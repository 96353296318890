import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileStart } from "../../store/slices/AdminSlice";
import AdminHeader from "./Header/AdminHeader";
import AdminSidebar from "./Header/AdminSidebar";

const AuthLayout = ({ children }) => <>{children}</>;

const EmptyLayout = ({ children }) => <>{children}</>;

const AdminLayout = ({ children }) => {
  const sidebar = useSelector((state) => state.common.sidebar);
  return (
    <>
      <div className="admin-ui-sec">
        <AdminSidebar />
        <div
          className={`admin-ui-main-wrapper ${
            sidebar ? "admin-collapse-main-wrapper" : ""
          }`}
        >
          <AdminHeader />
          {children}
        </div>
      </div>
    </>
  );
};

const EmptyAdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  useEffect(() => {
    dispatch(profileStart());
  }, []);
  return (
    <>
      {!profile.loading && Object.keys(profile.data).length > 0 ? (
        <div className="main-wrapper">
          {children}
        </div>
      ) : null}
    </>
  );
};

export { AuthLayout, EmptyLayout, AdminLayout, EmptyAdminLayout };

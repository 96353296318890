import React from "react";
import { Image, Button } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";

const NoBotExists = (props) => {

  const t = useTranslation("bot_creation")
  return (
    <>
      <div className="no-bot-exists-card">
        <div className="no-bot-exists-img-sec">
            <Image
            className="no-bot-exists-img"
            src={
                window.location.origin +
                "/images/no-data/no-bot-exists-img.svg"
            }
            />
        </div>
        <div className="no-bot-exists-info">
            <h4>{t("no_bots.heading")}</h4>
            <p>{t("no_bots.message")}</p>
           <div className="no-bot-exists-btn-sec">
           <Button
              className="onboarding-add-fine-tuning-btn"
              onClick={() => props.setAddBot(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="#fff"
                x="0"
                y="0"
                enableBackground="new 0 0 512 512"
                version="1.1"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
              >
                <path d="M480 224H288V32c0-17.673-14.327-32-32-32s-32 14.327-32 32v192H32c-17.673 0-32 14.327-32 32s14.327 32 32 32h192v192c0 17.673 14.327 32 32 32s32-14.327 32-32V288h192c17.673 0 32-14.327 32-32s-14.327-32-32-32z"></path>
              </svg>
              <span>{t("add_bot")}</span>
            </Button>
           </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation(NoBotExists);

import React, { useState, useEffect } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, withTranslation } from 'react-multi-lang';
import { subscriptionSaveStart, generateStripeIntentSuccess } from '../../store/slices/SubscriptionSlice';
import { getErrorNotificationMessage } from '../Helper/NotificationMessage';
import { useNavigate } from 'react-router-dom';

const StripePaymentSec = (props) => {

  const stripe = useStripe();
  const elements = useElements();
  const t = useTranslation("onboarding_subscription");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [email, setEmail] = useState('');
  const [skipRender, setSkipRender] = useState(true);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const subscriptionSave = useSelector((state)=> state.subscription.subscriptionSave);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = props.clientSecret;

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(t("payment_succeeded"));
          break;
        case "processing":
          setMessage(t("payment_processing"));
          break;
        case "requires_payment_method":
          setMessage(t("payment_failed"));
          break;
        default:
          setMessage("");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, ...response } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000",
      },
    });
    if (!error) {
      dispatch(subscriptionSaveStart({
        amount: response.paymentIntent.amount,
        payment_id: response.paymentIntent.id,
        subscription_plan_unique_id: props.subId,
        onboarding: 1,
      }));
    }
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
        getErrorNotificationMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
        getErrorNotificationMessage(error.message);
      }
    }
    setSkipRender(false);
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }
  
  return (
    <>
      {/* <div className="mb-5 back" onClick={() => props.back()}>
        <i className="fas fa-arrow-left mr-2"></i> Back
      </div> */}
      <form id="payment-form">
        <LinkAuthenticationElement
          id="link-authentication-element"
          // onChange={(e) => setEmail(e.target.value)}
          className="mb-2"
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <div className="add-card-btn mt-5">
          <Button
            disabled={isLoading || !stripe || !elements || subscriptionSave.buttonDisable}
            id="submit"
            type="button"
            onClick={(e)=>handleSubmit(e)}
            className='onboarding-btn'
          >
            <span id="button-text">
              {isLoading || subscriptionSave.buttonDisable ? t("payment.processing") : t("payment.pay")}
            </span>
          </Button>
        </div>
        {/* Show any error or success messages */}
      </form>
      {message && !skipRender ? <h6 id="payment-message" className="text-danger mt-4">{message}</h6> : null}
    </>
  );

}

export default withTranslation(StripePaymentSec);
import React from "react";
import Chart from "react-apexcharts";

const ChatsGraph = ({ tickets, name }) => {

  const options = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: tickets.days,
      tickAmount: 10,
      labels: {
        formatter: function (value, timestamp, opts) {
          return opts.dateFormatter(new Date(timestamp), 'dd MMM')
        }
      }
    },
    labels: tickets.days,
    colors: ["#512DAE"],
    legend: {
      show: false
    },
    yaxis: {
      show: 1
    },
    xaxis: {
      labels: {
        show: 1
      },
      axisTicks: {
        show: 1
      },
      axisBorder: {
        show: 1
      }
    }
  };
  const series = [{
    name: name,
    data: tickets.model_data,
  }]

  return (
    <Chart
      options={options}
      series={series}
      height="300"
    />
  );
}

export default ChatsGraph;
import React, { useEffect, useState } from "react";
import { Button, Image, Modal, InputGroup, Form } from "react-bootstrap";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProfileStart,
  updateProfileStart,
  updateTwoStepStart,
} from "../../store/slices/AdminSlice";
import { useTranslation, withTranslation } from "react-multi-lang";
import useLogout from "../../hooks/useLogout";

const TFAModal = (props) => {
  const t = useTranslation("login");
  const dispatch = useDispatch();
  const updateTwoStep = useSelector((state) => state.admin.updateTwoStep);
  const [skipRender, setSkipRender] = useState(true);
  const [showPassword, setShowPassword] = useState({
    password: false,
  });
  const { logout } = useLogout();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t("password.required")),
  });

  const handleSubmit = (values) => {
    dispatch(updateTwoStepStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !updateTwoStep.loading &&
      Object.keys(updateTwoStep.data).length > 0
    ) {
      props.closeNewEditProfileModal();
    }
    setSkipRender(false);
  }, [updateTwoStep]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.newEditProfile}
        onHide={props.closeNewEditProfileModal}
      >
        <Modal.Body>
          <h4>Update TFA Status</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeNewEditProfileModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M16.061 10.061L14.122 12l1.939 1.939a1.5 1.5 0 11-2.122 2.121L12 14.121l-1.939 1.939c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121L9.878 12l-1.939-1.939A1.5 1.5 0 1110.06 7.94l1.939 1.939 1.939-1.939a1.5 1.5 0 112.121 2.121zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-3 0c0-4.963-4.037-9-9-9s-9 4.037-9 9 4.038 9 9 9 9-4.037 9-9z"></path>
            </svg>
          </Button>
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <FORM className="onboarding-form">
                <Form.Label>
                  {t("password.label")} <span>*</span>
                </Form.Label>
                <div className="mb-3">
                  <InputGroup className="register-input-group">
                    <Field
                      type={showPassword.password ? "text" : "password"}
                      className="form-control"
                      placeholder={t("password.placeholder")}
                      name="password"
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        })
                      }
                    >
                      <Image
                        className="register-input-group-eye-icon"
                        src={
                          showPassword.password
                            ? window.location.origin +
                              "/images/onboarding/eye-visible-off-icon.svg"
                            : window.location.origin +
                              "/images/onboarding/eye-visible-on-icon.svg"
                        }
                      />
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="password"
                    className="errorMsg"
                  />
                </div>
                <div className="admin-ui-profile-btn-sec">
                  <Button
                    className="onboarding-cancel-btn"
                    onClick={() => props.closeNewEditProfileModal()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="onboarding-save-btn"
                    disabled={updateTwoStep.buttonDisable}
                    type="submit"
                  >
                    {updateTwoStep.buttonDisable ? "Loading" : "Update"}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(TFAModal);

import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  widgetSettingsFailure,
  widgetSettingsResetSuccess,
  widgetSettingsSaveFailure,
  widgetSettingsSaveSuccess,
  widgetSettingsSuccess
} from "../slices/SettingsSlice";


function* widgetSettingsAPI(action) {
  yield getCommonSaga({
    apiUrl: "custom_widgets/show",
    payload: action.payload,
    successNotify: false,
    success: widgetSettingsSuccess,
    failure: widgetSettingsFailure,
  });
}

function* widgetSettingsSaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "custom_widgets/store",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(widgetSettingsSaveSuccess(data));
      store.dispatch(widgetSettingsSuccess(data));
    },
    failure: widgetSettingsSaveFailure,
  });
}

function* widgetSettingsResetAPI(action) {
  yield getCommonSaga({
    apiUrl: "custom_widgets/reset",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(widgetSettingsResetSuccess(data));
      store.dispatch(widgetSettingsSuccess(data));
    },
    failure: widgetSettingsSaveFailure,
  });
}


export default function* SettingsSaga() {
  yield all([
    yield takeLatest('settings/widgetSettingsStart', widgetSettingsAPI),
    yield takeLatest('settings/widgetSettingsSaveStart', widgetSettingsSaveAPI),
    yield takeLatest('settings/widgetSettingsResetStart', widgetSettingsResetAPI),
  ]);
}


import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Tab,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./OnBoarding.css";
import { useDispatch, useSelector } from "react-redux";
import {
  generateStripeIntentStart,
  subscriptionPlansStart,
} from "../../store/slices/SubscriptionSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation, withTranslation } from "react-multi-lang";
import OnboardingStepsContent from "./OnboardingStepsContent";
import SubscriptionCard from "../Dashboard/SubscriptionCard";
import NavLogo from "../Layout/Header/NavLogo";

const OnBoardingSubscriptionPlans = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("onboarding_subscription");
  const subscriptionPlans = useSelector(
    (state) => state.subscription.subscriptionPlans
  );
  const generateStripeIntent = useSelector(
    (state) => state.subscription.generateStripeIntent
  );
  const profile = useSelector((state) => state.admin.profile);
  const [skipRender, setSkipRender] = useState(true);
  const [paymentIntent, setPaymentIntent] = useState({
    subscription_plan_unique_id: "",
    type: "",
  });

  useEffect(() => {
    localStorage.setItem('payment_page','onboarding');
    dispatch(subscriptionPlansStart());
  }, []);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.onboarding_step >= 6) navigate("/");
    }
  }, [profile]);

  const handleStripeIntent = (plan, type) => {
    if (plan) {
      dispatch(
        generateStripeIntentStart({
          type: type == "monthly" ? 1 : 2,
          subscription_plan_unique_id: plan.subscription_plan_unique_id,
          subscription_plan: plan,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !generateStripeIntent.loading &&
      Object.keys(generateStripeIntent.data).length > 0
    ) {
      if (generateStripeIntent.data.is_needs_to_pay) {
        navigate("/subscription-payment");
      } else {
        navigate("/settings?tab=fine-tune");
      }
    }
    setSkipRender(false);
  }, [generateStripeIntent]);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-subscription-plans-sec">
              {subscriptionPlans.loading ? (
                <>
                  <Skeleton
                    count={1}
                    height={50}
                    width={200}
                    className="m-2"
                  />
                  <div className="d-flex">
                    {[...Array(3)].map((i) => (
                      <Skeleton
                        count={1}
                        height={320}
                        width={220}
                        className={"m-2"}
                      />
                    ))}

                    {/* <Skeleton
                      count={1}
                      height={400}
                      width={200}
                      className={"m-2"}
                    />
                    <Skeleton
                      count={1}
                      height={400}
                      width={200}
                      className={"m-2"}
                    /> */}
                  </div>
                </>
              ) : Object.keys(subscriptionPlans.data).length > 0 ? (
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="monthly"
                >
                  <Row>
                    <Col sm={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="monthly">{t("plans.monthly")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="yearly">
                            <h4>{t("plans.yearly")}</h4>
                            {/* <p>Save 20%</p> */}
                            {/* Yearly */}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="monthly">
                          <div className="onboarding-subscription-plans-box">
                            {Object.keys(subscriptionPlans.data).length > 0 &&
                            subscriptionPlans.data.subscription_plans.length >
                              0 ? (
                              subscriptionPlans.data.subscription_plans.map(
                                (plan) => (
                                  <>
                                    <SubscriptionCard
                                      plan={plan}
                                      handleStripeIntent={handleStripeIntent}
                                      type="monthly"
                                    />
                                  </>
                                )
                              )
                            ) : (
                              <NoDataFound />
                            )}
                            {/* <div className="onboarding-subscription-plans-card advance-plan-card">
                            <div className="onboarding-subscription-plans-header-sec">
                              Pro
                            </div>
                            <div className="onboarding-subscription-plans-body-sec">
                              <div className="onboarding-subscription-plans-amount-sec">
                                <h3>$78 <span>/ Month</span></h3>
                                <p>Save 20%</p>
                              </div>
                              <div className="onboarding-subscription-plans-choose-btn-sec">
                                <Button className="onboarding-subscription-free-plans-btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="21"
                                    height="17"
                                    fill="none"
                                    viewBox="0 0 21 17"
                                  >
                                    <path
                                      fill="#020E30"
                                      d="M1.45 12.333L.197 4.178a.68.68 0 011.08-.648l3.91 2.932a.934.934 0 001.337-.23l3.254-4.88a.869.869 0 011.446 0l3.254 4.88a.934.934 0 001.338.23l3.908-2.932a.68.68 0 011.081.648l-1.255 8.155H1.451zM18.605 16.857H2.394a.944.944 0 01-.944-.943V13.84H19.55v2.073a.944.944 0 01-.944.943z"
                                    ></path>
                                  </svg>
                                  Choose Plan
                                </Button>
                              </div>
                              <div className="onboarding-subscription-plans-info-list-sec">
                                <ul className="list-unstyled onboarding-subscription-plans-info-list">
                                  <li>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="22.76"
                                        height="22.3251"
                                        rx="11.1625"
                                        fill="#fff"
                                      />
                                      <path
                                        d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                        fill="#00195E"
                                        stroke="#00195E"
                                      />
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="22.76"
                                        height="22.3251"
                                        rx="11.1625"
                                        stroke="#fff"
                                      />
                                    </svg>
                                    <span>
                                      Unlimited Messages
                                    </span>
                                  </li>
                                  <li>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="22.76"
                                        height="22.3251"
                                        rx="11.1625"
                                        fill="#fff"
                                      />
                                      <path
                                        d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                        fill="#00195E"
                                        stroke="#00195E"
                                      />
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="22.76"
                                        height="22.3251"
                                        rx="11.1625"
                                        stroke="#fff"
                                      />
                                    </svg>
                                    <span>
                                      500 Messages
                                    </span>
                                  </li>
                                  <li>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="22.76"
                                        height="22.3251"
                                        rx="11.1625"
                                        fill="#fff"
                                      />
                                      <path
                                        d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                        fill="#00195E"
                                        stroke="#00195E"
                                      />
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="22.76"
                                        height="22.3251"
                                        rx="11.1625"
                                        stroke="#fff"
                                      />
                                    </svg>
                                    <span>
                                      24/7 Support
                                    </span>
                                  </li>
                                  <li>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="22.76"
                                        height="22.3251"
                                        rx="11.1625"
                                        fill="#fff"
                                      />
                                      <path
                                        d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                        fill="#00195E"
                                        stroke="#00195E"
                                      />
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="22.76"
                                        height="22.3251"
                                        rx="11.1625"
                                        stroke="#fff"
                                      />
                                    </svg>
                                    <span>
                                      Unlimited fine tuning
                                    </span>
                                  </li>
                                  <li>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="22.76"
                                        height="22.3251"
                                        rx="11.1625"
                                        fill="#fff"
                                      />
                                      <path
                                        d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                                        fill="#00195E"
                                        stroke="#00195E"
                                      />
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="22.76"
                                        height="22.3251"
                                        rx="11.1625"
                                        stroke="#fff"
                                      />
                                    </svg>
                                    <span>
                                      Unlimited Bots
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> */}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="yearly">
                          <div className="onboarding-subscription-plans-box">
                            {Object.keys(subscriptionPlans.data).length > 0 &&
                            subscriptionPlans.data.subscription_plans.length >
                              0 ? (
                              subscriptionPlans.data.subscription_plans.map(
                                (plan) => (
                                  <>
                                    <SubscriptionCard
                                      plan={plan}
                                      handleStripeIntent={handleStripeIntent}
                                      type="yearly"
                                    />
                                  </>
                                )
                              )
                            ) : (
                              <NoDataFound />
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              ) : (
                <NoDataFound />
              )}
            </div>
            <div className="onboarding-footer-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-on-premise-btn-sec">
                    <Button
                      className="onboarding-back-btn"
                      onClick={() => navigate('/onboarding-steps')}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00195E"
                          d="M20 11H6.83l2.88-2.88A.997.997 0 008.3 6.71L3.71 11.3a.996.996 0 000 1.41L8.3 17.3a.997.997 0 101.41-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z"
                        ></path>
                      </svg>
                      {t("payment.back")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <OnboardingStepsContent step={4} />
      </div>
    </>
  );
};

export default withTranslation(OnBoardingSubscriptionPlans);

import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup, Row, Col } from "react-bootstrap";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import {
  botsStoreStart,
  botsListStart
} from "../../store/slices/BotSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";
import useBot from "../../hooks/useBot";

const AddBotModal = (props) => {

  const { widgets } = useBot();

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: 'auto' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: 'transparent!important',
      border: '1px solid #00337D57!important',
      borderRadius:'0!important',
      boxShadow: 'none!important',
      cursor:"pointer",
      height: '45px',
      minWidth: "150px",
      fontSize: "0.9em"
    }),
    placeholder: provided => ({ ...provided, color: '#d0d0d7', fontSize:'1.1em', fontWeight:'600' }),
    singleValue: provided => ({
      ...provided, color: '#000', display: "flex",
      alignItems: "center",
      gap: "0.5em", fontSize: "0.9em", fontWeight: "400"
    }),
    indicatorContainer: provided => ({ ...provided, color: '#32089F!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#32089F"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000"
      };
    }
  }

  const t = useTranslation("bot_creation");
  const dispatch = useDispatch();
  const botsStore = useSelector((state) => state.bots.botsStore);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")),
    website: Yup.string().required(t("website.required"))
    .matches(/^(?!https?)(?!http\.?).*\..+$/g, t("website.invalid")),
    widget_type: Yup.string().required('Please select an option'),
  });

  const handleBotsStore = (values) => {
    dispatch(
      botsStoreStart({
        ...values,
        website: "https://" + values.website,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !botsStore.loading &&
      Object.keys(botsStore.data).length > 0
    ) {
      // dispatch(botsListStart());
      props.closeAddBotModal();
    }
    setSkipRender(false);
  }, [botsStore]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.addBot}
        onHide={props.closeAddBotModal}
      >
        <Modal.Body>
          <h4>{t("add_bot")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeAddBotModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M16.061 10.061L14.122 12l1.939 1.939a1.5 1.5 0 11-2.122 2.121L12 14.121l-1.939 1.939c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121L9.878 12l-1.939-1.939A1.5 1.5 0 1110.06 7.94l1.939 1.939 1.939-1.939a1.5 1.5 0 112.121 2.121zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-3 0c0-4.963-4.037-9-9-9s-9 4.037-9 9 4.038 9 9 9 9-4.037 9-9z"></path>
            </svg>
          </Button>
          <Formik
            initialValues={{
              name:"",
              website:"",
              widget_type:""
            }}
            validationSchema={validationSchema}
            onSubmit={handleBotsStore}
          >
            {({ setFieldValue, values }) => (
              <FORM noValidate className="onboarding-form">
                <div className="onboarding-body-sec">
                  <Row className="justify-content-md-center">
                    <Col md={10}>
                      {/* <Form className="onboarding-form"> */}
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                        {t("name.label")} <span>*</span>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder={t("name.placeholder")}
                          name="name"
                        />
                      </Form.Group>
                      <ErrorMessage
                        component={"div"}
                        name="name"
                        className="errorMsg"
                      />
                      <Form.Label>
                      {t("website.label")} <span>*</span>
                      </Form.Label>
                      <div className="mb-3">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1">
                            https://
                          </InputGroup.Text>
                          <Field
                            className="form-control"
                            placeholder={t("website.placeholder")}
                            aria-label="Website"
                            aria-describedby="basic-addon1"
                            name="website"
                          />
                        </InputGroup>
                        <ErrorMessage
                          component={"div"}
                          name="website"
                          className="errorMsg"
                        />
                      </div>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                        {t("solution.label")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={widgets}
                          styles={customStyles}
                          placeholder="Select an option"
                          onChange={(selectOption)=>{
                            setFieldValue("widget_type", selectOption.value)
                          }}
                        />
                      </Form.Group>
                      <ErrorMessage
                        component={"div"}
                        name="widget_type"
                        className="errorMsg"
                      />
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>{t("about.label")}</Form.Label>
                        <Field
                          className="form-control"
                          as="textarea"
                          rows={3}
                          placeholder={t("about.placeholder")}
                          name="description"
                        />
                      </Form.Group>
                      {/* </Form> */}
                    </Col>
                  </Row>
                </div>
                <div className="onboarding-footer-sec">
                  <Row className="justify-content-md-center">
                    <Col md={10}>
                      <div className="onboarding-on-premise-btn-sec">
                        <Button
                          className="onboarding-btn"
                          type="submit"
                          disabled={botsStore.buttonDisable || values.widget_type===""}
                        >
                          {botsStore.buttonDisable ? t("loading") : t("add_bot")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(AddBotModal);

import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
    pageData: getCommonInitialState()
};

const PageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {

    // Static Pages
    fetchStaticPageStart: (state) => { state.pageData = getCommonStart() },
    fetchStaticPageSuccess: (state, action) => { state.pageData = getCommonSuccess(action.payload) },
    fetchStaticPageFailure: (state, action) => { state.pageData = getCommonFailure(action.payload) },

  }
});


export const {
  fetchStaticPageFailure,
  fetchStaticPageStart,
  fetchStaticPageSuccess

} = PageSlice.actions;

export default PageSlice.reducer;
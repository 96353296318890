import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, Image, Nav, Row, Col, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Select from "react-select";
import Toggle from "react-styled-toggle";
import InboxChat from "./InboxChat";
import {
  chatListStart,
  disableChatNotify,
  setHighlightChat,
  setSelectedChats,
} from "../../store/slices/ChatSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import Skeleton from "react-loading-skeleton";
import { profileStart } from "../../store/slices/AdminSlice";
import Pagination from "../Helper/Pagination";
import { Field, Form as FORM, Formik } from "formik";
import NoDataFound from "../Helper/NoDataFound";
import useDesign from "../../hooks/useDesign";
import NotChatFound from "../Helper/NoChatFound";
import { botsListStart } from "../../store/slices/BotSlice";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation } from "react-multi-lang";

const NewChatInbox = (props) => {
  const { getChatTypeName, getChatTypeBg } = useDesign();
  const t = useTranslation("chats.filter");

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "max-content",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #00337D24!important",
      borderRadius: "0!important",
      boxShadow: "none!important",
      cursor:"pointer",
      height: "45px",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#32089F",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
      display: "none",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#32089F",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const profile = useSelector((state) => state.admin.profile);
  const chatList = useSelector((state) => state.chat.chatList);
  const selectedChats = useSelector((state) => state.chat.selectedChats);
  const botsList = useSelector((state) => state.bots.botsList);
  const selectedInboxBot = useSelector((state) => state.chat.selectedInboxBot);
  const [skipRender, setSkipRender] = useState(true);
  const [unreadChat, setUnreadChat] = useState(0);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [initialChat, setInitialChat] = useState(true);
  const [chatId, setChatId] = useState("");
  const [bots, setBots] = useState([]);
  const [selectedBotDetail, setSelectedBotDetail] = useState({
    label: "All Chats",
    value: "",
  });
  const [selectedFilter, setSelectedFilter] = useState()

  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    order_by: searchParams.get("order_by") ? searchParams.get("order_by") : "",
  });

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];

  useEffect(() => {
    if(!Object.keys(botsList.data).length)
    dispatch(botsListStart());
  }, [])

  useEffect(() => {
    let newValues = {...filter};
    if (!profile.loading && Object.keys(profile.data).length > 0 && !skipRender) {
      if(Object.keys(selectedInboxBot).length > 0)
        newValues = {...newValues, bot_id: selectedInboxBot.value,}
      dispatch(
        chatListStart({
          skip: 5 * (page - 1),
          take: 5,
          ...newValues,
        })
      );
      (searchParams.get("order_by") && searchParams.get("order_by") != 'null') && setSelectedFilter({
        label: t(`${searchParams.get("order_by")}`),
        value: searchParams.get("order_by"),
      });
    }
  }, [profile, page, filter]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
  }, [searchParams.get("page")]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    params += searchParams.get("status")
      ? `status=${searchParams.get("status")}&`
      : "";
    navigate(`/inbox?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender && !chatList.loading && Object.keys(chatList.data).length > 0) {
      setUnreadChat(chatList.data.un_read_chats_count ?? 0);
      if (chatList.data.chats.length <= 0)
        setChatId("");
      else if(!chatId){
        setChatId(chatList.data.chats[0].chat_id ?? 0);
      }
    }
  }, [chatList]);

  useEffect(() => {
    if (initialChat && chatId) {
      dispatch(disableChatNotify(chatId));
      setInitialChat(false)
    }
  }, [chatId]);

  useEffect(() => {
    let newValues = {...filter};
    if (
      !botsList.loading &&
      Object.keys(botsList.data).length > 0 &&
      botsList.data.bots.length > 0
    ) {
      if(Object.keys(selectedInboxBot).length > 0)
        newValues = {...newValues, bot_id: selectedInboxBot.value,}
      dispatch(
        chatListStart({
          skip: 5 * (page - 1),
          take: 5,
          ...newValues,
        })
      );
    }
  }, [botsList]);

  useEffect(() => {
    if (
      !skipRender &&
      Object.keys(selectedInboxBot).length > 0
    ) {
      dispatch(
        chatListStart({
          skip: 0,
          take: 5,
          bot_id: selectedInboxBot.value,
          ...filter,
        })
      );
      setChatId("");
    }
    setSkipRender(false);
  }, [selectedInboxBot]);

  return profile.loading || chatList.loading ? (
    <PageLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      <div className="admin-ui-setting-body-sec">
        <div className="new-settings-sec">
          <div className="new-settings-tab-sec">
            <Tab.Container id="left-tabs-example" defaultActiveKey="inbox">
              <Row>
                <Col sm={12}>
                  <div className="new-settings-tab-nav-link-sec">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => navigate("/settings?tab=customize")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                            <path d="M21.294 13.9l-.444-.256a9.1 9.1 0 000-3.29l.444-.256a3 3 0 10-3-5.2l-.445.257A8.977 8.977 0 0015 3.513V3a3 3 0 00-6 0v.513a8.977 8.977 0 00-2.848 1.646L5.705 4.9a3 3 0 00-3 5.2l.444.256a9.1 9.1 0 000 3.29l-.444.256a3 3 0 103 5.2l.445-.257A8.977 8.977 0 009 20.487V21a3 3 0 006 0v-.513a8.977 8.977 0 002.848-1.646l.447.258a3 3 0 003-5.2zm-2.548-3.776a7.048 7.048 0 010 3.75 1 1 0 00.464 1.133l1.084.626a1 1 0 01-1 1.733l-1.086-.628a1 1 0 00-1.215.165 6.984 6.984 0 01-3.243 1.875 1 1 0 00-.751.969V21a1 1 0 01-2 0v-1.252a1 1 0 00-.751-.969A6.984 6.984 0 017.006 16.9a1 1 0 00-1.215-.165l-1.084.627a1 1 0 11-1-1.732l1.084-.626a1 1 0 00.464-1.133 7.048 7.048 0 010-3.75 1 1 0 00-.465-1.129l-1.084-.626a1 1 0 011-1.733l1.086.628A1 1 0 007.006 7.1a6.984 6.984 0 013.243-1.875A1 1 0 0011 4.252V3a1 1 0 012 0v1.252a1 1 0 00.751.969A6.984 6.984 0 0116.994 7.1a1 1 0 001.215.165l1.084-.627a1 1 0 111 1.732l-1.084.626a1 1 0 00-.463 1.129z"></path>
                          </svg>
                          Customize
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => navigate("/settings?tab=fine-tune")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                          </svg>
                          Custom Training
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="inbox"
                          onClick={() => navigate("/inbox")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                            <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                          </svg>
                          Inbox
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => navigate("/settings?tab=source")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 1H5a5.006 5.006 0 00-5 5v12a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V6a5.006 5.006 0 00-5-5zM5 3h14a3 3 0 013 3v1H2V6a3 3 0 013-3zm14 18H5a3 3 0 01-3-3V9h20v9a3 3 0 01-3 3zm0-8a1 1 0 01-1 1H6a1 1 0 010-2h12a1 1 0 011 1zm-4 4a1 1 0 01-1 1H6a1 1 0 010-2h8a1 1 0 011 1zM3 5a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1z"></path>
                          </svg>
                          Source
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => navigate("/settings?tab=analytics")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M23 22H5a3 3 0 01-3-3V1a1 1 0 00-2 0v18a5.006 5.006 0 005 5h18a1 1 0 000-2z"></path>
                            <path d="M6 20a1 1 0 001-1v-7a1 1 0 00-2 0v7a1 1 0 001 1zM10 10v9a1 1 0 002 0v-9a1 1 0 00-2 0zM15 13v6a1 1 0 002 0v-6a1 1 0 00-2 0zM20 9v10a1 1 0 002 0V9a1 1 0 00-2 0zM6 9a1 1 0 00.707-.293l3.586-3.586a1.025 1.025 0 011.414 0l2.172 2.172a3 3 0 004.242 0l5.586-5.586A1 1 0 0022.293.293l-5.586 5.585a1 1 0 01-1.414 0l-2.172-2.171a3 3 0 00-4.242 0L5.293 7.293A1 1 0 006 9z"></path>
                          </svg>
                          Analytics
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
          <div className="new-settings-box">
            <div className="new-inbox-tab-sec">
              <div className="new-chat-inbox-sec">
                <div className="new-chat-inbox-header-sec">
                  <div className="new-chat-inbox-header-left-sec">
                    <h3>
                      Inbox {chatList.loading ? <Skeleton count={1} height={20} width={20} /> : <span className="count">{unreadChat}</span>}
                    </h3>
                  </div>
                  <div className="new-chat-inbox-header-right-sec">
                    <div className="new-billing-search-sec">
                      <Formik
                        initialValues={{
                          search_key: searchParams.get("search_key")
                            ? searchParams.get("search_key")
                            : "",
                          order_by: searchParams.get("order_by")
                            ? searchParams.get("order_by")
                            : filter.order_by,
                        }}
                        onSubmit={(val) => {
                          setFilter({
                            ...filter,
                            search_key: val.search_key,
                          });
                          navigate(
                            `/inbox?search_key=${
                              val.search_key
                            }&order_by=${searchParams.get("order_by")}`
                          );
                        }}
                      >
                        {({ values, setFieldValue, resetForm }) => (
                          <FORM className="form">
                            <InputGroup className="mb-0">
                              <Field
                                placeholder="Search chats"
                                type="text"
                                className="form-control"
                                name="search_key"
                              />
                              {searchParams.get("search_key") && (
                                <InputGroup.Text id="basic-addon2">
                                  <Button
                                    className="search-btn"
                                    type="button"
                                    onClick={() => {
                                      setFieldValue("search_key", "");
                                      setFilter({
                                        ...filter,
                                        search_key: "",
                                      });
                                      navigate(
                                        `/inbox?order_by=${searchParams.get(
                                          "order_by"
                                        )}`
                                      );
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="#3E4351"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M16.707 8.707L13.414 12l3.293 3.293-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12s4.486 10 10 10 10-4.486 10-10z"></path>
                                    </svg>
                                  </Button>
                                </InputGroup.Text>
                              )}
                              <InputGroup.Text id="basic-addon2">
                                <Button className="search-btn" type="submit">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="#3E4351"
                                    x="0"
                                    y="0"
                                    enableBackground="new 0 0 513.749 513.749"
                                    version="1.1"
                                    viewBox="0 0 513.749 513.749"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M504.352 459.061l-99.435-99.477c74.402-99.427 54.115-240.344-45.312-314.746S119.261-9.277 44.859 90.15-9.256 330.494 90.171 404.896c79.868 59.766 189.565 59.766 269.434 0l99.477 99.477c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269l.001-.043zm-278.635-73.365c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c-.094 88.326-71.673 159.906-160 160z"></path>
                                  </svg>
                                </Button>
                              </InputGroup.Text>
                            </InputGroup>
                          </FORM>
                        )}
                      </Formik>
                    </div>
                    <div className="new-chat-inbox-filter-sec">
                      <Select
                        options={options}
                        styles={customStyles}
                        // menuIsOpen={true}
                        value={selectedFilter}
                        isSearchable={false}
                        onChange={(selectedOption) => {
                          setFilter({
                            ...filter,
                            order_by: selectedOption.value,
                          });
                          navigate(
                            `/inbox?search_key=${filter.search_key}&order_by=${selectedOption.value}`
                          );
                        }}
                        placeholder={
                          <div className="placeholder-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#3E4351"
                              viewBox="0 0 24 24"
                            >
                              <path d="M10 6h13a1 1 0 000-2H10a1 1 0 000 2zM23 11H10a1 1 0 000 2h13a1 1 0 000-2zM23 18H10a1 1 0 000 2h13a1 1 0 000-2zM6.087 6a.5.5 0 00.353-.854L4 2.707a1 1 0 00-1.414 0L.147 5.146A.5.5 0 00.5 6h1.794v12H.5a.5.5 0 00-.354.854l2.44 2.439a1 1 0 001.414 0l2.44-2.439A.5.5 0 006.087 18H4.294V6z"></path>
                            </svg>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="new-chat-inbox-body-sec">
                  <div className="new-chat-inbox-list-sec">
                    {chatList.loading ? (
                      [...Array(5)].map((i) => (
                        <>
                          <Skeleton count={1} height={100} />
                        </>
                      ))
                    ) : Object.keys(chatList.data).length > 0 &&
                      chatList.data.chats.length > 0 ? (
                      <>
                        <div className="new-chat-inbox-list-box">
                          {chatList.data.chats
                            .filter(
                              (chat) =>
                                selectedBotDetail.value == "" ||
                                chat.bot_id == selectedBotDetail.value
                            )
                            .map((chat, i) => (
                              <div
                                className={`new-chat-inbox-list-card ${
                                  chatId == chat.chat_id && "active"
                                }`}
                                onClick={() => {
                                  setChatId(chat.chat_id);
                                  // setUnreadChat(
                                  //   chat.notify && unreadChat > 0
                                  //     ? unreadChat - 1
                                  //     : unreadChat
                                  // );
                                  dispatch(disableChatNotify(chat.chat_id));
                                }}
                              >
                                {chat.notify ? (
                                  <div className="pulsating-circle"></div>
                                ) : null}
                                <div className="new-chat-inbox-list-user-details-sec">
                                  <div
                                    className={`new-chat-preview-user-img-sec ${getChatTypeBg(
                                      chat.widget_type
                                    )}`}
                                  >
                                    <Image
                                      className="new-chat-preview-user-img"
                                      src={
                                        window.location.origin +
                                        "/images/your-bot/your-bot-img.svg"
                                      }
                                    />
                                  </div>
                                  <div className="new-chat-inbox-list-user-details-info">
                                    <h4>
                                      {chat.chat_unique_id}{" "}
                                      <span className="count">
                                        {chat.chat_messages_count}
                                      </span>
                                    </h4>
                                    {chat.email ? (
                                      <h4>
                                        Email :
                                        <p>
                                          {chat.email}
                                          <CopyToClipboard
                                            text={chat.email}
                                            onCopy={() =>
                                              getSuccessNotificationMessage(
                                                "Email copied to clipboard!"
                                              )
                                            }
                                          >
                                            <Button
                                              type="button"
                                              className="search-btn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                fill="#3E4351"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                              </svg>
                                            </Button>
                                          </CopyToClipboard>
                                        </p>
                                      </h4>
                                    ) : null}
                                    {chat.contact ? (
                                      <h4>
                                        Contact :
                                        <p>
                                          {chat.contact}
                                          <CopyToClipboard
                                            text={chat.contact}
                                            onCopy={() =>
                                              getSuccessNotificationMessage(
                                                "Contact copied to clipboard!"
                                              )
                                            }
                                          >
                                            <Button
                                              type="button"
                                              className="search-btn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                fill="#3E4351"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                              </svg>
                                            </Button>
                                          </CopyToClipboard>
                                        </p>
                                      </h4>
                                    ) : null}
                                    {Object.keys(chat.last_chat_message)
                                      .length > 0 &&
                                      (chat.last_chat_message.message ? (
                                        <p>{chat.last_chat_message.message}</p>
                                      ) : <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="#00195e"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M19.5 0h-15A4.505 4.505 0 000 4.5v15A4.505 4.505 0 004.5 24h15a4.505 4.505 0 004.5-4.5v-15A4.505 4.505 0 0019.5 0zm-15 3h15A1.5 1.5 0 0121 4.5v15a1.492 1.492 0 01-.44 1.06l-8.732-8.732a4 4 0 00-5.656 0L3 15V4.5A1.5 1.5 0 014.5 3z"></path>
                                      <circle cx="15.5" cy="7.5" r="2.5"></circle>
                                    </svg>)}
                                  </div>
                                </div>
                                <div className="new-chat-inbox-list-info">
                                  <div className="new-chat-inbox-list-info-top-sec">
                                    {/* <div className="auto-reply-turn-off-badge">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="none"
                                    viewBox="0 0 16 13"
                                  >
                                    <path
                                      fill="#F9AF1A"
                                      stroke="#F9AF1A"
                                      strokeWidth="0.2"
                                      d="M15.5 6.363v2.4c0 .662-.505 1.2-1.125 1.2h-.402c-.182 1.356-1.278 2.4-2.598 2.4h-6.75c-1.32 0-2.416-1.044-2.598-2.4h-.402c-.62 0-1.125-.538-1.125-1.2v-2.4c0-.661.505-1.2 1.125-1.2H2v-.4c0-1.544 1.178-2.8 2.625-2.8h3v-1.2c0-.22.168-.4.375-.4s.375.18.375.4v1.2h3c1.447 0 2.625 1.256 2.625 2.8v.4h.375c.62 0 1.125.539 1.125 1.2zm-13.875 2.8H2v-3.2h-.375c-.207 0-.375.18-.375.4v2.4c0 .22.168.4.375.4zm11.625.4v-4.8c0-1.103-.841-2-1.875-2h-6.75c-1.034 0-1.875.897-1.875 2v4.8c0 1.103.841 2 1.875 2h6.75c1.034 0 1.875-.897 1.875-2zm1.5-3.2c0-.22-.168-.4-.375-.4H14v3.2h.375c.207 0 .375-.18.375-.4v-2.4zm-7.5 1.2c0 .883-.673 1.6-1.5 1.6s-1.5-.717-1.5-1.6c0-.882.673-1.6 1.5-1.6s1.5.718 1.5 1.6zm-.75 0c0-.441-.337-.8-.75-.8s-.75.359-.75.8c0 .442.337.8.75.8s.75-.358.75-.8zm5.25 0c0 .883-.673 1.6-1.5 1.6s-1.5-.717-1.5-1.6c0-.882.673-1.6 1.5-1.6s1.5.718 1.5 1.6zm-.75 0c0-.441-.337-.8-.75-.8s-.75.359-.75.8c0 .442.337.8.75.8s.75-.358.75-.8z"
                                    ></path>
                                  </svg>
                                  <span>Bot</span>
                                </div> */}
                                    <div
                                      className={getChatTypeName(
                                        chat.widget_type
                                      )}
                                    >
                                      {chat.widget_type_formatted}
                                    </div>
                                    {/* <Toggle
                                    backgroundColorChecked="#32089F"
                                    backgroundColorUnchecked="#E5E4F6"
                                    width={50}
                                    height={25}
                                    sliderWidth={19}
                                    sliderHeight={19}
                                    translate={23}
                                  /> */}
                                  </div>
                                  <div className="new-chat-inbox-list-info-last-seen">
                                    <p>{chat.age}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="new-billings-footer-sec">
                          <div className="new-billings-pagination-sec">
                            <Pagination
                              page={page}
                              pageCount={Math.ceil(
                                chatList.data.total_chats / 5
                              )}
                              handlePageClick={handlePageClick}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="new-settings-preview-sec">
              {chatId ? <InboxChat chatId={chatId} /> : <NotChatFound />}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default NewChatInbox;

import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  conversationsDeleteStart,
  conversationsListStart,
  moreConversationsListStart,
} from "../../../store/slices/FineTuneSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import { confirmAlert } from "react-confirm-alert";

const ConversationsIndex = (props) => {
  const dispatch = useDispatch();

  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const conversationsList = useSelector((state) => state.fineTune.conversationsList);
  const conversationsDelete = useSelector((state) => state.fineTune.conversationsDelete);

  useEffect(() => {
    dispatch(
      conversationsListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12 })
    );
  }, [selectedBot]);

  const handleDeleteConversations = (modelId) => {
    confirmAlert({
      title: "Do you really want to delete this conversational data?",
      message: "All your data wil be deleted and cannot be recovered.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            dispatch(conversationsDeleteStart({ conversational_data_id: modelId }));
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const fetchMoreData = () => {
    dispatch(
      moreConversationsListStart({
        bot_id: selectedBot.bot_id,
        skip: conversationsList.data.conversational_data.length,
        take: 12,
      })
    );
  };

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return "progress-badge";
      case 2:
        return "trained-badge";
      case 3:
        return "failed-badge";
      default:
        return "uploaded-badge";
    }
  };

  return (
    <div className="new-settings-finetune-table-sec">
      <div className="new-finetune-faq-table-box">
      {conversationsList.loading ? (
        <div className="onboarding-fine-tunings-files-box">
          {[...Array(4)].map((i) => (
            <Skeleton height={50} />
          ))}
        </div>
      ) : Object.keys(conversationsList.data).length > 0 &&
        conversationsList.data.conversational_data &&
        conversationsList.data.conversational_data.length > 0 ? (
        <InfiniteScroll
          dataLength={conversationsList.data.conversational_data.length}
          next={fetchMoreData}
          hasMore={
            conversationsList.data.conversational_data.length <
            conversationsList.data.total_conversational_data
          }
          loader={[...Array(3)].map((i) => (
            <Skeleton height={80} className="mb-3" />
          ))}
        >
          {conversationsList.data.conversational_data.map((model) => (
            <Table responsive="md">
              <tbody>
                <tr>
                  <td>{model.name}</td>
                  <td>
                    <span>Updated on:</span> {model.updated_at}
                  </td>
                  <td>
                    <div className="conversation-data-status">
                      <div className="uploaded-badge">{model.platform_formatted}</div>
                      <div className={getStatusName(model.training_status)}>
                        {model.training_status_formatted}
                      </div>
                    </div>
                  </td>
                  <td className="recent-chat-menu-btn">
                    {conversationsDelete.buttonDisable &&
                    conversationsDelete.data.conversational_data_id ==
                      model.id ? (
                      <Image
                        className="btn-loader"
                        src={
                          window.location.origin +
                          "/images/loader/btn-loader.gif"
                        }
                      />
                    ) : (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="recent-chat-menu-icon-sec">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              x="0"
                              y="0"
                              enableBackground="new 0 0 512 512"
                              version="1.1"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                            >
                              <circle cx="458.667" cy="256" r="53.333"></circle>
                              <circle cx="256" cy="256" r="53.333"></circle>
                              <circle cx="53.333" cy="256" r="53.333"></circle>
                            </svg>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href={model.file} target="_blank">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              fill="#546594"
                              height="20"
                              viewBox="0 0 24 24"
                            >
                              <path d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"></path>
                              <path d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"></path>
                            </svg>
                            View
                          </Dropdown.Item>
                          {model.training_status != 1 && <Dropdown.Item
                            disabled={conversationsDelete.buttonDisable}
                            onClick={() => handleDeleteConversations(model.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              fill="#546594"
                              height="20"
                              viewBox="0 0 24 24"
                            >
                              <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                              <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                            </svg>
                            {conversationsDelete.buttonDisable
                              ? "Loading"
                              : "Delete"}
                          </Dropdown.Item>}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          ))}
        </InfiniteScroll>
      ) : (
        <NoDataFound />
      )}
      </div>
    </div>
  );
};

export default ConversationsIndex;

import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Toggle from "react-styled-toggle";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation, withTranslation } from "react-multi-lang";
import {
  botsStoreStart,
  singleBotViewStart,
} from "../../../store/slices/BotSlice";
import NoDataFound from "../../Helper/NoDataFound";
import Select from "react-select";
import useBot from "../../../hooks/useBot";

const ChatCustomize = (props) => {
  const t = useTranslation("settings.customise");
  const dispatch = useDispatch();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const singleBotView = useSelector((state) => state.bots.singleBotView);
  const botsStore = useSelector((state) => state.bots.botsStore);
  const { promptOptions, handlePrompt } = useBot();

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #00337D57!important",
      borderRadius: "0!important",
      boxShadow: "none!important",
      height: "45px",
      cursor:"pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")),
    website: Yup.string()
      .required(t("website.required"))
      .matches(/^(?!https?)(?!http\.?).*\..+$/g, t("website.invalid")),
    support_email: Yup.string().email(t("support.invalid")),
  });

  const handleBotsStore = (values) => {
    dispatch(botsStoreStart({
      ...values,
      website: "https://" + values.website,
    }));
  };

  useEffect(()=>{
    if(Object.keys(selectedBot).length>0)
      dispatch(singleBotViewStart({bot_id : selectedBot.bot_id}))
  }, [selectedBot]);

  const handleBotInstruction = async (selectPrompt, setFieldValue) => {
    let res = await handlePrompt(selectPrompt);
    if (res.status_code != 500) 
    {
      setFieldValue('bot_instructions',res.prompt);
      setFieldValue('prompt_title', selectPrompt.domain);
    }
    else setFieldValue('bot_instructions', '');
  };

  return (
    <>
      <div className="new-chat-customize-sec">
        {singleBotView.loading ? (
          [...Array(5)].map((i) => (
            <div className="mb-3">
              <Skeleton height={10} width="100%" />
              <Skeleton height={50} width="100%" />
            </div>
          ))
        ) : Object.keys(singleBotView.data).length > 0 ? (
          <Formik
            initialValues={{
              bot_id: singleBotView.data.bot.bot_id,
              name: singleBotView.data.bot.name,
              website: singleBotView.data.bot.website.replace("https://", ""),
              description: singleBotView.data.bot.description,
              welcome_message: singleBotView.data.bot.welcome_message,
              fallback_message: singleBotView.data.bot.fallback_message,
              support_email: singleBotView.data.bot.support_email,
              select_prompt: singleBotView.data.bot.prompt_title,
              prompt_title: singleBotView.data.bot.prompt_title,
              bot_instructions: singleBotView.data.bot.bot_instructions,
              widget_type: singleBotView.data.bot.widget_type,
            }}
            validationSchema={validationSchema}
            onSubmit={handleBotsStore}
          >
            {({ setFieldValue, values, isValid }) => (
              <FORM className="onboarding-form">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("name.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("name.placeholder")}
                    name="name"
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                      props.setBotDetails({
                        ...props.botDetails,
                        name: e.target.value,
                      });
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="name"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="mb-3">
                  <Form.Label>{t("website.label")}</Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      https://
                    </InputGroup.Text>
                    <Field
                      className="form-control"
                      placeholder={t("website.placeholder")}
                      aria-label="Website"
                      aria-describedby="basic-addon1"
                      name="website"
                      onChange={(e) => {
                        setFieldValue("website", e.target.value);
                        props.setBotDetails({
                          ...props.botDetails,
                          website: "https://" + e.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="website"
                    className="errorMsg"
                  />
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("about.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    as="textarea"
                    placeholder={t("about.placeholder")}
                    name="description"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("welcome.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("welcome.placeholder")}
                    name="welcome_message"
                  />
                </Form.Group>
                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("fallback.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("fallback.placeholder")}
                    name="fallback_message"
                  />
                </Form.Group> */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("support.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    name="support_email"
                    placeholder={t("support.placeholder")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="support_email"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Select Prompt <span>*</span>
                  </Form.Label>
                  <Select
                    options={promptOptions}
                    styles={customStyles}
                    value={{
                      label: values.select_prompt,
                      value: values.select_prompt,
                    }}
                    onChange={(selectOption) => {
                      setFieldValue("select_prompt", selectOption.value);
                      if(selectOption.value!="Others")
                      {
                        handleBotInstruction(
                          {
                            domain: selectOption.value,
                            bot_name: values.name,
                            company: values.description,
                          },
                          setFieldValue
                        );
                      }
                    }}
                  />
                </Form.Group>
                {values.select_prompt === "Others" && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{t("prompt_title.label")}</Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={t("prompt_title.placeholder")}
                      name="prompt_title"
                    />
                  </Form.Group>
                )}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("instruction.label")}</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    as="textarea"
                    rows="5"
                    placeholder={t("instruction.placeholder")}
                    name="bot_instructions"
                  />
                </Form.Group>
                {/* <div className="allow-ai-auto-reply-switch">
                    <Toggle
                        width={40}
                        height={20}
                        sliderWidth={12}
                        sliderHeight={12}
                        translate={19}
                        backgroundColorChecked="#32089F" 
                        backgroundColorUnchecked="#E5E4F6"
                        labelRight="Allow AI Auto Reply"
                    />
                </div> */}
                <div className="new-settings-btn-sec mb-3">
                  <Button
                    className="settings-btn"
                    type="submit"
                    disabled={botsStore.buttonDisable || !isValid}
                    onClick={() => handleBotsStore(values)}
                  >
                    {botsStore.buttonDisable ? t("loading") : t("update")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};

export default withTranslation(ChatCustomize);

import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  dashboardFailure,
  dashboardGraphFailure,
  dashboardGraphSuccess,
  dashboardRecentChatsFailure,
  dashboardRecentChatsSuccess,
  dashboardSuccess,
  dashboardGraphUpdate
} from "../slices/DashboardSlice";



function* dashboardAPI(action) {
  yield getCommonSaga({
    apiUrl: "dashboard",
    payload: action.payload,
    successNotify: false,
    success: dashboardSuccess,
    failure: dashboardFailure,
  });
}

function* dashboardGraphAPI(action) {
  yield getCommonSaga({
    apiUrl: "dashboard_graph",
    payload: action.payload,
    successNotify: false,
    success: dashboardGraphSuccess,
    failure: dashboardGraphFailure,
  });
}

function* dashboardGraphUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "dashboard_graph",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(dashboardGraphSuccess(data));
    },
    failure: dashboardGraphFailure,
  });
}

function* dashboardRecentChatsAPI(action) {
  yield getCommonSaga({
    apiUrl: "dashboard_recent_chats",
    payload: action.payload,
    successNotify: false,
    success: dashboardRecentChatsSuccess,
    failure: dashboardRecentChatsFailure,
  });
}

export default function* DashboardSaga() {
  yield all([
    yield takeLatest('dashboard/dashboardStart', dashboardAPI),
    yield takeLatest('dashboard/dashboardGraphStart', dashboardGraphAPI),
    yield takeLatest('dashboard/dashboardGraphUpdate', dashboardGraphUpdateAPI),
    yield takeLatest('dashboard/dashboardRecentChatsStart', dashboardRecentChatsAPI),
  ]);
}


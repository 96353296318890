import React, { useEffect } from 'react';
import Base from './components/Base';
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify";
import { Tooltip } from 'react-tooltip'
//Css
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-tooltip/dist/react-tooltip.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css'

function App() {


  return (
    <>
      <Provider store={store}>
        <ToastContainer />
        <Tooltip id="tooltip-secondary" className="secondary" />
        <Tooltip id="tooltip-info" className="info" />
        <Tooltip id="tooltip-error" className="error" />
        <Tooltip id="tooltip-success" className="success" />
        <Tooltip id="tooltip-dark" className="dark" />
        <Base />
      </Provider>
    </>
  );
}

export default App;

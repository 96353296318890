import React from "react";
import { Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const DashboardLoader = () => {
  return (
    <>
      <Col md={12} lg={12} xl={6} className="resp-mrg-btm-xl">
        <div className="admin-ui-dashboard-header-left-sec">
          <Skeleton count={1} height={250} />
          <div className="admin-ui-dashboard-header-left-main-box">
            <Skeleton count={2} height={122.5} />
          </div>
        </div>
      </Col>
      <Col md={12} lg={12} xl={6}>
        <div className="admin-ui-dashboard-analytics-sec">
          <div className="new-setting-analytics-box">
            {[...Array(3)].map((i) => (
              <>
                <Skeleton count={1} height={250} />
              </>
            ))}
          </div>
        </div>
      </Col>
    </>
  );
};

export default DashboardLoader;

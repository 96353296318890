import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { useDispatch, useSelector } from "react-redux";
import {
  singleBotViewStart,
} from "../../../store/slices/BotSlice";
import NoDataFound from "../../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { useTranslation, withTranslation } from "react-multi-lang";
// import { DateRangePicker } from "materialui-daterange-picker";


const SettingAnalyticsIndex = (props) => {

  const t = useTranslation("settings.analytics")
  const dispatch = useDispatch();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const singleBotView = useSelector((state) => state.bots.singleBotView);

  // const [open, setOpen] = React.useState(true);
  // const [dateRange, setDateRange] = React.useState({});

  useEffect(()=>{
    if(Object.keys(selectedBot).length>0)
      dispatch(singleBotViewStart({bot_id : selectedBot.bot_id}))
  }, [selectedBot]);

  return (
    <>
      <div className="new-setting-analytics-sec">
        <div className="new-setting-analytics-header-sec">
          <h3>{t("heading")}</h3>
          {/* <div className="new-setting-analytics-calander-sec">
            <DateRangePicker
              open={open}
              onChange={(range) => setDateRange(range)}
            />

          </div> */}
        </div>
        <div className="new-setting-analytics-box">
        {singleBotView.loading ? (
          [...Array(5)].map((i) => (
            <div className="mb-3">
              <Skeleton height={10} width="100%" />
              <Skeleton height={50} width="100%" />
            </div>
          ))
        ) : 
        Object.keys(singleBotView.data).length > 0 ? (
          <>
          <div className="new-setting-analytics-card">
            <div className="new-setting-analytics-top-sec">
              <div className="new-setting-analytics-icon-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#00195E"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                  <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                </svg>
              </div>
              <div className="new-setting-analytics-info-sec">
                <h6>{t("message")}</h6>
                <h4>{singleBotView.data.bot.chat_messages_count} / {singleBotView.data.total_chat_messages_count}</h4>
              </div>
            </div>
            {/* <div className="new-setting-analytics-progress-bar-sec">
              <ProgressBar completed={singleBotView.data.bot.chat_messages_count} maxCompleted={singleBotView.data.subscription.total_tokens} height="10px" isLabelVisible={false}/>
            </div>
            <div className="new-setting-analytics-bottom-sec">
                <p>{singleBotView.data.subscription.total_tokens - singleBotView.data.bot.chat_messages_count} {t("remaining")}</p>
            </div> */}
          </div>
          <div className="new-setting-analytics-card">
            <div className="new-setting-analytics-top-sec">
              <div className="new-setting-analytics-icon-sec">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#00195E"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                  </svg>
              </div>
              <div className="new-setting-analytics-info-sec">
                <h6>{t("fine_tuning")}</h6>
                <h4>{singleBotView.data.bot.fine_tuning_models_count ?? 0} / {singleBotView.data.total_fine_tuning_models_count}</h4>
              </div>
            </div>
            {/* <div className="new-setting-analytics-progress-bar-sec">
              <ProgressBar completed={singleBotView.data.bot.fine_tuning_models_count ?? 0} maxCompleted={singleBotView.data.subscription.total_fine_tunings} height="10px" isLabelVisible={false}/>
            </div>
            <div className="new-setting-analytics-bottom-sec">
                <p>{singleBotView.data.subscription.total_fine_tunings - (singleBotView.data.bot.fine_tuning_models_count ?? 0)} {t("remaining")}</p>
            </div> */}
          </div>
          {/* <div className="new-setting-analytics-card">
            <div className="new-setting-analytics-top-sec">
              <div className="new-setting-analytics-icon-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#00195E"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                  <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                </svg>
              </div>
              <div className="new-setting-analytics-info-sec">
                <h6>Chat</h6>
                <h4>231 / 3,000</h4>
              </div>
            </div>
            <div className="new-setting-analytics-progress-bar-sec">
              <ProgressBar completed="231" maxCompleted={3000} height="10px" isLabelVisible={false}/>
            </div>
            <div className="new-setting-analytics-bottom-sec">
                <p>2,389 Remaining</p>
            </div>
          </div> */}
          </>
        ) : (
          <NoDataFound />
        )}
        </div>
      </div>
    </>
  );
};

export default withTranslation(SettingAnalyticsIndex);

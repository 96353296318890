import React, { useEffect, useState } from "react";
import { Image, InputGroup, Form, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import Pagination from "../Helper/Pagination";
import {
  subscriptionListStart,
  billingInvoiceDownloadStart,
} from "../../store/slices/SubscriptionSlice";
import { profileStart } from "../../store/slices/AdminSlice";
import Skeleton from "react-loading-skeleton";
import { Field, Form as FORM, Formik } from "formik";
import NoBillingExist from "../Helper/NoBillingExist";

const BillingsIndex = (props) => {
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #32089F!important",
      borderRadius: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor:"pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#32089F",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#32089F",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const profile = useSelector((state) => state.admin.profile);
  const subscriptionList = useSelector(
    (state) => state.subscription.subscriptionList
  );
  const billingInvoiceDownload = useSelector(
    (state) => state.subscription.billingInvoiceDownload
  );
  const [skipRender, setSkipRender] = useState(true);
  const [filterStatus, setFilterStatus] = useState(false);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    order_by: searchParams.get("order_by") ? searchParams.get("order_by") : "",
  });

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "price_hl", label: "Price High to Low" },
    { value: "price_lh", label: "Price Low to High" },
  ];

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        subscriptionListStart({
          ...filter,
          skip: 12 * (page - 1),
          take: 12,
        })
      );
    }
  }, [profile, page, filter]);

  useEffect(() => {
    if(!skipRender && !billingInvoiceDownload.loading && Object.keys(billingInvoiceDownload.data).length > 0){
      const link = document.createElement("a");
      link.href = billingInvoiceDownload.data.url;
      link.setAttribute("target", '_blank'); //or any other extension
      link.setAttribute("download", `order_.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  }, [billingInvoiceDownload])

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    params += searchParams.get("order_by")
      ? `order_by=${searchParams.get("order_by")}&`
      : "";
    navigate(`/billings?${params}page=${selected + 1}`);
  };

  return (
    <>
      {profile.loading ? (
        <PageLoader />
      ) : Object.keys(profile.data).length > 0 ? (
        <>
          <div className="admin-ui-body-sec">
            <div className="new-billings-sec">
              <div className="new-billings-header-sec">
                <div className="new-billings-header-left-sec">
                  <h3>Payment History</h3>
                  <div className="new-billing-search-sec">
                    <Formik
                      initialValues={{
                        search_key: searchParams.get("search_key")
                          ? searchParams.get("search_key")
                          : "",
                        order_by: searchParams.get("order_by")
                          ? searchParams.get("order_by")
                          : filter.order_by,
                      }}
                      onSubmit={(val) => {
                        setFilter({
                          ...filter,
                          search_key: val.search_key.replace("#", ""),
                        });
                        navigate(
                          `/billings?search_key=${
                            val.search_key.replace('#',"")
                          }&order_by=${searchParams.get("order_by")}`
                        );
                      }}
                    >
                      {({ values, setFieldValue, resetForm }) => (
                        <FORM className="form">
                          <InputGroup className="mb-0">
                            <Field
                              placeholder="Search"
                              type="text"
                              className="form-control"
                              name="search_key"
                            />
                            <InputGroup.Text id="basic-addon2">
                              {searchParams.get("search_key") && (
                                <button
                                  type="button"
                                  className="search-btn"
                                  onClick={() => {
                                    setFieldValue("search_key", "");
                                    setFilter({
                                      ...filter,
                                      search_key: "",
                                    });
                                    navigate(
                                      `/billings?order_by=${searchParams.get(
                                        "order_by"
                                      )}`
                                    );
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#3E4351"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M16.707 8.707L13.414 12l3.293 3.293-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12s4.486 10 10 10 10-4.486 10-10z"></path>
                                  </svg>
                                </button>
                              )}
                            </InputGroup.Text>
                            <InputGroup.Text id="basic-addon2">
                              <button className="search-btn" type="submit">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  fill="#3E4351"
                                  x="0"
                                  y="0"
                                  enableBackground="new 0 0 513.749 513.749"
                                  version="1.1"
                                  viewBox="0 0 513.749 513.749"
                                  xmlSpace="preserve"
                                >
                                  <path d="M504.352 459.061l-99.435-99.477c74.402-99.427 54.115-240.344-45.312-314.746S119.261-9.277 44.859 90.15-9.256 330.494 90.171 404.896c79.868 59.766 189.565 59.766 269.434 0l99.477 99.477c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269l.001-.043zm-278.635-73.365c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c-.094 88.326-71.673 159.906-160 160z"></path>
                                </svg>
                              </button>
                            </InputGroup.Text>
                          </InputGroup>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="new-billings-header-right-sec">
                  <Select
                    options={options}
                    styles={customStyles}
                    // menuIsOpen={true}
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      setFilter({
                        ...filter,
                        order_by: selectedOption.value,
                      });
                      navigate(
                        `/billings?search_key=${filter.search_key}&order_by=${selectedOption.value}`
                      );
                    }}
                    placeholder={
                      <div className="placeholder-flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#32089F"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M14 24a1 1 0 01-.6-.2l-4-3A1 1 0 019 20v-5.62L1.984 6.487A3.9 3.9 0 014.9 0h14.2a3.9 3.9 0 012.913 6.488L15 14.38V23a1 1 0 01-1 1zm-3-4.5l2 1.5v-7a1 1 0 01.253-.664l7.268-8.177A1.9 1.9 0 0019.1 2H4.9a1.9 1.9 0 00-1.421 3.158l7.269 8.178A1 1 0 0111 14z"></path>
                        </svg>
                        Filter
                      </div>
                    }
                  />
                  {/* <Button className="contact-us-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#32089F"
                      viewBox="0 0 24 24"
                    >
                      <g data-name="01 align center">
                        <path d="M12.032 19a2.991 2.991 0 002.122-.878l3.919-3.922-1.414-1.41-3.633 3.634L13 0h-2l.026 16.408-3.62-3.62L5.992 14.2l3.919 3.919a2.992 2.992 0 002.121.881z"></path>
                        <path d="M22 16v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v5a3 3 0 003 3h18a3 3 0 003-3v-5z"></path>
                      </g>
                    </svg>
                    Bulk Download
                  </Button> */}
                  {/* <Button className="billing-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#fff"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22.853 1.148a3.626 3.626 0 00-5.124 0L1.465 17.412A4.968 4.968 0 000 20.947V23a1 1 0 001 1h2.053a4.966 4.966 0 003.535-1.464L22.853 6.271a3.626 3.626 0 000-5.123zM5.174 21.122A3.022 3.022 0 013.053 22H2v-1.053a2.98 2.98 0 01.879-2.121L15.222 6.483l2.3 2.3zM21.438 4.857l-2.506 2.507-2.3-2.295 2.507-2.507a1.623 1.623 0 112.295 2.3z"></path>
                    </svg>
                    Edit Billing Address
                  </Button> */}
                </div>
              </div>
              <div className="new-billings-body-sec">
                <div className="new-billings-table-sec">
                  {subscriptionList.loading ? (
                    [...Array(3)].map((i) => (
                      <>
                        <Skeleton count={1} height={50} />
                        <Skeleton count={1} height={50} />
                        <Skeleton count={1} height={50} />
                      </>
                    ))
                  ) : Object.keys(subscriptionList.data).length > 0 ? (
                    <>
                      {subscriptionList.data.subscriptions &&
                      subscriptionList.data.subscriptions.length > 0 ? (
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>
                                <div className="invoice-checkbox">
                                  {/* <Form.Check aria-label="option 1" /> */}
                                  Invoice
                                </div>
                              </th>
                              <th>Payment ID</th>
                              <th>Paid Date</th>
                              <th>Plan</th>
                              <th>Amount</th>
                              <th>Actions</th>
                            </tr>
                          </thead>

                          <tbody>
                            {subscriptionList.data.subscriptions.map(
                              (subscription, i) => (
                                <tr>
                                  <td>
                                    <div className="invoice-checkbox">
                                      {/* <Form.Check aria-label="option 1" /> */}
                                      #{subscription.id}
                                    </div>
                                  </td>
                                  <td>{subscription.payment_id}</td>
                                  <td>{subscription.paid_date}</td>
                                  <td>
                                    <div className="billing-plan-details">
                                      <Image
                                        className="billing-plan-icon"
                                        src={
                                          window.location.origin +
                                          "/images/billings/base-plan.svg"
                                        }
                                      />
                                      {subscription.plan_name ?? "N/A"}
                                    </div>
                                  </td>
                                  <td>{subscription.amount}</td>
                                  <td>
                                    {billingInvoiceDownload.buttonDisable && 
                                    billingInvoiceDownload.data.subscription_unique_id == subscription.unique_id ? (
                                      <Image
                                        className="btn-loader"
                                        src={
                                          window.location.origin +
                                          "/images/loader/btn-loader.gif"
                                        }
                                      />
                                    ) : (
                                      <Button className="billing-download-btn" onClick={() => dispatch(billingInvoiceDownloadStart({subscription_unique_id: subscription.unique_id}))}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          fill="#32089F"
                                          viewBox="0 0 24 24"
                                        >
                                          <g data-name="01 align center">
                                            <path d="M12.032 19a2.991 2.991 0 002.122-.878l3.919-3.922-1.414-1.41-3.633 3.634L13 0h-2l.026 16.408-3.62-3.62L5.992 14.2l3.919 3.919a2.992 2.992 0 002.121.881z"></path>
                                            <path d="M22 16v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v5a3 3 0 003 3h18a3 3 0 003-3v-5z"></path>
                                          </g>
                                        </svg>
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoBillingExist />
                      )}
                      <div className="new-billings-footer-sec">
                        <div className="new-billings-pagination-sec">
                          <Pagination
                            page={page}
                            pageCount={Math.ceil(
                              subscriptionList.data.total_subscriptions / 12
                            )}
                            handlePageClick={handlePageClick}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <SomethingWentWrong
                      buttonText="Retry"
                      handleClick={() => dispatch(subscriptionListStart())}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SomethingWentWrong
          buttonText="Retry"
          handleClick={() => dispatch(profileStart())}
        />
      )}
    </>
  );
};

export default BillingsIndex;

import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./OnBoarding.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { forgotPasswordStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import OnBoardingVerificationModal from "./OnboardingVerificationModal";
import NavLogo from "../Layout/Header/NavLogo";

const AIasForgotPassword = (props) => {
  const t = useTranslation("forgot_password");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forgotPassword = useSelector((state) => state.admin.forgotPassword);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
  });

  const handleForgotPassword = (values) => {
    dispatch(forgotPasswordStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !forgotPassword.loading &&
      Object.keys(forgotPassword.data).length > 0 &&
      Object.keys(forgotPassword.data.user).length > 0
    ) {
        setShowVerify(forgotPassword.data.user);
    }
    setSkipRender(false);
  }, [forgotPassword]);


  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-header-sec">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <div className="onboarding-onpremise-title-sec">
                    <h2>{t("heading")}</h2>
                    <p>
                      {t("message")}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={forgotPasswordSchema}
              onSubmit={handleForgotPassword}
            >
              {({ setFieldValue, values }) => (
                <FORM noValidate className="onboarding-form">
                  <div className="onboarding-body-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            {t("email.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="email"
                            placeholder={t("email.placeholder")}
                            name="email"
                          />
                          <ErrorMessage
                          component={"div"}
                          name="email"
                          className="errorMsg"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="onboarding-footer-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <div className="ai-as-service-btn-sec">
                          <Button
                            className="onboarding-btn"
                            type="submit"
                            disabled={forgotPassword.buttonDisable}
                          >
                            {forgotPassword.buttonDisable
                              ? t("submit_btn.loading")
                              : t("submit_btn.text")}
                          </Button>
                        </div>
                        <div className="onbaording-link-sec">
                          {t("have_account")}
                          <Link to="/login">{t("login")}</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </div>
        <div className="onboarding-right-sec">
          <div className="onboarding-installation-content">
            <div className="onboarding-ai-as-service-img-sec">
              <Image
                className="onboarding-ai-as-service-img"
                src={
                  window.location.origin +
                  "/images/onboarding/choose-solution/ai-as-register-img.png"
                }
              />
            </div>
            <h3>Unlock the full potential of our service-based solutions and experience the transformative capabilities of our industry-specific platform.</h3>
            <p>
              Request a demo today to see how our services can drive exceptional outcomes tailored to your unique needs.
            </p>
            <ul className="list-unstyled onboarding-installation-list">
              <li>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    fill="#F4B01D"
                  />
                  <path
                    d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                    fill="white"
                    stroke="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    stroke="#F4B01D"
                  />
                </svg>
                <span>
                  Witness seamless conversion of customer interactions between channels while preserving data and context .
                </span>
              </li>
              <li>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    fill="#F4B01D"
                  />
                  <path
                    d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                    fill="white"
                    stroke="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    stroke="#F4B01D"
                  />
                </svg>
                <span>
                  Trust HaiVE's innovative and flexible platform designed specifically for your industry
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showVerify ?
        <OnBoardingVerificationModal forgotPassword={1} showVerify={showVerify} closeVerify={() => setShowVerify(false)} />
        : null}
    </>
  );
};

export default AIasForgotPassword;

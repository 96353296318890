import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  conversationsDeleteFailure,
  conversationsDeleteSuccess,
  conversationsListFailure,
  conversationsListSuccess,
  conversationsSaveFailure,
  conversationsSaveSuccess,
  customTrainingListFailure,
  customTrainingListSuccess,
  fineTuneDeleteFailure,
  fineTuneDeleteSuccess,
  fineTuneListFailure,
  fineTuneListSuccess,
  fineTuneSaveFailure,
  fineTuneSaveSuccess,
} from "../slices/FineTuneSlice";

function* fineTuneListAPI(action) {
  yield getCommonSaga({
    apiUrl: "fine_tune_models",
    payload: action.payload,
    successNotify: false,
    success: fineTuneListSuccess,
    failure: fineTuneListFailure,
  });
}

function* customTrainingListAPI(action) {
    const customTrainingListData = yield select((state) => state.fineTune.customTrainingList.data);  
    yield getCommonSaga({
    apiUrl: "custom_trainings",
    payload: action.payload,
    successCallback: true,
    successNotify: false,
    success: data => {
      if (Object.keys(customTrainingListData).length > 0 && customTrainingListData.custom_trainings)
      {
        store.dispatch(customTrainingListSuccess({
          ...customTrainingListData,
          custom_trainings: [...customTrainingListData.custom_trainings, ...data.custom_trainings],
          total_custom_training_count: data.total_custom_training_count,
        }));
      }
      else
      store.dispatch(customTrainingListSuccess(data));
    },
    failure: customTrainingListFailure,
  });
}

function* fineTuneSaveAPI(action) {
  const fineTuneListData = yield select(
    (state) => state.fineTune.fineTuneList.data
  );
  const customTrainingListData = yield select(
    (state) => state.fineTune.customTrainingList.data
  );
  yield getCommonSaga({
    apiUrl: "fine_tune_models/store",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      store.dispatch(fineTuneSaveSuccess(data));
      if (Object.keys(fineTuneListData).length > 0) {
        const item = fineTuneListData.fine_tune_models
          ? fineTuneListData.fine_tune_models.find(
              (tune) => tune.id === data.fine_tune_model.id
            )
          : "";
        store.dispatch(
          fineTuneListSuccess({
            ...fineTuneListData,
            fine_tune_models: item
              ? fineTuneListData.fine_tune_models.map((tune) =>
                  tune.id === data.fine_tune_model.id
                    ? data.fine_tune_model
                    : tune
                )
              : [data.fine_tune_model, ...fineTuneListData.fine_tune_models],
            total_fine_tune_models: fineTuneListData.total_fine_tune_models + 1,
            all_fine_tune_count: fineTuneListData.all_fine_tune_count + 1,
            docs_fine_tune_count:
              data.fine_tune_model.file_type !== "link"
                ? fineTuneListData.docs_fine_tune_count + 1
                : fineTuneListData.docs_fine_tune_count,
            links_fine_tune_count:
              data.fine_tune_model.file_type === "link"
                ? fineTuneListData.links_fine_tune_count + 1
                : fineTuneListData.links_fine_tune_count,
          })
        );
      }
      if(Object.keys(customTrainingListData).length > 0)
      {
        const item = customTrainingListData.custom_trainings
          ? customTrainingListData.custom_trainings.find(
              (tune) => tune.id === data.fine_tune_model.id
            )
          : "";
        store.dispatch(
          customTrainingListSuccess({
            ...customTrainingListData,
            custom_trainings: item
              ? customTrainingListData.custom_trainings.map((tune) =>
                  tune.id === data.fine_tune_model.id
                    ? data.fine_tune_model
                    : tune
                )
              : [data.fine_tune_model, ...customTrainingListData.custom_trainings],
              total_custom_training_count: customTrainingListData.total_custom_training_count + 1,
          })
        );
      }
    },
    failure: fineTuneSaveFailure,
  });
}

function* fineTuneDeleteAPI(action) {
  const fineTuneListData = yield select(
    (state) => state.fineTune.fineTuneList.data
  );
  const customTrainingListData = yield select(
    (state) => state.fineTune.customTrainingList.data
  );
  yield getCommonSaga({
    apiUrl: "fine_tune_models/destroy",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      const deletedFineTune = fineTuneListData.fine_tune_models.find(
        (tune) => tune.id === action.payload.fine_tune_model_id
      );
      store.dispatch(fineTuneDeleteSuccess());
      store.dispatch(
        fineTuneListSuccess({
          ...fineTuneListData,
          fine_tune_models: fineTuneListData.fine_tune_models.filter(
            (tune) => tune.id !== action.payload.fine_tune_model_id
          ),
          total_fine_tune_models: fineTuneListData.total_fine_tune_models - 1,
          all_fine_tune_count: fineTuneListData.all_fine_tune_count - 1,
          docs_fine_tune_count:
            deletedFineTune.file_type !== "link"
              ? fineTuneListData.docs_fine_tune_count - 1
              : fineTuneListData.docs_fine_tune_count,
          links_fine_tune_count:
            deletedFineTune.file_type === "link"
              ? fineTuneListData.links_fine_tune_count - 1
              : fineTuneListData.links_fine_tune_count,
        })
      );
      if(Object.keys(customTrainingListData).length > 0)
      {
        store.dispatch(
          customTrainingListSuccess({
            ...customTrainingListData,
            custom_trainings: customTrainingListData.custom_trainings.filter((tune) =>
                  tune.id !== action.payload.fine_tune_model_id),
              total_custom_training_count: customTrainingListData.total_custom_training_count - 1,
          })
        );
      }
    },
    failure: fineTuneDeleteFailure,
  });
}

function* conversationsListAPI(action) {
  const conversationsListData = yield select(
    (state) => state.fineTune.conversationsList.data
  );
  yield getCommonSaga({
    apiUrl: "conversational_data",
    payload: action.payload,
    successCallback: true,
    successNotify: false,
    success: data => {
      if (Object.keys(conversationsListData).length > 0)
      {
        store.dispatch(conversationsListSuccess({
          ...conversationsListData,
          conversational_data: [...conversationsListData.conversational_data, ...data.conversational_data],
          total_conversational_data: data.total_conversational_data,
        }));
      }
      else
      store.dispatch(conversationsListSuccess(data));
    },
    failure: conversationsListFailure,
  });
}

function* conversationsSaveAPI(action) {
  const conversationsListData = yield select(
    (state) => state.fineTune.conversationsList.data
  );
  const fineTuneListData = yield select(
    (state) => state.fineTune.fineTuneList.data
  );
  const customTrainingListData = yield select(
    (state) => state.fineTune.customTrainingList.data
  );
  yield getCommonSaga({
    apiUrl: "conversational_data/store",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(conversationsSaveSuccess(data));
      if (Object.keys(conversationsListData).length > 0) {
        store.dispatch(
          conversationsListSuccess({
            ...conversationsListData,
            conversational_data: [data.conversational_data, ...conversationsListData.conversational_data],
            total_conversational_data: conversationsListData.total_conversational_data + 1,
          })
        );
      }
      if (Object.keys(fineTuneListData).length > 0) {
        store.dispatch(
          fineTuneListSuccess({
            ...fineTuneListData,
            total_conversational_data_count: fineTuneListData.total_conversational_data_count + 1,
            total_fine_tune_models: fineTuneListData.total_fine_tune_models + 1,
          })
        );
      }
      if(Object.keys(customTrainingListData).length > 0)
      {
        const item = customTrainingListData.custom_trainings
          ? customTrainingListData.custom_trainings.find(
              (tune) => tune.id === data.conversational_data.id
            )
          : "";
        store.dispatch(
          customTrainingListSuccess({
            ...customTrainingListData,
            custom_trainings: item
              ? customTrainingListData.custom_trainings.map((tune) =>
                  tune.id === data.conversational_data.id
                    ? data.conversational_data
                    : tune
                )
              : [data.conversational_data, ...customTrainingListData.custom_trainings],
              total_custom_training_count: customTrainingListData.total_custom_training_count + 1,
          })
        );
      }
    },
    failure: conversationsSaveFailure,
  });
}

function* conversationsDeleteAPI(action) {
  const conversationsListData = yield select(
    (state) => state.fineTune.conversationsList.data
  );
  const fineTuneListData = yield select(
    (state) => state.fineTune.fineTuneList.data
  );
  const customTrainingListData = yield select(
    (state) => state.fineTune.customTrainingList.data
  );
  yield getCommonSaga({
    apiUrl: "conversational_data/destroy",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(conversationsDeleteSuccess(data));
      if (Object.keys(conversationsListData).length > 0) {
        store.dispatch(
          conversationsListSuccess({
            ...conversationsListData,
            conversational_data: conversationsListData.conversational_data.filter(
              (tune) => tune.id !== action.payload.conversational_data_id
            ),
            total_conversational_data: conversationsListData.total_conversational_data - 1,
          })
        );
      }
      if (Object.keys(fineTuneListData).length > 0) {
        store.dispatch(
          fineTuneListSuccess({
            ...fineTuneListData,
            total_conversational_data_count: fineTuneListData.total_conversational_data_count - 1,
            total_fine_tune_models: fineTuneListData.total_fine_tune_models - 1,
          })
        );
      }
      if(Object.keys(customTrainingListData).length > 0)
      {
        store.dispatch(
          customTrainingListSuccess({
            ...customTrainingListData,
            custom_trainings: customTrainingListData.custom_trainings.filter((tune) =>
                  tune.id !== action.payload.conversational_data_id),
              total_custom_training_count: customTrainingListData.total_custom_training_count - 1,
          })
        );
      }
    },
  });
}


export default function* FineTuneSaga() {
  yield all([
    yield takeLatest("finetune/fineTuneListStart", fineTuneListAPI),
    yield takeLatest("finetune/fineTuneSaveStart", fineTuneSaveAPI),
    yield takeLatest("finetune/fineTuneDeleteStart", fineTuneDeleteAPI),
    yield takeLatest("finetune/conversationsListStart", conversationsListAPI),
    yield takeLatest('finetune/moreConversationsListStart', conversationsListAPI),
    yield takeLatest("finetune/conversationsSaveStart", conversationsSaveAPI),
    yield takeLatest("finetune/conversationsDeleteStart", conversationsDeleteAPI),
    yield takeLatest("finetune/customTrainingListStart", customTrainingListAPI),
    yield takeLatest("finetune/moreCustomTrainingListStart", customTrainingListAPI),
  ]);
}

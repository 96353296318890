import React from "react";
import { Image, Button } from "react-bootstrap";
// import "./NewUI.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../../store/slices/CommonSlice";

const CollapseSidebar = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <>
      <div className={`admin-ui-collapse-sidebar`}>
        <div className="admin-ui-sidebar-collapse">
          <div className="admin-ui-logo-sec">
            <Link className="" to="/">
              <Image
                className="admin-ui-logo"
                src={
                  window.location.origin + "/images/haive-text-logo.svg"
                }
              />
            </Link>
            <Button className="offcanvas-close" onClick={()=>dispatch(toggleSidebar())}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="#fff"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M23.341 9.48l-3.501-6A5.016 5.016 0 0015.522 1H8.451a5.02 5.02 0 00-4.319 2.48l-3.501 6a5.01 5.01 0 000 5.039l3.501 6a5.016 5.016 0 004.318 2.48h7.071a5.02 5.02 0 004.319-2.48l3.5-6a5.01 5.01 0 000-5.039zm-1.729 4.031l-3.499 6a3.011 3.011 0 01-2.592 1.488H8.45a3.01 3.01 0 01-2.591-1.488l-3.5-6a3.004 3.004 0 010-3.023l3.499-6A3.011 3.011 0 018.45 3h7.071a3.01 3.01 0 012.591 1.488l3.5 6a3.004 3.004 0 010 3.023zm-5.905-3.805l-2.293 2.293 2.293 2.293a.999.999 0 11-1.414 1.414L12 13.413l-2.293 2.293a.997.997 0 01-1.414 0 .999.999 0 010-1.414l2.293-2.293-2.293-2.293a.999.999 0 111.414-1.414L12 10.585l2.293-2.293a.999.999 0 111.414 1.414z"></path>
              </svg>
            </Button>
          </div>
          <div className="admin-ui-collapse-body">
            <ul className="admin-ui-sidebar-list list-unstyled" onClick={()=>dispatch(toggleSidebar())}>
              <li>
                <Link to="/" className={`${location.pathname === "/" && "active"}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M7 0H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM20 0h-3a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2h-3a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM7 13H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2zM20 13h-3a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2h-3a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2z"></path>
                  </svg>
                  <span>DashBoard</span>
                </Link>
              </li>
              <li>
                <Link to="/bots" className={`${location.pathname === "/bots" && "active"}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 23a1 1 0 11-2 0c0-2.206-1.794-4-4-4H9c-2.206 0-4 1.794-4 4a1 1 0 11-2 0c0-3.309 2.691-6 6-6h6c3.309 0 6 2.691 6 6zm1-15.5v2c0 .827-.673 1.5-1.5 1.5H20c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4h-.5C2.673 11 2 10.327 2 9.5v-2C2 6.673 2.673 6 3.5 6H4c0-2.206 1.794-4 4-4h3V1a1 1 0 112 0v1h3c2.206 0 4 1.794 4 4h.5c.827 0 1.5.673 1.5 1.5zM18 6c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2v5c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6zM9.5 7a1.5 1.5 0 10.001 3.001A1.5 1.5 0 009.5 7zm5 0a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0014.5 7z"></path>
                  </svg>
                  <span>Your Bots</span>
                </Link>
              </li>
              {/* <li>
                <Link to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                  </svg>
                  <span>Fine Tuning</span>
                </Link>
              </li> */}
              {/* <li>
                <Link to="/billings" className={`${location.pathname === "/billings" && "active"}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16 0H8a5.006 5.006 0 00-5 5v18a1 1 0 001.564.825l2.106-1.439 2.106 1.439a1 1 0 001.13 0l2.1-1.439 2.1 1.439a1 1 0 001.131 0l2.1-1.438 2.1 1.437A1 1 0 0021 23V5a5.006 5.006 0 00-5-5zm3 21.1l-1.1-.752a1 1 0 00-1.132 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.131 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.129 0L5 21.1V5a3 3 0 013-3h8a3 3 0 013 3z"></path>
                    <rect width="10" height="2" x="7" y="8" rx="1"></rect>
                    <rect width="8" height="2" x="7" y="12" rx="1"></rect>
                  </svg>
                  <span>Billing</span>
                </Link>
              </li> */}
              <li>
                <Link to="/settings" className={`${location.pathname === "/settings" && "active"}`}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zM8 21.164V19c0-.552.449-1 1-1h6c.551 0 1 .448 1 1v2.164c-1.226.537-2.578.836-4 .836s-2.774-.299-4-.836zm10-1.169V19c0-1.654-1.346-3-3-3H9c-1.654 0-3 1.346-3 3v.995A9.991 9.991 0 012 12C2 6.486 6.486 2 12 2s10 4.486 10 10a9.992 9.992 0 01-4 7.995zM12 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                    <path d="M21.294 13.9l-.444-.256a9.1 9.1 0 000-3.29l.444-.256a3 3 0 10-3-5.2l-.445.257A8.977 8.977 0 0015 3.513V3a3 3 0 00-6 0v.513a8.977 8.977 0 00-2.848 1.646L5.705 4.9a3 3 0 00-3 5.2l.444.256a9.1 9.1 0 000 3.29l-.444.256a3 3 0 103 5.2l.445-.257A8.977 8.977 0 009 20.487V21a3 3 0 006 0v-.513a8.977 8.977 0 002.848-1.646l.447.258a3 3 0 003-5.2zm-2.548-3.776a7.048 7.048 0 010 3.75 1 1 0 00.464 1.133l1.084.626a1 1 0 01-1 1.733l-1.086-.628a1 1 0 00-1.215.165 6.984 6.984 0 01-3.243 1.875 1 1 0 00-.751.969V21a1 1 0 01-2 0v-1.252a1 1 0 00-.751-.969A6.984 6.984 0 017.006 16.9a1 1 0 00-1.215-.165l-1.084.627a1 1 0 11-1-1.732l1.084-.626a1 1 0 00.464-1.133 7.048 7.048 0 010-3.75 1 1 0 00-.465-1.129l-1.084-.626a1 1 0 011-1.733l1.086.628A1 1 0 007.006 7.1a6.984 6.984 0 013.243-1.875A1 1 0 0011 4.252V3a1 1 0 012 0v1.252a1 1 0 00.751.969A6.984 6.984 0 0116.994 7.1a1 1 0 001.215.165l1.084-.627a1 1 0 111 1.732l-1.084.626a1 1 0 00-.463 1.129z"></path>
                  </svg>
                  <span>Settings</span>
                </Link>
              </li>
              <li>
                <Link to="/inbox" className={`${location.pathname === "/inbox" && "active"}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                    <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                  </svg>
                  <span>Chat</span>
                </Link>
              </li>
              <li>
                <Link to="/subscription" className={`${location.pathname === "/subscription" && "active"}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12.836.029a12.043 12.043 0 00-9.327 3.492 12.027 12.027 0 00-3.48 9.333C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.862 19.097.454 12.836.029zM22 19c0 1.654-1.346 3-3 3h-5.917c-6.034 0-10.686-3.905-11.058-9.285a10.027 10.027 0 012.898-7.782 10.019 10.019 0 017.778-2.91C17.915 2.377 22 6.909 22 12.339v6.66zm-6-5c0 1.654-1.346 3-3 3v1a1 1 0 11-2 0v-1h-.268a3.01 3.01 0 01-2.598-1.499 1 1 0 111.73-1.001c.179.308.511.5.867.5h2.268c.552 0 1-.449 1-1a.768.768 0 00-.644-.76l-3.041-.507A2.761 2.761 0 017.999 10c0-1.654 1.346-3 3-3V6a1 1 0 112 0v1h.268c1.067 0 2.063.575 2.598 1.5a1 1 0 11-1.73 1.001 1.004 1.004 0 00-.867-.5H11c-.552 0-1 .449-1 1 0 .378.271.698.644.76l3.041.507A2.761 2.761 0 0116 14.001z"></path>
                  </svg>
                  <span>Subscription</span>
                </Link>
              </li>
              <li>
                <a href="https://haive.tech/" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M11.904 16a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0111.904 16zm1-2c0-.561.408-1.225.928-1.512a4.021 4.021 0 002.009-4.223c-.283-1.613-1.607-2.938-3.221-3.221a4.034 4.034 0 00-3.289.874 3.995 3.995 0 00-1.428 3.063 1 1 0 102 0 2.002 2.002 0 012.371-1.967c.787.138 1.458.81 1.596 1.596a1.995 1.995 0 01-1.004 2.125c-1.156.637-1.963 1.979-1.963 3.264a1 1 0 102 0zM24 19v-6.66C24 5.861 19.096.454 12.836.028a12.047 12.047 0 00-9.327 3.493A12.024 12.024 0 00.03 12.854C.47 19.208 6.082 24 13.084 24h5.917c2.757 0 5-2.243 5-5zM12.701 2.024C17.916 2.378 22 6.909 22 12.339v6.66c0 1.654-1.346 3-3 3h-5.917c-6.035 0-10.686-3.904-11.059-9.284a10.02 10.02 0 012.899-7.781A10.016 10.016 0 0112.7 2.023z"></path>
                  </svg>
                  <span>Support</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollapseSidebar;

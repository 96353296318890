import React from 'react';
import Sidebar from './Sidebar';
import CollapseSidebar from './CollapseSidebar';
import { useSelector } from 'react-redux';


export default function AdminSidebar() {
    const sidebar = useSelector(state => state.common.sidebar);
    return (
        sidebar ?
            <CollapseSidebar />
            : <Sidebar />
    );
}
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fineTuneDeleteStart,
  fineTuneListStart,
} from "../../../store/slices/FineTuneSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../Helper/NoDataFound";
import { Link } from "react-router-dom";

const FinetuneIndex = (props) => {
  const dispatch = useDispatch();

  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const fineTuneList = useSelector((state) => state.fineTune.fineTuneList);
  const fineTuneDelete = useSelector((state) => state.fineTune.fineTuneDelete);

  useEffect(() => {
    dispatch(
      fineTuneListStart({ bot_id: selectedBot.bot_id, ...props })
    );
  }, [selectedBot]);

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return "progress-badge";
      case 2:
        return "trained-badge";
      case 3:
        return "failed-badge";
      default:
        return "uploaded-badge";
    }
  };

  const handleDeleteFineTuning = (modelId) => {
    dispatch(fineTuneDeleteStart({ fine_tune_model_id: modelId }));
  };

  return (
    <div className="new-settings-finetune-table-sec">
      {fineTuneList.loading ? (
        <div className="onboarding-fine-tunings-files-box">
          {[...Array(4)].map((i) => (
            <Skeleton height={50} />
          ))}
        </div>
      ) : Object.keys(fineTuneList.data).length > 0 &&
        fineTuneList.data.fine_tune_models &&
        fineTuneList.data.fine_tune_models.length > 0 ? (
          <Table responsive="md">
            <tbody>
              {fineTuneList.data.fine_tune_models.map((model) => (props.file_type ? model.file_type === props.file_type : 1) && (
                <tr>
                  <td>{model.name}</td>
                  <td>
                    <span>Updated on:</span> {model.updated_at}
                  </td>
                  <td>
                    {model.import_url && model.web_scrap_status != 2 ? (
                      <div className={getStatusName(model.web_scrap_status)}>
                        Web Scrape {model.web_scrap_status_formatted}
                      </div>
                    ) : (
                      <div className={getStatusName(model.training_status)}>
                        Training Model {model.training_status_formatted}
                      </div>
                    )}
                  </td>
                  <td className="recent-chat-menu-btn">
                    {fineTuneDelete.buttonDisable &&
                    fineTuneDelete.data.fine_tune_model_id == model.id ? (
                      <Image
                        className="btn-loader"
                        src={
                          window.location.origin +
                          "/images/loader/btn-loader.gif"
                        }
                      />
                    ) : (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="recent-chat-menu-icon-sec">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              x="0"
                              y="0"
                              enableBackground="new 0 0 512 512"
                              version="1.1"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                            >
                              <circle cx="458.667" cy="256" r="53.333"></circle>
                              <circle cx="256" cy="256" r="53.333"></circle>
                              <circle cx="53.333" cy="256" r="53.333"></circle>
                            </svg>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {model.file_type === "link" ? (
                            <Dropdown.Item
                              href={model.import_url}
                              target="_blank"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                fill="#546594"
                                height="20"
                                viewBox="0 0 24 24"
                              >
                                <path d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"></path>
                                <path d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"></path>
                              </svg>
                              View
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              download={true}
                              target="_blank"
                              href={model.file}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                fill="#546594"
                                height="20"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M17.974 7.146a1.028 1.028 0 01-.742-.569c-1.552-3.271-5.143-5.1-8.735-4.438A7.946 7.946 0 002.113 8.64a8.13 8.13 0 00.033 2.89c.06.309-.073.653-.346.901a5.51 5.51 0 00-1.801 4.07c0 3.032 2.467 5.5 5.5 5.5h11c4.136 0 7.5-3.364 7.5-7.5 0-3.565-2.534-6.658-6.026-7.354zM16.5 20h-11C3.57 20 2 18.43 2 16.5a3.51 3.51 0 011.146-2.59c.786-.715 1.155-1.773.963-2.763a6.142 6.142 0 01-.024-2.181c.403-2.422 2.365-4.421 4.771-4.862A6.4 6.4 0 0110.001 4a5.95 5.95 0 015.422 3.434 3.018 3.018 0 002.158 1.673 5.51 5.51 0 014.417 5.394c0 3.032-2.467 5.5-5.5 5.5zm-1.379-6.707a.999.999 0 010 1.414l-2.707 2.707a1.993 1.993 0 01-1.405.584L11 18l-.009-.002a1.993 1.993 0 01-1.405-.584l-2.707-2.707a.999.999 0 111.414-1.414L10 15v-5a1 1 0 112 0v5l1.707-1.707a.999.999 0 011.414 0z"></path>
                              </svg>
                              Download
                            </Dropdown.Item>
                          )}
                          {model.training_status != 1 && <Dropdown.Item
                            disabled={fineTuneDelete.buttonDisable}
                            onClick={() => handleDeleteFineTuning(model.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              fill="#546594"
                              height="20"
                              viewBox="0 0 24 24"
                            >
                              <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                              <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                            </svg>
                            Delete
                          </Dropdown.Item>}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
};

export default FinetuneIndex;

import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  Row,
  Col,
  Button,
  ProgressBar,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DashBoardBotCard from "./DashBoardBotCard";
import DashBoardRecentChatIndex from "./DashBoardRecentChatIndex";
import DashboardTicketsChart from "./ChatsGraph";
import { dashboardGraphStart, dashboardGraphUpdate, dashboardRecentChatsStart, dashboardStart } from "../../store/slices/DashboardSlice";
import ChatsGraph from "./ChatsGraph";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation, withTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import TableLoader from "../Helper/TableLoader";
import DashboardLoader from "../Helper/DashboardLoader";

const DashBoardIndex = (props) => {
  
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#32089F12!important",
      border: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor:"pointer",
      fontSize: "0.9em",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const dispatch = useDispatch();
  const t = useTranslation("dashboard");
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const dashboardGraph = useSelector((state) => state.dashboard.dashboardGraph);
  const dashboardRecentChats = useSelector((state) => state.dashboard.dashboardRecentChats);
  const profile = useSelector((state) => state.admin.profile);
  const [fineTuningFilter, setFineTuningFilter] = useState(7);
  const [chatFilter, setChatFilter] = useState(7);

  const options = [
    { value: 7, label: t("last_7_days") },
    { value: 30, label: t("last_month") },
    // { value: 365, label: 'Last Year' },
  ];
  
  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(dashboardStart());
      dispatch(dashboardGraphStart());
      dispatch(dashboardRecentChatsStart());
    }
  }, [profile]);

  const handleFineTuningFilter = (selectedOption) => {
    setFineTuningFilter(selectedOption.value);
    dispatch(
      dashboardGraphUpdate({
        fine_tuning_days_count: selectedOption.value,
        chats_days_count: chatFilter,
      })
    );
  }

  const handleChatFilter = (selectedOption) => {
    setChatFilter(selectedOption.value);
    dispatch(
      dashboardGraphUpdate({
        fine_tuning_days_count: fineTuningFilter,
        chats_days_count: selectedOption.value,
      })
    );
  }

  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="admin-ui-dashboard-sec">
          <div className="admin-ui-dashboard-header-sec">
            <Row>
              {dashboard.loading ? <DashboardLoader /> : Object.keys(dashboard.data).length > 0 &&
                Object.keys(dashboard.data.current_plan).length > 0 ? (
                <>
                  <Col md={12} lg={12} xl={6} className="resp-mrg-btm-xl">
                    <div className="admin-ui-dashboard-header-left-sec">
                      <div className="admin-ui-dashboard-header-main-card">
                        <div className="admin-ui-dashboard-header-main-info">
                          <h6>{t("current_plan")}</h6>
                          <h3>
                            {dashboard.data.current_plan.amount}{" "}
                            <span>
                              ({dashboard.data.current_plan.type_formatted})
                            </span>
                          </h3>
                          <p>
                            <span>{t("expiry")}</span> :{" "}
                            {dashboard.data.current_plan.expiry_date}{" "}
                          </p>
                          <Link to="/subscription" className="white-btn">
                            {t("upgrade")}
                          </Link>
                        </div>
                        <div className="admin-ui-dashboard-header-main-icon-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="#32089F"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12.836.029a12.043 12.043 0 00-9.327 3.492 12.027 12.027 0 00-3.48 9.333C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.862 19.097.454 12.836.029zM22 19c0 1.654-1.346 3-3 3h-5.917c-6.034 0-10.686-3.905-11.058-9.285a10.027 10.027 0 012.898-7.782 10.019 10.019 0 017.778-2.91C17.915 2.377 22 6.909 22 12.339v6.66zm-6-5c0 1.654-1.346 3-3 3v1a1 1 0 11-2 0v-1h-.268a3.01 3.01 0 01-2.598-1.499 1 1 0 111.73-1.001c.179.308.511.5.867.5h2.268c.552 0 1-.449 1-1a.768.768 0 00-.644-.76l-3.041-.507A2.761 2.761 0 017.999 10c0-1.654 1.346-3 3-3V6a1 1 0 112 0v1h.268c1.067 0 2.063.575 2.598 1.5a1 1 0 11-1.73 1.001 1.004 1.004 0 00-.867-.5H11c-.552 0-1 .449-1 1 0 .378.271.698.644.76l3.041.507A2.761 2.761 0 0116 14.001z"></path>
                          </svg>
                        </div>
                      </div>
                      <div className="admin-ui-dashboard-header-left-main-box">
                        <div className="admin-ui-dashboard-header-left-main-card">
                          <div className="admin-ui-dashboard-header-left-main-card-info-sec">
                            <p>{t("today_chats")}</p>
                            <h3>{dashboard.data.today_chats}</h3>
                          </div>
                          <div className="admin-ui-dashboard-header-left-main-box-icon-sec">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="none"
                              viewBox="0 0 25 24"
                            >
                              <g clipPath="url(#clip0_993_8274)">
                                <path
                                  fill="#fff"
                                  d="M21.75 7.094v1.812a.906.906 0 11-1.813 0V7.094a2.722 2.722 0 00-2.718-2.719H4.53a2.722 2.722 0 00-2.718 2.719V8h8.156a.907.907 0 010 1.813H1.813v8.156a2.722 2.722 0 002.718 2.718h3.625a.907.907 0 010 1.813H4.531A4.536 4.536 0 010 17.969V7.094a4.536 4.536 0 014.531-4.532h.907v-.906a.907.907 0 011.812 0v.907h7.25v-.907a.906.906 0 111.813 0v.907h.906a4.536 4.536 0 014.531 4.53z"
                                ></path>
                              </g>
                              <path
                                fill="#fff"
                                stroke="#fff"
                                d="M17.595 10.5c.004.003.01.003.021.003 3.52 0 6.384 2.812 6.384 6.269 0 1.41-.49 2.78-1.388 3.884l.929 2.118a.338.338 0 01-.181.448.36.36 0 01-.198.023l-3.406-.587a6.24 6.24 0 01-2.122.362c-3.52 0-6.384-2.811-6.384-6.268-.007-3.447 2.835-6.249 6.345-6.252zm.035 11.84c.672 0 1.336-.12 1.966-.352a.338.338 0 01.184-.017l2.867.492-.759-1.732a.339.339 0 01.056-.359 5.465 5.465 0 001.357-3.604c0-3.08-2.554-5.585-5.688-5.585-3.128-.007-5.667 2.477-5.67 5.548v.02c0 3.085 2.553 5.59 5.687 5.59z"
                              ></path>
                              <path
                                fill="#fff"
                                stroke="#fff"
                                d="M17.655 15h2.19c.223 0 .405.169.405.375s-.182.375-.405.375h-2.19c-.223 0-.405-.169-.405-.375s.182-.375.405-.375zM15.365 18h4.52c.2 0 .365.169.365.375a.371.371 0 01-.365.375h-4.52a.371.371 0 01-.365-.375c0-.206.164-.375.365-.375z"
                              ></path>
                              <defs>
                                <clipPath id="clip0_993_8274">
                                  <path
                                    fill="#fff"
                                    d="M0 0H21.75V21.75H0z"
                                    transform="translate(0 .75)"
                                  ></path>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                        <div className="admin-ui-dashboard-header-left-main-card">
                          <div className="admin-ui-dashboard-header-left-main-card-info-sec">
                            <p>{t("total_chats")}</p>
                            <h3>{dashboard.data.total_chats}</h3>
                          </div>
                          <div className="admin-ui-dashboard-header-left-main-box-icon-sec">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#fff"
                                stroke="#fff"
                                strokeWidth="0.612"
                                d="M11.62.78c-.005.006-.018.006-.036.006C5.367.786.312 5.734.312 11.82c0 2.483.866 4.893 2.45 6.835l-1.64 3.728a.594.594 0 00.32.787c.11.049.234.06.35.043l6.014-1.035a11.05 11.05 0 003.747.638c6.217 0 11.272-4.948 11.272-11.032C22.837 5.716 17.82.786 11.621.78zm-.06 20.838c-1.186 0-2.36-.21-3.471-.619a.601.601 0 00-.326-.03l-5.062.866 1.34-3.048a.594.594 0 00-.099-.632 9.593 9.593 0 01-2.396-6.343c0-5.422 4.51-9.83 10.044-9.83 5.522-.011 10.007 4.36 10.013 9.764v.037c0 5.429-4.51 9.835-10.044 9.835z"
                              ></path>
                              <path
                                fill="#fff"
                                stroke="#fff"
                                strokeWidth="0.245"
                                d="M11.08 9.6H7.763a.61.61 0 00-.614.601c0 .331.276.602.614.602h3.317a.61.61 0 00.614-.602.61.61 0 00-.614-.6zM15.38 12.606H7.763a.61.61 0 00-.614.602c0 .33.276.6.614.6h7.617a.61.61 0 00.614-.6.61.61 0 00-.614-.602z"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} lg={12} xl={6}>
                    <div className="admin-ui-dashboard-analytics-sec">
                      <div className="new-setting-analytics-box">
                        <div className="new-setting-analytics-card">
                          <div className="new-setting-analytics-top-sec">
                            <div className="new-setting-analytics-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#00195E"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M21 23a1 1 0 11-2 0c0-2.206-1.794-4-4-4H9c-2.206 0-4 1.794-4 4a1 1 0 11-2 0c0-3.309 2.691-6 6-6h6c3.309 0 6 2.691 6 6zm1-15.5v2c0 .827-.673 1.5-1.5 1.5H20c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4h-.5C2.673 11 2 10.327 2 9.5v-2C2 6.673 2.673 6 3.5 6H4c0-2.206 1.794-4 4-4h3V1a1 1 0 112 0v1h3c2.206 0 4 1.794 4 4h.5c.827 0 1.5.673 1.5 1.5zM18 6c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2v5c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6zM9.5 7a1.5 1.5 0 10.001 3.001A1.5 1.5 0 009.5 7zm5 0a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0014.5 7z"></path>
                              </svg>
                            </div>
                            <div className="new-setting-analytics-info-sec">
                              <h6>{t("bots")}</h6>
                              <h4>
                                {dashboard.data.bots.used} /{" "}
                                {dashboard.data.bots.total}
                              </h4>
                            </div>
                          </div>
                          <div className="new-setting-analytics-progress-bar-sec bot-progress-bar">
                            <ProgressBar
                              now={
                                (dashboard.data.bots.used /
                                  dashboard.data.bots.total) *
                                100
                              }
                            />
                          </div>
                          <div className="new-setting-analytics-bottom-sec">
                            <p>{dashboard.data.bots.remaining} {t("remaining")}</p>
                          </div>
                        </div>
                        <div className="new-setting-analytics-card">
                          <div className="new-setting-analytics-top-sec">
                            <div className="new-setting-analytics-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#00195E"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                              </svg>
                            </div>
                            <div className="new-setting-analytics-info-sec">
                              <h6>{t("fine_tune")}</h6>
                              <h4>
                                {dashboard.data.fine_tunings.used} /{" "}
                                {dashboard.data.fine_tunings.total}
                              </h4>
                            </div>
                          </div>
                          <div className="new-setting-analytics-progress-bar-sec fine-tune-progress-bar">
                            <ProgressBar
                              now={
                                (dashboard.data.fine_tunings.used /
                                  dashboard.data.fine_tunings.total) *
                                100
                              }
                            />
                          </div>
                          <div className="new-setting-analytics-bottom-sec">
                            <p>
                              {dashboard.data.fine_tunings.remaining} {t("remaining")}
                            </p>
                          </div>
                        </div>
                        <div className="new-setting-analytics-card">
                          <div className="new-setting-analytics-top-sec">
                            <div className="new-setting-analytics-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#00195E"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                                <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                              </svg>
                            </div>
                            <div className="new-setting-analytics-info-sec">
                              <h6>{t("chat_messages")}</h6>
                              <h4>
                                {dashboard.data.messages.used}{" "}
                                / {dashboard.data.messages.total}
                              </h4>
                            </div>
                          </div>
                          <div className="new-setting-analytics-progress-bar-sec setting-analytics-progress-bar">
                            <ProgressBar
                              now={
                                ((dashboard.data.messages.used) /
                                  dashboard.data.current_plan.total_tokens) *
                                100
                              }
                            />
                          </div>
                          <div className="new-setting-analytics-bottom-sec">
                            <p>
                              {dashboard.data.messages.remaining}{" "}
                              {t("remaining")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              ) : null}
            </Row>
          </div>
          <div className="admin-ui-dashboard-body-sec">
            <Row>
              <Col md={6} className="resp-mrg-btm-xs">
                <div className="admin-ui-dashboard-graph-card">
                  <div className="admin-ui-dashboard-graph-header-sec">
                    <h3>{t("fine_tunings")}</h3>
                    <div className="admin-ui-dashboard-graph-filter-sec">
                      <Select
                        options={options}
                        styles={customStyles}
                        isSearchable={false}
                        defaultValue={{ value:7, label: "Last 7 Days" }}
                        onChange={(selectedOption) => {
                          handleFineTuningFilter(selectedOption)
                        }}
                      />
                    </div>
                  </div>
                  <div className="admin-ui-dashboard-graph-body-sec">
                    <div className="admin-ui-dashboard-graph-img-sec">
                      {dashboardGraph.loading ? (
                        <div className="onboarding-form">
                          <div className="onboarding-body-sec">
                            <Row className="justify-content-md-center">
                              <Col md={12}>
                                <Skeleton count={1} height={250} />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : Object.keys(dashboardGraph.data).length > 0 &&
                        Object.keys(dashboardGraph.data.fine_tuning_graph).length >
                          0 ? (
                        <ChatsGraph
                          tickets={dashboardGraph.data.fine_tuning_graph}
                          name="Custom Training"
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="admin-ui-dashboard-graph-card">
                  <div className="admin-ui-dashboard-graph-header-sec">
                    <h3>{t("chats")}</h3>
                    <div className="admin-ui-dashboard-graph-filter-sec">
                      <Select
                        options={options}
                        styles={customStyles}
                        isSearchable={false}
                        defaultValue={{ value:7, label: "Last 7 Days" }}
                        onChange={(selectedOption) => {
                          handleChatFilter(selectedOption)
                        }}
                      />
                    </div>
                  </div>
                  <div className="admin-ui-dashboard-graph-body-sec">
                    <div className="admin-ui-dashboard-graph-img-sec">
                      {dashboardGraph.loading ? (
                        <div className="onboarding-form">
                          <div className="onboarding-body-sec">
                            <Row className="justify-content-md-center">
                              <Col md={12}>
                                <Skeleton count={1} height={250} />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : Object.keys(dashboardGraph.data).length > 0 &&
                        Object.keys(dashboardGraph.data.chats_graph).length > 0 ? (
                        <ChatsGraph
                          tickets={dashboardGraph.data.chats_graph}
                          name="Chats"
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {dashboardRecentChats.loading ? (
                  <TableLoader lines={6} />
                ) : Object.keys(dashboardRecentChats.data).length > 0 &&
                  dashboardRecentChats.data.recent_chats ? (
                  <DashBoardRecentChatIndex
                    chats={dashboardRecentChats.data.recent_chats}
                  />
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation(DashBoardIndex);

import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  widgetSettings: getCommonInitialState(),
  widgetSettingsSave: getCommonInitialState(),
  widgetSettingsReset: getCommonInitialState(),
};

const SettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {

    //Get Widget Settings
    widgetSettingsStart: (state, { payload }) => { state.widgetSettings = getCommonStart() },
    widgetSettingsSuccess: (state, { payload }) => { state.widgetSettings = getCommonSuccess(payload) },
    widgetSettingsFailure: (state, { payload }) => { state.widgetSettings = getCommonFailure(payload) },

    //Save Widget Settings
    widgetSettingsSaveStart: (state, { payload }) => { state.widgetSettingsSave = getCommonStart() },
    widgetSettingsSaveSuccess: (state, { payload }) => { state.widgetSettingsSave = getCommonSuccess(payload) },
    widgetSettingsSaveFailure: (state, { payload }) => { state.widgetSettingsSave = getCommonFailure(payload) },

    //Reset Widget Settings
    widgetSettingsResetStart: (state, { payload }) => { state.widgetSettingsReset = getCommonStart() },
    widgetSettingsResetSuccess: (state, { payload }) => { state.widgetSettingsReset = getCommonSuccess(payload) },
    widgetSettingsResetFailure: (state, { payload }) => { state.widgetSettings = getCommonFailure(payload) },

  }
});


export const {
  widgetSettingsStart,
  widgetSettingsSuccess,
  widgetSettingsFailure,
  widgetSettingsSaveStart,
  widgetSettingsSaveSuccess,
  widgetSettingsSaveFailure,
  widgetSettingsResetStart,
  widgetSettingsResetSuccess,
  widgetSettingsResetFailure

} = SettingsSlice.actions;

export default SettingsSlice.reducer;
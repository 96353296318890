import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  onPromiseCompanySave: getCommonInitialState(),
  updateWidgetType: getCommonInitialState(),
  onboardingbot: getCommonInitialState(),
};

const OnboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {

    //On Promise company save
    onPromiseCompanySaveStart: (state, action) => { state.onPromiseCompanySave = getCommonStart() },
    onPromiseCompanySaveSuccess: (state, action) => { state.onPromiseCompanySave = getCommonSuccess(action.payload) },
    onPromiseCompanySaveFailure: (state, action) => { state.onPromiseCompanySave = getCommonFailure(action.payload) },

    //Update Widget type
    updateWidgetTypeStart: (state, action) => { state.updateWidgetType = getCommonStart() },
    updateWidgetTypeSuccess: (state, action) => { state.updateWidgetType = getCommonSuccess(action.payload) },
    updateWidgetTypeFailure: (state, action) => { state.updateWidgetType = getCommonFailure(action.payload) },

    //Onboarding Created Bot Store
    onboardingCreatedbotStart: (state, action) => { state.onboardingbot = getCommonStart() },
    onboardingCreatedbotSuccess: (state, action) => { state.onboardingbot = getCommonSuccess(action.payload) },
    onboardingCreatedbotFailure: (state, action) => { state.onboardingbot = getCommonFailure(action.payload) },

  }
});


export const {
  onPromiseCompanySaveStart,
  onPromiseCompanySaveSuccess,
  onPromiseCompanySaveFailure,
  updateWidgetTypeStart,
  updateWidgetTypeSuccess,
  updateWidgetTypeFailure,
  onboardingCreatedbotStart,
  onboardingCreatedbotSuccess,
  onboardingCreatedbotFailure,

} = OnboardingSlice.actions;

export default OnboardingSlice.reducer;
import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ page, handlePageClick, pageCount }) => {
  return (
    <ReactPaginate
      forcePage={page - 1}
      breakLabel="..."
      nextLabel=">"
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel="<"
      renderOnZeroPageCount={null}
      className="page-number"
      previousClassName="paginate-previous-btn"
      nextClassName="paginate-next-btn"
      pageLinkClassName="paginate-link"
      activeClassName="active-page"
    />
  );
}

export default Pagination;
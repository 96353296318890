import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { emailVerifyStart, resendOtpStart, updateEmailCodeStart, updateEmailStart, verifyForgotPasswordStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation, withTranslation } from "react-multi-lang";

const OnBoardingVerificationModal = (props) => {

  const t = useTranslation("verification_modal");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailVerify = useSelector(state => state.admin.emailVerify);
  const verifyForgotPassword = useSelector((state) => state.admin.verifyForgotPassword);
  const updateEmail = useSelector((state) => state.admin.updateEmail);
  const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);
  const resendOtp = useSelector(state => state.admin.resendOtp);
  const [otp, setOtp] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const handleChange = (otp) => setOtp(otp);

  const handleSubmit = e => {
    e.preventDefault();
    if (otp)
      if (otp.length === 6)
        if(props.forgotPassword && props.forgotPassword === 1)
        {
          dispatch(verifyForgotPasswordStart({
            email: props.showVerify.email,
            verification_code: otp,
          })); 
        }
        else if(props.forgotPassword && props.forgotPassword === 2)
        {
          dispatch(updateEmailStart({
            email: props.showVerify,
            verification_code: otp,
          })); 
        }
        else{
          dispatch(emailVerifyStart({
            email: props.showVerify.email,
            remember: props.showVerify.remember,
            verification_code: otp,
          })); 
        }
      else getErrorNotificationMessage("The verification code should be 6 digits.")
    else getErrorNotificationMessage("The verification code field is required.");
  }

  const handleresendOtp = () => {
    if (props.forgotPassword && props.forgotPassword === 2) {
      dispatch(
        updateEmailCodeStart({
          email: props.showVerify,
        })
      );
    } else {
      dispatch(
        resendOtpStart({
          email: props.showVerify.email,
          code_type: "email",
        })
      );
    } 
  }

  useEffect(() => {
    if (
      !skipRender &&
      !verifyForgotPassword.loading &&
      Object.keys(verifyForgotPassword.data).length > 0
    ) {
      navigate(`/reset-password/${verifyForgotPassword.data.token}`);
    }
    setSkipRender(false);
  }, [verifyForgotPassword]);

  useEffect(() => {
    if (
      !skipRender && 
      !updateEmail.loading &&
      Object.keys(updateEmail.data).length > 0
    ) {
      props.closeVerify();
    }
    setSkipRender(false);
  }, [updateEmail]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.showVerify}
        // onHide={props.closeVerify}
      >
        <Modal.Body>
          <h4>{props.forgotPassword ? t("otp_verification") : t("email_verification")}</h4>
          <Button className="modal-close" onClick={() => props.closeVerify()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M16.061 10.061L14.122 12l1.939 1.939a1.5 1.5 0 11-2.122 2.121L12 14.121l-1.939 1.939c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121L9.878 12l-1.939-1.939A1.5 1.5 0 1110.06 7.94l1.939 1.939 1.939-1.939a1.5 1.5 0 112.121 2.121zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-3 0c0-4.963-4.037-9-9-9s-9 4.037-9 9 4.038 9 9 9 9-4.037 9-9z"></path>
            </svg>
          </Button>
            <Form onSubmit={handleSubmit}>
            <div className="verification-item">
              <h4>{t("heading")}</h4>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                // isInputNum={true}
                // shouldAutoFocus={true}
                separator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="verification-btn-sec">
              <span>{t("message")}</span>
              <button
                type="button"
                onClick={() => handleresendOtp()}
                className="resend-link"
                disabled={resendOtp.buttonDisable || updateEmailCode.buttonDisable || verifyForgotPassword.buttonDisable || updateEmail.buttonDisable || emailVerify.buttonDisable || updateEmailCode.buttonDisable }
              >
                {resendOtp.buttonDisable || updateEmailCode.buttonDisable ? "Loading" : "Send Again"}
              </button>
            </div>
            <div className="verify-btn-sec">
            {props.forgotPassword ? props.forgotPassword === 1 ? <Button
                type="submit"
                className="onboarding-btn"
                disabled={verifyForgotPassword.buttonDisable || updateEmailCode.buttonDisable || resendOtp.buttonDisable}
              >
                {verifyForgotPassword.buttonDisable ? t("loading") : t("verify")}
              </Button> : <Button
                type="submit"
                className="onboarding-btn"
                disabled={updateEmail.buttonDisable || updateEmailCode.buttonDisable || resendOtp.buttonDisable}
              >
                {updateEmail.buttonDisable ? t("loading") : t("update")}
              </Button> : <Button
                type="submit"
                className="onboarding-btn"
                disabled={emailVerify.buttonDisable || updateEmailCode.buttonDisable || resendOtp.buttonDisable}
              >
                {emailVerify.buttonDisable ? t("loading") : t("validate")}
              </Button>}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(OnBoardingVerificationModal);
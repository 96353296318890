import { all, fork } from "redux-saga/effects";
import AdminSaga from "./AdminSaga";
import CategorySaga from "./CategorySaga";
import SubscriptionSaga from "./SubscriptionSaga";
import ChatSaga from "./ChatSaga";
import FineTuneSaga from "./FineTuneSaga";
import SettingsSaga from "./SettingsSaga";
import SupportTicketSaga from "./SupportTicketSaga";
import OnboardingSaga from "./OnboardingSaga";
import BotSaga from "./BotSaga";
import DashboardSaga from "./DashboardSaga";
import FaqSaga from "./FaqSaga";
import PageSaga from "./PageSaga";

export default function* rootSaga() {
    yield all([
        fork(AdminSaga),
        fork(CategorySaga),
        fork(SubscriptionSaga),
        fork(ChatSaga),
        fork(FineTuneSaga),
        fork(SettingsSaga),
        fork(SupportTicketSaga),
        fork(OnboardingSaga),
        fork(BotSaga),
        fork(DashboardSaga),
        fork(FaqSaga),
        fork(PageSaga),
    ]);
}

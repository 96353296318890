import React, { useState, useEffect, useRef } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  botsStoreStart,
  singleBotViewStart,
  sourceConfigurationSaveStart,
} from "../../../store/slices/BotSlice";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation, withTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../Helper/NoDataFound";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../Helper/NotificationMessage";
import useBot from "../../../hooks/useBot";
import FreshChatDemoSteps from "./FreshChatDemoSteps";
import ZenDeskDemoSteps from "./ZenDeskDemoSteps";
import LiveChatDemoSteps from "./LiveChatDemoSteps";
import { sendInstallationDocumentsStart } from "../../../store/slices/AdminSlice";

const SourceScriptIndex = (props) => {
  const { widgets } = useBot();
  const [widgetType, setWidgetType] = useState();

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #00337D57!important",
      borderRadius: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "auto",
      fontSize: "0.9em",
      cursor:"pointer",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#50506B80",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };
  const t = useTranslation("settings.source");
  const dispatch = useDispatch();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const botsStore = useSelector((state) => state.bots.botsStore);
  const sendInstallationDocuments = useSelector((state) => (state.admin.sendInstallationDocuments))
  const singleBotView = useSelector((state) => state.bots.singleBotView);
  const sourceConfigurationSave = useSelector(
    (state) => state.bots.sourceConfigurationSave
  );

  const validationSchema = Yup.object().shape({
    widget_type: Yup.string().required(t("widget_type.required")),
    zendesk_key_id: Yup.string().when("widget_type", {
      is: 3,
      then: Yup.string().required(t("zendesk_key_id.required")),
      otherwise: Yup.string(),
    }),
    zendesk_secret_key: Yup.string().when("widget_type", {
      is: 3,
      then: Yup.string().required(t("zendesk_secret_key.required")),
      otherwise: Yup.string(),
    }),
    zendesk_app_id: Yup.string().when("widget_type", {
      is: 3,
      then: Yup.string().required(t("zendesk_key_id.required")),
      otherwise: Yup.string(),
    }),
    live_chat_email: Yup.string().when("widget_type", {
      is: 4,
      then: Yup.string().required(t("zendesk_key_id_is_required")),
      otherwise: Yup.string(),
    }),
    live_chat_organization_id: Yup.string().when("widget_type", {
      is: 4,
      then: Yup.string().required(t("zendesk_key_id_is_required")),
      otherwise: Yup.string(),
    }),
  });

  useEffect(() => {
    if (Object.keys(selectedBot).length > 0) {
      dispatch(singleBotViewStart({ bot_id: selectedBot.bot_id }));
      setWidgetType(selectedBot.widget_type);
    }
  }, [selectedBot]);

  const handleBotsStore = (values) => {
    let newValues = {};
    switch (values.widget_type) {
      case 3:
        newValues = {
          ...newValues,
          bot_id: values.bot_id,
          widget_type: values.widget_type,
          zendesk_key_id: values.zendesk_key_id,
          zendesk_app_id: values.zendesk_app_id,
          zendesk_secret_key: values.zendesk_secret_key,
        };
        break;
      case 4:
        newValues = {
          ...newValues,
          bot_id: values.bot_id,
          widget_type: values.widget_type,
          live_chat_email: values.live_chat_email,
          live_chat_organization_id: values.live_chat_organization_id,
        };
        break;
      default:
        newValues = {
          ...newValues,
          bot_id: values.bot_id,
          widget_type: values.widget_type,
        };
    }
    dispatch(sourceConfigurationSaveStart(newValues));
  };

  const onCopy = (event) => {
    getSuccessNotificationMessage("Api Key was copied to clipboard!");
  };

  const handleSendEmail = () => {
    dispatch(sendInstallationDocumentsStart({
      widget_type: widgetType
    }))
  }

  return (
    <>
      {singleBotView.loading ? (
        <div className="new-source-script-sec">
          {[...Array(5)].map((i) => (
            <div className="mb-3">
              <Skeleton height={10} width="100%" />
              <Skeleton height={50} width="100%" />
            </div>
          ))}
        </div>
      ) : Object.keys(singleBotView.data).length > 0 ? (
        <div className="new-source-script-sec">
          <div className="new-source-script-header-sec">
            <div className="new-source-script-header-left-sec">
              <h3>Website Embeddings</h3>
              <p>
                To add the chatbot any where on your website, add this frame to
                your html code
              </p>
            </div>
            <div className="new-source-script-header-right-sec">
              <div className="my-subscription-header-action-btn-sec">
                <Button
                  type="button"
                  className="contact-us-btn"
                  onClick={handleSendEmail}
                  disabled={sendInstallationDocuments.buttonDisable}
                >
                  {sendInstallationDocuments.buttonDisable ? "Loading" : "Send Email"}
                </Button>
                <Link
                  to="https://www.haive.tech/#contact"
                  target="_blank"
                  className="billing-btn"
                >
                  Need Help
                </Link>
              </div>
            </div>
          </div>
          <div className="new-source-script-body-sec">
            <Formik
              initialValues={{
                bot_id: singleBotView.data.bot.bot_id,
                widget_type: singleBotView.data.bot.widget_type,
                widget_type_formatted:
                  singleBotView.data.bot.widget_type_formatted,
                zendesk_key_id:
                  singleBotView.data.bot.source?.zendesk_key_id ?? "",
                zendesk_secret_key:
                  singleBotView.data.bot.source?.zendesk_secret_key ?? "",
                zendesk_app_id:
                  singleBotView.data.bot.source?.zendesk_app_id ?? "",
                live_chat_email:
                  singleBotView.data.bot.source?.live_chat_email ?? "",
                live_chat_organization_id:
                  singleBotView.data.bot.source?.live_chat_organization_id ?? "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleBotsStore}
            >
              {({ setFieldValue, values }) => (
                <FORM className="onboarding-form">
                  <div className="new-source-script-form-sec">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Choose Solution <span>*</span>
                      </Form.Label>
                      <Select
                        options={widgets}
                        styles={customStyles}
                        value={{
                          label: values.widget_type_formatted,
                          value: values.widget_type,
                        }}
                        placeholder="Select an option"
                        onChange={(selectOption) => {
                          setFieldValue("widget_type", selectOption.value);
                          setFieldValue(
                            "widget_type_formatted",
                            selectOption.label
                          );
                          setWidgetType(selectOption.value);
                        }}
                      />
                    </Form.Group>
                    {values.widget_type == 1 &&
                      singleBotView.data.bot.api_key && (
                        <div className="new-source-script-form-box">
                          <Form.Label>
                            Api Key <span>*</span>
                          </Form.Label>
                          <InputGroup
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Field
                              className="form-control"
                              type="text"
                              placeholder="Api Key"
                              name="api_key"
                              value={singleBotView.data.bot.api_key || ""}
                              disabled={true}
                            />
                            <InputGroup.Text id="basic-addon2">
                              <CopyToClipboard
                                text={singleBotView.data.bot.api_key}
                                onCopy={onCopy}
                              >
                                <Button type="button" className="search-btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#3E4351"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                      )}
                    {values.widget_type === 3 && (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Zendesk Key ID <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder="Key ID"
                            name="zendesk_key_id"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="zendesk_key_id"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Zendesk Secret Key <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder="Secret Key"
                            name="zendesk_secret_key"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="zendesk_secret_key"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Zendesk App ID <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder="App ID"
                            name="zendesk_app_id"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="zendesk_app_id"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </>
                    )}
                    {values.widget_type === 4 && (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Live Chat Email <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="email"
                            placeholder="Email"
                            name="live_chat_email"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="live_chat_email"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Live Chat Organisation ID <span>*</span></Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder="ID"
                            name="live_chat_organization_id"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="live_chat_organization_id"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </>
                    )}
                    <div className="new-settings-btn-sec">
                      <Button
                        className="settings-btn"
                        type="submit"
                        disabled={sourceConfigurationSave.buttonDisable ||
                          (values.widget_type == 1 && singleBotView.data.bot.api_key && values.widget_type === singleBotView.data.bot.widget_type) || 
                          (values.widget_type == 3 && values.zendesk_key_id === singleBotView.data.bot.source?.zendesk_key_id && values.zendesk_key_id === singleBotView.data.bot.source?.zendesk_key_id && values.zendesk_secret_key === singleBotView.data.bot.source?.zendesk_secret_key && values.zendesk_app_id === singleBotView.data.bot.source?.zendesk_app_id) || 
                          (values.widget_type == 4 && values.live_chat_organization_id === singleBotView.data.bot.source?.live_chat_organization_id  && values.live_chat_email === singleBotView.data.bot.source?.live_chat_email)}>
                        {sourceConfigurationSave.buttonDisable ? "Loading" : values.widget_type == 1 &&
                        !singleBotView.data.bot.api_key ? "Generate API Key" : "Update Changes"}
                      </Button>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
          {widgetType == 1 ? (
            <FreshChatDemoSteps />
          ) : widgetType == 3 ? (
            <ZenDeskDemoSteps />
          ) : (
            <LiveChatDemoSteps />
          )}
        </div>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default withTranslation(SourceScriptIndex);

import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Button, Form } from "react-bootstrap";
// import "../../Layout/NewHeader/NewUI.css";
import { Link, useLocation } from "react-router-dom";
import Toggle from "react-styled-toggle";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { profileStart, updateEmailCodeStart } from "../../store/slices/AdminSlice";
import { updateProfileStart } from "../../store/slices/AdminSlice";
import * as Yup from "yup";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import ProfileLoader from "../Helper/ProfileLoader";
import DeleteProfileModal from "./DeleteProfileModal";
import ChangePasswordModal from "./ChangePasswordModal";
import OnBoardingVerificationModal from "../OnBoarding/OnboardingVerificationModal";
import TFAModal from "./TFAModal";

const ProfileIndex = (props) => {

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const updateProfile = useSelector((state) => state.admin.updateProfile);
  const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);
  const updateEmail = useSelector((state) => state.admin.updateEmail);
  const [newDeleteProfile, setNewDeleteProfile] = useState(false);
  const [newTFAModal, setNewTFAModal] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [profileDetail, setProfileDetail] = useState({
    name: "",
    email: ""
  });

  const closeNewDeleteProfileModal = () => {
    setNewDeleteProfile(false);
  };

  const closeNewTFAModal = () => {
    setNewTFAModal(false);
  };

  const [newChangePassword, setNewChangePassword] = useState(false);

  const closeNewChangePasswordModal = () => {
    setNewChangePassword(false);
  };

  const handleUpdateProfileImage = (image) => {
    if (image && !profile.loading && Object.keys(profile.data).length > 0)
      dispatch(
        updateProfileStart({
          picture: image,
          name: profile.data.name,
          email: profile.data.email,
        })
      );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name required")
      .test(
        "len",
        "Should not exceed 255 characters",
        (val) => val && val.toString().length < 255
      ),
    email: Yup.string().email("Invalid Email").required("Email Required"),
  });

  const handleEditName = (values) => {
    dispatch(updateProfileStart(values));
  };

  const handleEditEmail = (values) => {
    if(values.email){
      setNewEmail(values.email);
      dispatch(updateEmailCodeStart(values));
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !updateProfile.loading &&
      Object.keys(updateProfile.data).length > 0
    ) {
      setEditName(false);
    }
    setSkipRender(false);
  }, [updateProfile]);

  useEffect(() => {
    if (
      !skipRender &&
      !updateEmailCode.loading &&
      Object.keys(updateEmailCode.data).length > 0 
    ) {
        setShowVerify(newEmail);
    }
    setSkipRender(false);
  }, [updateEmailCode]);

  useEffect(() => {
    if (
      !skipRender &&
      !updateEmail.loading &&
      Object.keys(updateEmail.data).length > 0
    ) {
      setEditEmail(false);
    }
    setSkipRender(false);
  }, [updateEmail]);

  const options = [
    { value: "USA", label: "USA", icon: "usa.svg" },
    { value: "India", label: "India", icon: "india.svg" },
  ];

  const { Option } = components;
  const IconOption = props => (
    <Option {...props} className="select-option">
      <img
        src={require('./' + props.data.icon)}
        style={{ width: 20, height: 20 }}
        alt={props.data.label}
      />
      {props.data.label}
    </Option>
  );

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#32089F12!important",
      border: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      fontSize: "0.9em",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const [startDate, setStartDate] = useState(new Date());
  const [tfA, setTFA] = useState(false);

  useEffect(()=> {
    if (!profile.loading &&
      Object.keys(profile.data).length > 0
    ) {
      setProfileDetail({
        name: profile.data.name,
        email: profile.data.email,
      })
      setTFA(profile.data.tfa_status ? true : false);
    }
  }, [profile])


  return profile.loading ? (
    <ProfileLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      <div className="admin-ui-body-sec profile-body-sec">
        <Formik
          initialValues={{
            name: profile.data.name,
            email: profile.data.email,
          }}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <FORM className="onboarding-form">
              <div className="profile-new-header-sec">
                <div className="profile-new-header-left-sec">
                  <div className="admin-ui-profile-img-change-sec">
                    <>
                      {!profile.loading &&
                      Object.keys(profile.data).length > 0 &&
                      profile.data.picture ? (
                        <CustomLazyLoad
                          className="admin-ui-profile-img-change"
                          src={profile.data.picture}
                        />
                      ) : (
                        <Image
                          className="admin-ui-profile-img-change"
                          src={
                            window.location.origin +
                            "/images/profile/profile-img.png"
                          }
                        />
                      )}
                      <label className="admin-ui-profile-img-edit-icon">
                        <input
                          type="file"
                          accept="image/png, image/jpeg,, image/jpg"
                          size="60"
                          onChange={(e) =>
                            handleUpdateProfileImage(e.target.files[0])
                          }
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                          width="30"
                          height="30"
                          fill="#00195E"
                        >
                          <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                        </svg>
                      </label>
                    </>
                  </div>
                </div>
                <div className="profile-new-header-right-sec">
                  <div className="profile-form-flex">
                    {!editName ? (
                      <h3>
                        <span>{profile.data.name}</span>
                        <Button
                          className="profile-form-svg-btn edit-icon"
                          onClick={() => setEditName(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                          >
                            <path d="M18.656.93L6.464 13.122A4.966 4.966 0 005 16.657V18a1 1 0 001 1h1.343a4.966 4.966 0 003.535-1.464L23.07 5.344a3.125 3.125 0 000-4.414 3.194 3.194 0 00-4.414 0zm3 3L9.464 16.122A3.02 3.02 0 017.343 17H7v-.343a3.02 3.02 0 01.878-2.121L20.07 2.344a1.148 1.148 0 011.586 0 1.123 1.123 0 010 1.586z"></path>
                            <path d="M23 8.979a1 1 0 00-1 1V15h-4a3 3 0 00-3 3v4H5a3 3 0 01-3-3V5a3 3 0 013-3h9.042a1 1 0 000-2H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h11.343a4.968 4.968 0 003.536-1.464l2.656-2.658A4.968 4.968 0 0024 16.343V9.979a1 1 0 00-1-1zm-4.535 12.143a2.975 2.975 0 01-1.465.8V18a1 1 0 011-1h3.925a3.016 3.016 0 01-.8 1.464z"></path>
                          </svg>
                        </Button>
                      </h3>
                    ) : (
                      <>
                        <Row>
                          <Col md={12}>
                            <Form.Group className="mb-0">
                              <Field
                                className="form-control"
                                type="text"
                                placeholder="Enter name"
                                name="name"
                                value={profileDetail.name}
                                onChange={(e) => {
                                  setProfileDetail({
                                    ...profileDetail,
                                    name: e.target.value,
                                  })
                                  setFieldValue("name", e.target.value)
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="name"
                                className="errorMsg"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          className="profile-form-svg-btn"
                          disabled={
                            updateProfile.buttonDisable ||
                            profileDetail.name == "" ||
                            profileDetail.name === profile.data.name
                          }
                          onClick={() => handleEditName(values)}
                          type="submit"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#58D073"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm-.091 15.419a2.001 2.001 0 01-2.823-.005l-2.782-2.696 1.393-1.437 2.793 2.707 5.809-5.701 1.404 1.425-5.793 5.707z"></path>
                          </svg>
                        </Button>
                        <Button
                          className="profile-form-svg-btn"
                          onClick={() => {
                            setEditName(false);
                            setProfileDetail({
                              name: profile.data.name,
                              email: profile.data.email,
                            })
                          }}
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#dc3545"
                            x="0"
                            y="0"
                            enableBackground="new 0 0 512 512"
                            version="1.1"
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                          >
                            <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256C511.847 114.678 397.322.153 256 0zm85.333 311.189c8.669 7.979 9.229 21.475 1.25 30.144-7.979 8.669-21.475 9.229-30.144 1.25-.434-.399-.85-.816-1.25-1.25L256 286.165l-55.168 55.168c-8.475 8.185-21.98 7.95-30.165-.525-7.984-8.267-7.984-21.373 0-29.64L225.835 256l-55.168-55.168c-8.185-8.475-7.95-21.98.525-30.165 8.267-7.984 21.373-7.984 29.64 0L256 225.835l55.189-55.168c7.979-8.669 21.475-9.229 30.144-1.25 8.669 7.979 9.229 21.475 1.25 30.144-.399.434-.816.85-1.25 1.25L286.165 256l55.168 55.189z"></path>
                          </svg>
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="profile-form-flex">
                    {!editEmail ? (
                      <h4>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 0a12 12 0 107.092 21.681l-1.184-1.612A10 10 0 1122 12v2a2 2 0 01-4 0v-2a6.021 6.021 0 10-1.48 3.933A3.986 3.986 0 0024 14v-2A12.013 12.013 0 0012 0zm0 16a4 4 0 114-4 4 4 0 01-4 4z"></path>
                        </svg>
                        <span>{profile.data.email}&nbsp;</span>
                        <Button
                          className="profile-form-svg-btn edit-icon"
                          onClick={() => setEditEmail(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                          >
                            <path d="M18.656.93L6.464 13.122A4.966 4.966 0 005 16.657V18a1 1 0 001 1h1.343a4.966 4.966 0 003.535-1.464L23.07 5.344a3.125 3.125 0 000-4.414 3.194 3.194 0 00-4.414 0zm3 3L9.464 16.122A3.02 3.02 0 017.343 17H7v-.343a3.02 3.02 0 01.878-2.121L20.07 2.344a1.148 1.148 0 011.586 0 1.123 1.123 0 010 1.586z"></path>
                            <path d="M23 8.979a1 1 0 00-1 1V15h-4a3 3 0 00-3 3v4H5a3 3 0 01-3-3V5a3 3 0 013-3h9.042a1 1 0 000-2H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h11.343a4.968 4.968 0 003.536-1.464l2.656-2.658A4.968 4.968 0 0024 16.343V9.979a1 1 0 00-1-1zm-4.535 12.143a2.975 2.975 0 01-1.465.8V18a1 1 0 011-1h3.925a3.016 3.016 0 01-.8 1.464z"></path>
                          </svg>
                        </Button>
                      </h4>
                    ) : (
                      <>
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Field
                                className="form-control"
                                type="email"
                                placeholder="Enter email"
                                name="email"
                                value={profileDetail.email}
                                onChange={(e) => {
                                  setProfileDetail({
                                    ...profileDetail,
                                    email: e.target.value,
                                  })
                                  setFieldValue("email", e.target.value)
                                }}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="email"
                                className="errorMsg"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          className="profile-form-svg-btn"
                          disabled={
                            updateEmailCode.buttonDisable ||
                            profileDetail.email == "" ||
                            profileDetail.email === profile.data.email
                          }
                          type="button"
                          onClick={() => {
                            handleEditEmail(values);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#58D073"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm-.091 15.419a2.001 2.001 0 01-2.823-.005l-2.782-2.696 1.393-1.437 2.793 2.707 5.809-5.701 1.404 1.425-5.793 5.707z"></path>
                          </svg>
                        </Button>
                        <Button
                          className="profile-form-svg-btn"
                          onClick={() => {
                            setEditEmail(false);
                            setProfileDetail({
                              name: profile.data.name,
                              email: profile.data.email,
                            })
                          }}
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="#dc3545"
                            x="0"
                            y="0"
                            enableBackground="new 0 0 512 512"
                            version="1.1"
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                          >
                            <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256C511.847 114.678 397.322.153 256 0zm85.333 311.189c8.669 7.979 9.229 21.475 1.25 30.144-7.979 8.669-21.475 9.229-30.144 1.25-.434-.399-.85-.816-1.25-1.25L256 286.165l-55.168 55.168c-8.475 8.185-21.98 7.95-30.165-.525-7.984-8.267-7.984-21.373 0-29.64L225.835 256l-55.168-55.168c-8.185-8.475-7.95-21.98.525-30.165 8.267-7.984 21.373-7.984 29.64 0L256 225.835l55.189-55.168c7.979-8.669 21.475-9.229 30.144-1.25 8.669 7.979 9.229 21.475 1.25 30.144-.399.434-.816.85-1.25 1.25L286.165 256l55.168 55.189z"></path>
                          </svg>
                        </Button>
                      </>
                    )}
                  </div>
                  {/* <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  fill="#616981"
                  height="18"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0a11.972 11.972 0 00-8 3.073V1a1 1 0 00-2 0v3a3 3 0 003 3h3a1 1 0 000-2H5a.854.854 0 01-.1-.021A9.987 9.987 0 112 12a1 1 0 00-2 0A12 12 0 1012 0z"></path>
                  <path d="M12 6a1 1 0 00-1 1v5a1 1 0 00.293.707l3 3a1 1 0 001.414-1.414L13 11.586V7a1 1 0 00-1-1z"></path>
                </svg>
                <span>Last logged in 4hr ago</span>
              </h5> */}
                </div>
              </div>
              <div className="profile-new-notification-security-sec">
                <div className="profile-new-security-card">
                  <h4>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M19.949 2.644L12 .009 4.051 2.644A2.982 2.982 0 002 5.476V12c0 7.524 9.2 11.679 9.594 11.852l.354.157.368-.122C12.711 23.755 22 20.577 22 12V5.476a2.983 2.983 0 00-2.051-2.832zM20 12c0 6.263-6.349 9.216-7.953 9.861C10.44 21.058 4 17.494 4 12V5.476a.994.994 0 01.684-.944L12 2.106l7.316 2.426a.992.992 0 01.684.944z"
                        data-name="01 align center"
                      ></path>
                    </svg>
                    Password
                  </h4>
                  <h5>Change your password</h5>
                  <p>Change password for improved security</p>
                  <div className="profile-new-security-btn-sec">
                    <Button
                      className="contact-us-btn"
                      onClick={() => setNewChangePassword(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#32089f"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 512.016 512.016"
                        version="1.1"
                        viewBox="0 0 512.016 512.016"
                        xmlSpace="preserve"
                      >
                        <path d="M256.008 42.675a214.017 214.017 0 01151.936 64h-66.603c-11.782 0-21.333 9.551-21.333 21.333s9.551 21.333 21.333 21.333h88.384c21.874-.012 39.604-17.742 39.616-39.616V21.341c0-11.782-9.551-21.333-21.333-21.333s-21.333 9.551-21.333 21.333v44.331C321.782-28.795 160.169-20.343 65.702 84.55A255.597 255.597 0 001.075 232.541c-1.096 11.814 7.593 22.279 19.407 23.375.64.059 1.283.09 1.927.092 10.813.138 19.954-7.977 21.099-18.731C53.35 127.213 145.505 42.82 256.008 42.675zM489.629 256.008c-10.813-.138-19.954 7.977-21.099 18.731-10.18 117.214-113.454 203.982-230.669 193.801a213.037 213.037 0 01-133.789-63.199h66.603c11.782 0 21.333-9.551 21.333-21.333 0-11.782-9.551-21.333-21.333-21.333H82.291c-21.868-.012-39.604 17.706-39.616 39.573v88.427c0 11.782 9.551 21.333 21.333 21.333s21.333-9.551 21.333-21.333v-44.331c104.893 94.467 266.505 86.015 360.972-18.878a255.592 255.592 0 0064.628-147.992c1.096-11.814-7.593-22.279-19.407-23.375a21.63 21.63 0 00-1.905-.091z"></path>
                      </svg>
                      Change Password
                    </Button>
                  </div>
                </div>
                <div className="profile-new-payment-delete-account-box">
                  <h4>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                      <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                    </svg>
                    Delete Account
                  </h4>
                  <h5>Delete your account permanently</h5>
                  <p>
                    Deleting your account will permanently erase all your data,
                    settings, and content.
                  </p>
                  <div className="profile-new-security-btn-sec">
                    <Button
                      className="delete-account-btn"
                      onClick={() => setNewDeleteProfile(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#E2434D"
                        viewBox="0 0 24 24"
                      >
                        <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                        <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                      </svg>
                      Delete Account
                    </Button>
                  </div>
                </div>
              </div>
              <div className="profile-new-notification-security-box">
                <div className="profile-new-notification-card">
                  <h4>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22.555 13.662l-1.9-6.836A9.321 9.321 0 002.576 7.3l-1.471 6.615A5 5 0 005.986 20H7.1a5 5 0 009.8 0h.838a5 5 0 004.818-6.338zM12 22a3 3 0 01-2.816-2h5.632A3 3 0 0112 22zm8.126-5.185A2.977 2.977 0 0117.737 18H5.986a3 3 0 01-2.928-3.651l1.47-6.616a7.321 7.321 0 0114.2-.372l1.9 6.836a2.977 2.977 0 01-.502 2.618z"></path>
                    </svg> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M19.949 2.644L12 .009 4.051 2.644A2.982 2.982 0 002 5.476V12c0 7.524 9.2 11.679 9.594 11.852l.354.157.368-.122C12.711 23.755 22 20.577 22 12V5.476a2.983 2.983 0 00-2.051-2.832zM20 12c0 6.263-6.349 9.216-7.953 9.861C10.44 21.058 4 17.494 4 12V5.476a.994.994 0 01.684-.944L12 2.106l7.316 2.426a.992.992 0 01.684.944z"
                        data-name="01 align center"
                      ></path>
                    </svg>
                    Security
                  </h4>
                  <div className="profile-new-notification-security-switch">
                    <Toggle
                      width={40}
                      height={20}
                      sliderWidth={12}
                      sliderHeight={12}
                      translate={19}
                      checked={tfA}
                      backgroundColorChecked="#32089F"
                      backgroundColorUnchecked="#E5E4F6"
                      // labelRight="Receive Notifications"
                      labelRight="Two-step Authentication"
                      onChange={() => setNewTFAModal(true)}
                    />
                  </div>
                  {/* <p>We may still send you important notifications</p> */}
                  <p>Enable Two Step Authentication for security</p>
                  {/* <div className="profile-new-notification-security-switch">
                    <Toggle
                      width={40}
                      height={20}
                      sliderWidth={12}
                      sliderHeight={12}
                      translate={19}
                      backgroundColorChecked="#32089F"
                      backgroundColorUnchecked="#E5E4F6"
                      labelRight="Enable Notification Sound"
                    />
                  </div>
                  <p>To receive notification sounds enable this option</p> */}
                </div>
                {/* <div className="profile-new-payment-settings-box">
                  <h4>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 7v1a1 1 0 11-2 0V7c0-1.654-1.346-3-3-3H5C3.346 4 2 5.346 2 7v10c0 1.654 1.346 3 3 3h3a1 1 0 110 2H5c-2.757 0-5-2.243-5-5V7c0-2.757 2.243-5 5-5h14c2.757 0 5 2.243 5 5zm-9 2h4a1 1 0 100-2h-4a1 1 0 100 2zm8.121 2.879c.566.566.879 1.32.879 2.121s-.313 1.555-.879 2.122l-6.707 6.707a3.975 3.975 0 01-2.828 1.172H12a1 1 0 01-1-1v-1.586c0-1.068.416-2.073 1.172-2.828l6.707-6.707a3.002 3.002 0 014.242 0zM22 14a1 1 0 00-1.707-.707L13.586 20A2.017 2.017 0 0013 21.414V22h.586c.534 0 1.036-.208 1.414-.586l6.707-6.707A.993.993 0 0022 14zM9 15H6.732c-.356 0-.688-.192-.867-.5a.996.996 0 00-1.366-.365.998.998 0 00-.364 1.366A3.008 3.008 0 006.733 17h.268a1 1 0 102 0c1.654 0 3-1.346 3-3 0-1.36-.974-2.51-2.315-2.733l-3.041-.507a.768.768 0 01-.644-.76c0-.551.448-1 1-1h2.268c.356 0 .688.192.867.5a.998.998 0 101.73-1.001 3.009 3.009 0 00-2.598-1.5H9a1 1 0 10-2 0c-1.654 0-3 1.346-3 3 0 1.36.974 2.51 2.315 2.733l3.041.507a.768.768 0 01.644.76c0 .551-.448 1-1 1zm5-3a1 1 0 102 0 1 1 0 00-2 0z"></path>
                    </svg>
                    Payment Settings
                  </h4>
                  <div className="profile-new-payment-settings-select-options">
                    <Row>
                      <Col md={6}>
                        <div className="profile-new-payment-settings-select-options-item">
                          <h5>Change Currency</h5>
                          <Select
                            // defaultValue={options[0]}
                            options={options}
                            components={{ Option: IconOption }}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            styles={customStyles}
                            isSearchable={false}
                            // menuIsOpen={true}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="profile-new-payment-settings-select-options-item">
                          <h5>Date Format</h5>
                          <DatePicker
                            selected={startDate}
                            showIcon
                            onChange={(date) => setStartDate(date)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div> */}
              </div>
            </FORM>
          )}
        </Formik>
      </div>
      {newDeleteProfile && (
        <DeleteProfileModal
          newEditProfile={newDeleteProfile}
          closeNewEditProfileModal={closeNewDeleteProfileModal}
          setNewEditProfile={setNewDeleteProfile}
          profile={profile.data}
        />
      )}
      {newTFAModal && (
        <TFAModal
          newEditProfile={newTFAModal}
          closeNewEditProfileModal={closeNewTFAModal}
          setNewEditProfile={setNewTFAModal}
        />
      )}
      {newChangePassword && (
        <ChangePasswordModal
          newChangePassword={newChangePassword}
          closeNewChangePasswordModal={closeNewChangePasswordModal}
          setNewChangePassword={setNewChangePassword}
        />
      )}
      {showVerify ? (
        <OnBoardingVerificationModal
          forgotPassword={2}
          showVerify={showVerify}
          closeVerify={() => setShowVerify(false)}
        />
      ) : null}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default withTranslation(ProfileIndex);

import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  chatAsTicket: getCommonInitialState(),
};

const SupportTicketSlice = createSlice({
  name: "supportticket",
  initialState,
  reducers: {
    
    // Make chat as ticket
    makeChatAsTicketStart: (state, action) => { state.chatAsTicket = getCommonStart(action.payload) },
    makeChatAsTicketSuccess: (state, action) => { state.chatAsTicket = getCommonSuccess(action.payload) },
    makeChatAsTicketFailure: (state, action) => { state.chatAsTicket = getCommonFailure(action.payload) },

  },
});

export const {
  makeChatAsTicketFailure,
  makeChatAsTicketStart,
  makeChatAsTicketSuccess,
} = SupportTicketSlice.actions;

export default SupportTicketSlice.reducer;

import { takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import { fetchStaticPageFailure, fetchStaticPageSuccess } from "../slices/PageSlice";

function* fetchStaticPageAPI(action) {
  yield getCommonSaga({
    apiUrl: "static_pages/show",
    payload: action.payload,
    successNotify: false,
    success: fetchStaticPageSuccess,
    failure: fetchStaticPageFailure,
  });
}

export default function* PageSaga() {
  yield all([
    yield takeLatest("page/fetchStaticPageStart", fetchStaticPageAPI),
  ]);
}

import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./OnBoarding.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { updateWidgetTypeStart } from "../../store/slices/OnboardingSlice";
import OnboardingStepsContent from "./OnboardingStepsContent";
import useBot from "../../hooks/useBot";
import NavLogo from "../Layout/Header/NavLogo";

const ChooseSolutionIndex = (props) => {

  const {botType, setBotType} = props;
  const { widgets } = useBot();
  const t = useTranslation("on_premise");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updateWidgetType = useSelector(
    (state) => state.onboarding.updateWidgetType
  );
  const profile = useSelector((state) => state.admin.profile);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (
      !skipRender &&
      !updateWidgetType.loading &&
      Object.keys(updateWidgetType.data).length > 0
    )
      props.setStep(2);
    setSkipRender(false);
  }, [updateWidgetType]);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo />
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-body-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <Form className="onboarding-form">
                    <div
                      className="onboarding-choose-solution-box"
                      onChange={(e) => setBotType(e.target.value)}
                    >
                      {widgets.map((widget) => (
                        <>
                          <label for={widget.value} className="radio-card">
                            <input
                              type="radio"
                              name="radio-card"
                              id={widget.value}
                              value={widget.value}
                              checked={botType == widget.value}
                            />
                            <div className="card-content-wrapper">
                              <span className="check-icon"></span>
                              <div className="card-content">
                                <Image
                                  className="onboarding-choose-solution-logo-icon"
                                  src={widget.picture}
                                />
                              </div>
                            </div>
                          </label>
                        </>
                      ))}
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
            <div className="onboarding-footer-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-on-premise-btn-sec">
                    <Button
                      className="onboarding-back-btn"
                      onClick={() => navigate(-1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00195E"
                          d="M20 11H6.83l2.88-2.88A.997.997 0 008.3 6.71L3.71 11.3a.996.996 0 000 1.41L8.3 17.3a.997.997 0 101.41-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z"
                        ></path>
                      </svg>
                      {t("back")}
                    </Button>
                    <Button
                      className="onboarding-btn"
                      onClick={() => {
                        // dispatch(
                        //   updateWidgetTypeStart({
                        //     widget_type: botType,
                        //   })
                        // )
                        props.setStep(2);
                      }}
                      disabled={
                        botType == "" ||
                        botType == null ||
                        updateWidgetType.buttonDisable
                      }
                    >
                      {updateWidgetType.buttonDisable ? t("loading") : t("next")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            {/* <div className="onboarding-footer-sec choose-solution-footer-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="ai-as-service-btn-sec">
                    <Button
                      className="onboarding-btn"
                      onClick={() => {
                        // dispatch(
                        //   updateWidgetTypeStart({
                        //     widget_type: botType,
                        //   })
                        // )
                        props.setStep(2);
                      }}
                      disabled={
                        botType == "" ||
                        botType == null ||
                        updateWidgetType.buttonDisable
                      }
                    >
                      {updateWidgetType.buttonDisable ? "Loading" : "Next"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div> */}
          </div>
        </div>
        <OnboardingStepsContent step={0} />
      </div>
    </>
  );
};

export default ChooseSolutionIndex;

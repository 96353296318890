import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { fineTuneSaveStart } from "../../store/slices/FineTuneSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const ImportWebpageModal = (props) => {
  const t = useTranslation("import_finetune_modal");
  const dispatch = useDispatch();
  const fineTuneSave = useSelector((state) => state.fineTune.fineTuneSave);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("web.name.required")).trim().notOneOf(['']),
    import_url: Yup.string()
    .required(t("web.website.required"))
    .matches(/^(?!https?)(?!http\.?).*\..+$/g, t("web.website.invalid")),
  });

  const isValidUrl = (url) => {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
  };

  const saveTune = (values) => {
    dispatch(fineTuneSaveStart({
      ...values,
      import_url: "https://" + values.import_url,
      onboarding: props.onboarding,
      bot_id: props.onboardingBot.bot_id ?? 0
    }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !fineTuneSave.loading &&
      Object.keys(fineTuneSave.data).length > 0
    ) {
      props.closeImportWebpageModal()
      if(props.onboarding===1 && props.step && props.step!==4)
      {
        props.setStep(4);
      }
    }
    setSkipRender(false);
  }, [fineTuneSave]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.importWebpage}
        onHide={props.closeImportWebpageModal}
      >
        <Modal.Body>
          <h4>{t("web.heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeImportWebpageModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M16.061 10.061L14.122 12l1.939 1.939a1.5 1.5 0 11-2.122 2.121L12 14.121l-1.939 1.939c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121L9.878 12l-1.939-1.939A1.5 1.5 0 1110.06 7.94l1.939 1.939 1.939-1.939a1.5 1.5 0 112.121 2.121zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-3 0c0-4.963-4.037-9-9-9s-9 4.037-9 9 4.038 9 9 9 9-4.037 9-9z"></path>
            </svg>
          </Button>
          <Formik
            initialValues={{
              name: "",
              description: "",
              import_url: "",
            }}
            validationSchema={validationSchema}
            onSubmit={saveTune}
          >
            {({ setFieldValue, values }) => (
              <FORM noValidate className="onboarding-form">
                {/* <Form className="onboarding-form"> */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("web.name.label")} <span>*</span>
                  </Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("web.name.placeholder")}
                    name="name"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="name"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Label>
                  {t("web.website.label")} <span>*</span>
                </Form.Label>
                <div className="mb-3">
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">https://</InputGroup.Text>
                    <Field
                      className="form-control"
                      placeholder={t("web.website.placeholder")}
                      aria-label="Website"
                      aria-describedby="basic-addon1"
                      name="import_url"
                    />
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="import_url"
                    className="errorMsg"
                  />
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>{t("web.about.label")}</Form.Label>
                  <Field
                    className="form-control"
                    as="textarea"
                    rows={3}
                    placeholder={t("web.about.placeholder")}
                    name="description"
                  />
                </Form.Group>
                <div className="onboarding-import-webpage-modal-btn-sec">
                  <Button
                    className="onboarding-cancel-btn"
                    onClick={() => props.closeImportWebpageModal()}
                    disabled={fineTuneSave.buttonDisable}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    className="onboarding-save-btn"
                    type="submit"
                    disabled={fineTuneSave.buttonDisable}
                  >
                    {fineTuneSave.buttonDisable ? t("loading") : t("add")}
                  </Button>
                </div>
                {/* </Form> */}
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImportWebpageModal;

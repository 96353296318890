import React, { Fragment, memo, useCallback, useState } from "react";
import { Form, InputGroup, Image, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  addChat,
  setChats,
  setSelectedChats,
  updateChatMessages,
  updateChatSuggtion,
  addChatMsgCount,
} from "../../store/slices/ChatSlice";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useSocket from "../../hooks/useSocket";
import useDesign from "../../hooks/useDesign";
import { ButtonLoader } from "../Helper/Loader";
import { apiUrl } from "../../Environment";
import EmojiPicker from "emoji-picker-react";
import { useDropzone } from "react-dropzone";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import ChatPreviewLoader from "../Helper/ChatPreviewLoader";
import Toggle from "react-styled-toggle";
import NotChatFound from "../Helper/NoChatFound";
import { Tooltip as ReactTooltip } from "react-tooltip";
dayjs.extend(customParseFormat);

const InboxChat = ({ chatId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const selectedChats = useSelector((state) => state.chat.selectedChats);
  const highlightChat = useSelector((state) => state.chat.highlightChat);
  const chats = useSelector((state) => state.chat.chats);
  const chatList = useSelector((state) => state.chat.chatList);

  const [singleMsg, setSingleMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [showChat, setShowChat] = useState(true);
  const [isMin, setIsMin] = useState(false);
  const [first, setFirst] = useState(true);
  const [notify, setNotify] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [cursorPointer, setCursorPointer] = useState(0);
  const [skipRender, setSkipRender] = useState(true);
  const [scrollDown, setScrollDown] = useState({
    notify: false,
    msg_count: 0,
  });
  const lastMsg = useRef();
  const messageField = useRef();
  const containerRef = useRef(null);

  const { getChatTypeBg } = useDesign();

  const { subscribe, reconnect, update, sendMessage, unSubscribe } =
    useSocket();
  const [chat, setChat] = useState({ messages: [], chatId, notify: false });
  const [isSuggestion, setIsSuggestion] = useState(false);

  useEffect(() => {
    setChat(chats.find((c) => c.chatId === chatId) ?? chat);
  }, [chats]);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      getInitialChat();
    }
  }, [profile, chatId]);

  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : sessionStorage.getItem("token");

  const getInitialChat = async () => {
    setSingleMsg("");
    setLoading(true);
    setScrollDown({
      notify: false,
      msg_count: 0,
      isAtBottom: true,
    });
    setFirst(true);
    let formData = new FormData();
    formData.append("chat_id", chatId);
    const response = await axios.post(`${apiUrl}chats/messages`, formData, {
      headers: { Authorization: "Bearer " + token },
    });
    // console.log("response", response.data);
    if (response.data && response.data.data && response.data.data.chat) {
      setLoading(false);
      dispatch(
        addChat({
          chatId,
          username: response.data.data.chat.username,
          chat_unique_id: response.data.data.chat.chat_unique_id,
          autoMsg: true,
          notify: true,
          messages: response.data.data.chat_messages,
          socketStatus: 0,
        })
      );
      subscribe(response.data.data.chat.username);
    } else {
      setLoading(false);
      setShowChat(false);
    }
  };

  // useEffect(() => {
  //   const container = containerRef?.current;
  //   const handleScroll = () => {
  //     const container = containerRef.current;
  //     if (container) {
  //       const isAtBottom =
  //         container.scrollTop + container.clientHeight === container.scrollHeight;
  //       // setReachedBottom(isAtBottom);
  //       console.log("isAtBottom", isAtBottom)
  //     }
  //   };

  //   // Attach the scroll event listener to the container

  //   container.addEventListener('scroll', handleScroll);

  //   // Clean up the event listener on unmount
  //   // return () => {
  //   //   const container = containerRef?.current;
  //   //   container.removeEventListener('scroll', handleScroll);
  //   // };
  // }, []);

  useEffect(() => {
    handleScrollDown();
    if (chat.messages && lastMsg.current) {
      if (first)
        lastMsg?.current.scrollIntoView({
          behavior: first ? "instant" : "smooth",
        });
      // const container = containerRef.current;
      // if (container) {
      //   const scrollPosition = container.scrollTop + container.clientHeight;
      //   const isNotAtBottom = container.scrollHeight - scrollPosition > 200;
      //   if (!first && isNotAtBottom) {
      //     setScrollDown({
      //       ...scrollDown,
      //       notify: true,
      //       msg_count: scrollDown.msg_count + 1,
      //     });
      //   }
      //   console.log(
      //     "isNotAtBottom",
      //     isNotAtBottom,
      //     scrollPosition,
      //     container.scrollHeight
      //   );
      // }
      setFirst(false);
      // const handleScroll = () => {
      //   const container = containerRef.current;
      //   if (container) {
      //     const isAtBottom =
      //       container.scrollTop + container.clientHeight ===
      //       container.scrollHeight;
      //     console.log("scrollDown", scrollDown.isAtBottom)
      //     if (isAtBottom)
      //       setScrollDown({
      //         notify: false,
      //         msg_count: 0,
      //         isAtBottom: true,
      //       });
      //     else if (scrollDown.isAtBottom)
      //       setScrollDown({
      //         ...scrollDown,
      //         isAtBottom: false,
      //       });
      //   }
      // };
      // container.addEventListener("scroll", handleScroll);
      // return () => {
      //   container.removeEventListener("scroll", handleScroll);
      // };
    }
  }, [chat.messages]);

  useEffect(() => {
    if (chat.suggetion && chat.socketStatus === 1) {
      setSingleMsg((msg) =>
        msg ? msg + "\n\n" + chat.suggetion : chat.suggetion
      );
      setIsSuggestion(true);
      dispatch(updateChatSuggtion({ username: chat.username, suggetion: "" }));
    }
  }, [chat.suggetion]);

  useEffect(() => {
    if (!first && chat.socketStatus === 1) setNotify(true);
  }, [chat.notify]);

  const onSend = async (e) => {
    e.preventDefault();
    if (singleMsg && !sendLoading) {
      setShowEmoji(false);
      setSendLoading(true);
      let formData = new FormData();
      formData.append("chat_id", chatId);
      formData.append("answer", singleMsg);
      formData.append("sent_by", isSuggestion ? "ai_client" : "client");
      try {
        const response = await axios.post(
          `${apiUrl}hooks/send_message`,
          formData,
          { headers: { Authorization: "Bearer " + token } }
        );
        if (response.data.success) {
          // console.log(response.data);
          setIsSuggestion(false);
          const message = response.data.data.chat_message;
          const date = dayjs().format("DD MMM YYYY");
          dispatch(
            updateChatMessages({
              username: response.data.data.chat_formatted.username,
              message: { date, message },
              disableNotify: true,
            })
          );
          dispatch(addChatMsgCount({chat_id: chatId, type: "text", message: singleMsg}));
          setSingleMsg("");
          sendMessage(response.data.data.chat_formatted.username, {
            date,
            message,
          });
        } else {
          console.log(response);
          getErrorNotificationMessage(response.data.error);
        }
        setSendLoading(false);
      } catch (e) {
        setSendLoading(false);
        getErrorNotificationMessage(e);
      }
    }
  };

  const onSendFile = async (file) => {
    if (!fileLoading) {
      setShowEmoji(false);
      setFileLoading(true);
      let formData = new FormData();
      formData.append("chat_id", chatId);
      formData.append("image", file);
      formData.append("sent_by", "client");
      try {
        const response = await axios.post(
          `${apiUrl}hooks/send_message`,
          formData,
          { headers: { Authorization: "Bearer " + token } }
        );
        if (response.data.success) {
          // console.log(response.data);
          const message = response.data.data.chat_message;
          const date = dayjs().format("DD MMM YYYY");
          dispatch(
            updateChatMessages({
              username: response.data.data.chat_formatted.username,
              message: { date, message },
              disableNotify: true,
            })
          );
          dispatch(addChatMsgCount({chat_id: chatId, type: "file", message: file}));
          setSingleMsg("");
          sendMessage(response.data.data.chat_formatted.username, {
            date,
            message,
          });
        } else {
          console.log(response);
          getErrorNotificationMessage(response.data.error);
        }
        setFileLoading(false);
      } catch (e) {
        setFileLoading(false);
        getErrorNotificationMessage(false);
      }
    }
  };

  const removeChat = () => {
    dispatch(
      setSelectedChats(selectedChats.filter((chat_id) => chat_id != chatId))
    );
    setTimeout(() => {
      unSubscribe(chat.username);
    }, 500);
  };

  useEffect(() => {
    changeAutoMsg();
    console.log("unSubscribe", chat.username);
    return () => {
      unSubscribe(chat.username);
    };
  }, [chat.username]);

  const changeAutoMsg = () => update(chat.username);

  useEffect(() => {
    if (!skipRender && messageField.current) {
      messageField.current.selectionEnd = cursorPointer;
    }
    setSkipRender(false);
  }, [cursorPointer]);

  const onEmojiPick = (data) => {
    const ref = messageField.current;
    ref.focus();
    const start = singleMsg.substring(0, ref.selectionStart);
    const end = singleMsg.substring(ref.selectionStart);
    const text = start + data.emoji + end;
    setSingleMsg(text);
    setCursorPointer(start.length + data.emoji.length);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      onSendFile(file);
    });
  }, [chatId]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "image/jpeg": [".jpeg", ".png", ".gif"],
    },
    maxFiles: 1,
    noDragEventsBubbling: true,
  });

  const handleScrollDown = () => {
    // setScrollDown({
    //   notify: false,
    //   msg_count: 0,
    //   isAtBottom: true,
    // });
    lastMsg?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return loading || chatList.loading ? (
    <ChatPreviewLoader />
  ) : showChat && chat.chat_unique_id ? (
    <>
      <div className="new-chat-preview-card-left-header-sec">
        <div className="new-chat-preview-ip-info-sec">
          <div
            className={`new-chat-preview-user-img-sec ${getChatTypeBg(
              chat.widget_type
            )}`}
          >
            <Image
              className="new-chat-preview-user-img"
              src={window.location.origin + "/images/your-bot/your-bot-img.svg"}
            />
          </div>
          <div className="new-chat-preview-user-info">
            <h4>{chat.chat_unique_id}</h4>
            {/* <Link to="#" className="new-chat-preview-user-url-link">
              https://haive.tech
            </Link> */}
          </div>
        </div>
        <div className="new-chat-preview-ip-icon-right" data-tooltip-id="my-tooltip-2">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="#fff"
            data-name="Layer 1"
            viewBox="0 0 24 24"
          >
            <path d="M15 3v18c0 1.66-1.34 3-3 3s-3-1.34-3-3V3c0-1.66 1.34-3 3-3s3 1.34 3 3zm6 3c-1.66 0-3 1.34-3 3v12c0 1.66 1.34 3 3 3s3-1.34 3-3V9c0-1.66-1.34-3-3-3zM3 12c-1.66 0-3 1.34-3 3v6c0 1.66 1.34 3 3 3s3-1.34 3-3v-6c0-1.66-1.34-3-3-3z"></path>
          </svg> */}
          <Toggle
            backgroundColorChecked="#F9AF1A"
            backgroundColorUnchecked="#9c9ca5"
            width={50}
            height={25}
            sliderWidth={19}
            sliderHeight={19}
            translate={23}
            checked={chat.autoMsg}
            onChange={() => changeAutoMsg()}
          />
        </div>
      </div>
      <div className="new-chat-preview-card-left-body-sec" ref={containerRef}>
        {chat.messages.length > 0 ? (
          <>
            {chat.messages.map((dailyChat, i) => (
              <Fragment key={i}>
                <div className="new-chat-preview-sticky-date-time">
                  {dailyChat.date}
                </div>
                {dailyChat.messages.map((message, i) =>
                  message.sent_by === "user" ? (
                    <div
                      className="new-chat-preview-user-receiver-card"
                      key={i}
                    >
                      <div className="new-chat-preview-user-receiver-icon-sec">
                        <Image
                          className="new-chat-preview-user-receiver-icon"
                          src={window.location.origin + "/images/chat/user.png"}
                        />
                      </div>
                      <div className="new-chat-preview-user-receiver-message-card">
                        <div className="new-chat-preview-user-receiver-message">
                          {message.file ? (
                            <div className="my-2">
                              <CustomLazyLoad
                                className="chat-img"
                                src={message.file}
                              />
                            </div>
                          ) : null}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: urlify(message.message),
                            }}
                          />
                        </div>
                        <div className="client-chat-user-receiver-time">
                          <span>{message.send_at}</span>
                          {/* <div className="client-chat-user-receiver-thumbs-down-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              fill="none"
                              stroke="#32089f"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              className="feather feather-thumbs-down"
                              viewBox="0 0 24 24"
                            >
                              <path d="M10 15v4a3 3 0 003 3l4-9V2H5.72a2 2 0 00-2 1.7l-1.38 9a2 2 0 002 2.3zm7-13h2.67A2.31 2.31 0 0122 4v7a2.31 2.31 0 01-2.33 2H17"></path>
                            </svg>
                             <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#32089f"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="feather feather-thumbs-up"
                                viewBox="0 0 24 24"
                              >
                                <path d="M14 9V5a3 3 0 00-3-3l-4 9v11h11.28a2 2 0 002-1.7l1.38-9a2 2 0 00-2-2.3zM7 22H4a2 2 0 01-2-2v-7a2 2 0 012-2h3"></path>
                              </svg>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="new-chat-preview-user-sender-card" key={i}>
                      <div className="new-chat-preview-user-sender-icon-sec">
                        <Image
                          className="new-chat-preview-user-sender-icon"
                          src={
                            window.location.origin +
                            "/images/chat/new-chat-bot-img.png"
                          }
                        />
                      </div>
                      <div className="new-chat-preview-user-sender-message-card">
                        <div className="new-chat-preview-user-sender-message">
                          {message.file ? (
                            <div className="my-2">
                              <CustomLazyLoad
                                className="chat-img"
                                src={message.file}
                              />
                            </div>
                          ) : null}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: urlify(message.message),
                            }}
                          />
                        </div>
                        <div className="client-chat-user-sender-time">
                          {message.send_at}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Fragment>
            ))}
            <div ref={lastMsg} />
          </>
        ) : null}
        {chat.socketStatus === 1 ? (
          <div className="new-chat-preview-card-left-footer-form">
            <Form onSubmit={onSend}>
              {showEmoji ? <EmojiPicker onEmojiClick={onEmojiPick} /> : null}
              <div className="new-chat-preview-input-typing-card">
                <div className="new-chat-preview-input-typing-card-top-sec">
                  <ChatMessageBox
                    value={singleMsg}
                    onChange={(e) => setSingleMsg(e.target.value)}
                    textareaRef={messageField}
                    onSend={onSend}
                  />
                </div>
                <div className="new-chat-preview-input-typing-card-bottom-sec">
                  <div className="new-chat-preview-input-typing-card-left-sec">
                    <Button
                      className="emoji-btn"
                      onClick={() => setShowEmoji(!showEmoji)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 24a12 12 0 1112-12 12.013 12.013 0 01-12 12zm0-22a10 10 0 1010 10A10.011 10.011 0 0012 2zm5.666 13.746a1 1 0 00-1.33-1.494A7.508 7.508 0 0112 16a7.509 7.509 0 01-4.334-1.746 1 1 0 00-1.332 1.492A9.454 9.454 0 0012 18a9.454 9.454 0 005.666-2.254zM6 10c0 1 .895 1 2 1s2 0 2-1a2 2 0 00-4 0zm8 0c0 1 .895 1 2 1s2 0 2-1a2 2 0 00-4 0z"></path>
                      </svg>
                    </Button>
                    <div className="upload-btn-wrapper">
                      <div className="new-chat-attached-file">
                        <button
                          className="search-btn"
                          type="button"
                          onClick={() => (!fileLoading ? open() : null)}
                        >
                          {fileLoading ? (
                            <Image
                              className="btn-loader"
                              src={
                                window.location.origin +
                                "/images/loader/btn-loader.gif"
                              }
                            />
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M22.95 9.6a1 1 0 00-1.414 0L10.644 20.539a5 5 0 11-7.072-7.071L14.121 2.876a3 3 0 014.243 4.242L7.815 17.71a1.022 1.022 0 01-1.414 0 1 1 0 010-1.414l9.392-9.435a1 1 0 00-1.414-1.414l-9.392 9.435a3 3 0 000 4.243 3.073 3.073 0 004.243 0L19.778 8.532a5 5 0 00-7.071-7.07L2.158 12.054a7 7 0 009.9 9.9L22.95 11.018a1 1 0 000-1.418z"></path>
                              </svg>
                            </>
                          )}
                        </button>
                        <input {...getInputProps()} disabled={fileLoading} />
                      </div>
                    </div>
                  </div>
                  <div className="new-chat-preview-input-typing-card-end-sec">
                    <Button
                      type="submit"
                      className="send-btn"
                      disabled={sendLoading}
                    >
                      {sendLoading ? "Loading" : "Send"}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        ) : chat.socketStatus === 2 ? (
          <div className="new-chat-preview-card-left-footer-form">
            <h4>Monitoring mode</h4>
            <p>Chat is already connected in another device</p>
            <Button
              className="onboarding-add-fine-tuning-btn btn btn-primary"
              type="button"
              onClick={() => reconnect(chat.username)}
            >
              Make this Active
            </Button>
          </div>
        ) : null}
        {/* {!scrollDown.isAtBottom && ( */}
        <div className="scroll-down-button" onClick={handleScrollDown}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#00195e"
            data-name="Layer 1"
            viewBox="0 0 24 24"
          >
            <path d="M12.836.029C9.367-.206 5.961 1.065 3.509 3.521S-.211 9.378.03 12.854C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.861 19.097.454 12.836.029zm3.871 14.56l-2.535 2.535a2.992 2.992 0 01-2.122.877H12a2.982 2.982 0 01-2.071-.877l-2.636-2.636a.999.999 0 111.414-1.414L11 15.367V7a1 1 0 012 0v8.467l2.293-2.293a.999.999 0 111.414 1.414z"></path>
          </svg>
        </div>
        {/* )} */}
        {/* {scrollDown.notify && (
          <div className="new-msg-notify" onClick={handleScrollDown}>
            <div className="msg-content">
              <span>{scrollDown.msg_count} New Message</span>
            </div>
          </div>
        )} */}
      </div>
      <ReactTooltip
        id="my-tooltip-2"
        place="bottom"
        className="opaque"
        variant={!chat.autoMsg ? "success":"warning"}
        content={!chat.autoMsg ? "Enable Automated Reply" : "Disable Automated Reply"}
      />
    </>
  ) : (
    <NotChatFound />
  );
};

export const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  text = text.replaceAll("\n", "<br />");
  return text.replaceAll(urlRegex, function (url) {
    return (
      '<a className="text-info underline" href="' +
      url +
      '" target="_blank">' +
      url +
      "</a>"
    );
  });
};

const ChatMessageBox = ({ value, onChange, textareaRef, onSend }) => {
  // const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    console.log(scrollHeight);
    textareaRef.current.style.height =
      scrollHeight < 76
        ? "76px"
        : scrollHeight > 192
        ? "192px"
        : scrollHeight + "px";
  }, [value]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onSend(event);
    }
  };

  return (
    <textarea
      ref={textareaRef}
      className="form-control w-full border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 chat-msg"
      placeholder="Write your message"
      type="text"
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default InboxChat;

import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    dashboard: getCommonInitialState(),
    dashboardGraph: getCommonInitialState(),
    graphUpdate: getCommonInitialState(),
    dashboardRecentChats: getCommonInitialState(),
};

const DashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {

        //Dashboard
        dashboardStart: (state, action) => { state.dashboard = getCommonStart() },
        dashboardSuccess: (state, action) => { state.dashboard = getCommonSuccess(action.payload) },
        dashboardFailure: (state, action) => { state.dashboard = getCommonFailure(action.payload) },
        dashboardListUpdate: (state, { payload }) => {
            if (state.dashboard.data.recent_chats)
                state.dashboard = getCommonSuccess({
                    ...state.dashboard.data,
                    recent_chats: [payload, ...state.dashboard.data.recent_chats.filter(chat => chat.chat_id !== payload.chat_id).slice(0, 5)],
                })
            else state.dashboard = state.dashboard
        },
        disableDashboardNotify: (state, { payload }) => {
            state.dashboard = getCommonSuccess({
                ...state.dashboard.data,
                recent_chats: state.dashboard.data.recent_chats.map(chat => chat.chat_id === payload ? { ...chat, notify: false } : chat)
            })
        },

        //Dashboard Graph
        dashboardGraphStart: (state, action) => { state.dashboardGraph = getCommonStart() },
        dashboardGraphSuccess: (state, action) => { state.dashboardGraph = getCommonSuccess(action.payload) },
        dashboardGraphFailure: (state, action) => { state.dashboardGraph = getCommonFailure(action.payload) },
        dashboardGraphUpdate: (state, action) => { state.graphUpdate = getCommonSuccess(action.payload) },

        //Dashboard Recent Chats Table
        dashboardRecentChatsStart: (state, action) => { state.dashboardRecentChats = getCommonStart() },
        dashboardRecentChatsSuccess: (state, action) => { state.dashboardRecentChats = getCommonSuccess(action.payload) },
        dashboardRecentChatsFailure: (state, action) => { state.dashboardRecentChats = getCommonFailure(action.payload) },
    }
});


export const {
    dashboardStart,
    dashboardSuccess,
    dashboardFailure,
    dashboardListUpdate,
    disableDashboardNotify,
    dashboardGraphFailure,
    dashboardGraphStart,
    dashboardGraphSuccess,
    dashboardGraphUpdate,
    dashboardRecentChatsFailure,
    dashboardRecentChatsStart,
    dashboardRecentChatsSuccess,

} = DashboardSlice.actions;

export default DashboardSlice.reducer;
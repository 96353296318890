import React, { useEffect, useState } from "react";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import ChatCustomize from "./Customise/ChatCustomize";
import ChatPreview from "./ChatPreview";
import SettingsfineTune from "./CustomTraining/SettingsFinetune";
import SourceScriptIndex from "./Source/SourceScriptIndex";
import SettingAnalyticsIndex from "./Analytics/SettingAnalyticsIndex";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { botsListStart } from "../../store/slices/BotSlice";
import NoBotExists from "../Helper/NoBotExists";
import AddBotModal from "../Bots/AddBotModal";
import { useTranslation, withTranslation } from "react-multi-lang";
import {
  fineTuneListStart,
} from "../../store/slices/FineTuneSlice";

const SettingsIndex = (props) => {

  const t = useTranslation("settings.options");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const botsList = useSelector((state) => state.bots.botsList);
  const botsStore = useSelector((state) => state.bots.botsStore);

  const [tab, setTab] = useState(searchParams.get("tab") ? searchParams.get("tab") : "customize");
  const [bot, setBot] = useState(selectedBot);
  const [addBot, setAddBot] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [botDetails, setBotDetails] = useState({
    name: "",
    website: "",
  })

  const closeAddBotModal = () => {
    setAddBot(false);
  };

  useEffect(() => {
    dispatch(botsListStart());
  }, []);

  useEffect(() => {
    if(Object.keys(selectedBot).length > 0) {
      setBot(selectedBot);
      setBotDetails({
        ...botDetails,
        name: selectedBot.name,
        website: selectedBot.website
      })
      dispatch(
        fineTuneListStart({ bot_id: selectedBot.bot_id })
      );
    }
  }, [selectedBot]);

  useEffect(()=> {
    if(!skipRender && !botsStore.loading && Object.keys(botsStore.data).length > 0 && Object.keys(botsStore.data.bot).length > 0) {
      setBot(botsStore.data.bot);
      setBotDetails({
        ...botDetails,
        name: botsStore.data.bot.name,
        website: botsStore.data.bot.website
      })
    }
    setSkipRender(false);
  }, [botsStore])

  return (
    <>
      <div className="admin-ui-setting-body-sec">
        <div className="new-settings-sec">
          {/* <div className="new-settings-box"> */}
            {Object.keys(selectedBot).length > 0 ? (
              <div className="new-settings-box">
                <div className="new-settings-tab-sec">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={searchParams.get("tab") ? searchParams.get("tab") : "customize"}
                    onSelect={(key, ev) => (ev.type != "click") && setTab(key)}
                  >
                    <Row>
                      <Col sm={12}>
                        <div className="new-settings-tab-nav-link-sec">
                          <Nav variant="pills">
                            <Nav.Item>
                              <Nav.Link
                                eventKey="customize"
                                onClick={() => setTab("customize")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                                  <path d="M21.294 13.9l-.444-.256a9.1 9.1 0 000-3.29l.444-.256a3 3 0 10-3-5.2l-.445.257A8.977 8.977 0 0015 3.513V3a3 3 0 00-6 0v.513a8.977 8.977 0 00-2.848 1.646L5.705 4.9a3 3 0 00-3 5.2l.444.256a9.1 9.1 0 000 3.29l-.444.256a3 3 0 103 5.2l.445-.257A8.977 8.977 0 009 20.487V21a3 3 0 006 0v-.513a8.977 8.977 0 002.848-1.646l.447.258a3 3 0 003-5.2zm-2.548-3.776a7.048 7.048 0 010 3.75 1 1 0 00.464 1.133l1.084.626a1 1 0 01-1 1.733l-1.086-.628a1 1 0 00-1.215.165 6.984 6.984 0 01-3.243 1.875 1 1 0 00-.751.969V21a1 1 0 01-2 0v-1.252a1 1 0 00-.751-.969A6.984 6.984 0 017.006 16.9a1 1 0 00-1.215-.165l-1.084.627a1 1 0 11-1-1.732l1.084-.626a1 1 0 00.464-1.133 7.048 7.048 0 010-3.75 1 1 0 00-.465-1.129l-1.084-.626a1 1 0 011-1.733l1.086.628A1 1 0 007.006 7.1a6.984 6.984 0 013.243-1.875A1 1 0 0011 4.252V3a1 1 0 012 0v1.252a1 1 0 00.751.969A6.984 6.984 0 0116.994 7.1a1 1 0 001.215.165l1.084-.627a1 1 0 111 1.732l-1.084.626a1 1 0 00-.463 1.129z"></path>
                                </svg>
                                {t("customise")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="fine-tune"
                                onClick={() => setTab("fine-tune")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                                </svg>
                                {t("fine_tune")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link onClick={() => navigate("/inbox")}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                                  <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                                </svg>
                                {t("inbox")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="source"
                                onClick={() => setTab("source")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M19 1H5a5.006 5.006 0 00-5 5v12a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V6a5.006 5.006 0 00-5-5zM5 3h14a3 3 0 013 3v1H2V6a3 3 0 013-3zm14 18H5a3 3 0 01-3-3V9h20v9a3 3 0 01-3 3zm0-8a1 1 0 01-1 1H6a1 1 0 010-2h12a1 1 0 011 1zm-4 4a1 1 0 01-1 1H6a1 1 0 010-2h8a1 1 0 011 1zM3 5a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1z"></path>
                                </svg>
                                {t("source")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="analytics"
                                onClick={() => setTab("analytics")}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M23 22H5a3 3 0 01-3-3V1a1 1 0 00-2 0v18a5.006 5.006 0 005 5h18a1 1 0 000-2z"></path>
                                  <path d="M6 20a1 1 0 001-1v-7a1 1 0 00-2 0v7a1 1 0 001 1zM10 10v9a1 1 0 002 0v-9a1 1 0 00-2 0zM15 13v6a1 1 0 002 0v-6a1 1 0 00-2 0zM20 9v10a1 1 0 002 0V9a1 1 0 00-2 0zM6 9a1 1 0 00.707-.293l3.586-3.586a1.025 1.025 0 011.414 0l2.172 2.172a3 3 0 004.242 0l5.586-5.586A1 1 0 0022.293.293l-5.586 5.585a1 1 0 01-1.414 0l-2.172-2.171a3 3 0 00-4.242 0L5.293 7.293A1 1 0 006 9z"></path>
                                </svg>
                                {t("analytics")}
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          {tab == "customize" && (
                            <Tab.Pane eventKey="customize">
                              <ChatCustomize setBotDetails={setBotDetails} botDetails={botDetails} setBot={setBot} bot={bot} />
                            </Tab.Pane>
                          )}
                          {tab == "fine-tune" && (
                            <Tab.Pane eventKey="fine-tune">
                              <SettingsfineTune />
                            </Tab.Pane>
                          )}
                          {tab == "analytics" && (
                            <Tab.Pane eventKey="analytics">
                              <SettingAnalyticsIndex />
                            </Tab.Pane>
                          )}
                          {tab == "source" && (
                            <Tab.Pane eventKey="source">
                              <SourceScriptIndex />
                            </Tab.Pane>
                          )}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
                <div className="new-settings-preview-sec">
                  {Object.keys(selectedBot).length > 0 ? (
                    <ChatPreview selectedBot={bot} botDetails={botDetails} />
                  ) : null}
                </div>
              </div>
            ) : (!botsList.loading && Object.keys(botsList.data).length > 0 && Object.keys(botsList.data.bots).length <= 0) &&
            (
              <div className="mt-5">
                <NoBotExists setAddBot={setAddBot} />
                </div>
            )
            }
          {/* </div> */}
        </div>
      </div>
      {addBot && (
        <AddBotModal
          addBot={addBot}
          closeAddBotModal={closeAddBotModal}
          setAddBot={setAddBot}
        />
      )}
    </>
  );
};

export default withTranslation(SettingsIndex);

import React from "react";
import { Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const NoBillingExists = () => {
  return (
    <>
      <div className="no-bot-exists-card">
        <div className="no-bot-exists-img-sec">
            <Image
            className="no-bot-exists-img"
            src={
                window.location.origin +
                "/images/no-data/no-billing-exists-img.svg"
            }
            />
        </div>
        <div className="no-bot-exists-info">
            <h4>No Bills Exists !</h4>
            <p>Subscribe Now and Experience the Best - In Seconds!</p>
           <div className="no-bot-exists-btn-sec">
           <Link
              to="/subscription"
              className="onboarding-add-fine-tuning-btn"
            >
              <span>Subscribe</span>
            </Link>
           </div>
        </div>
      </div>
    </>
  );
};

export default NoBillingExists;

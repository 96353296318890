import React from "react";
import { Image } from "react-bootstrap";

const NotChatFound = () => {
  return (
    <>
      {/* <div className="no-chat-found-card">
        <Image
          className="no-data-found"
          src={
            window.location.origin +
            "/images/no-data/no-chat-found.svg"
          }
        />
      </div> */}
      <div className="no-bot-exists-card no-chat-found-card">
        <div className="no-bot-exists-img-sec">
            <Image
            className="no-bot-exists-img"
            src={
                window.location.origin +
                "/images/no-data/no-chat-exists-img.svg"
            }
            />
        </div>
        <div className="no-bot-exists-info">
            <h4>No Chat Found !</h4>
        </div>
      </div>
    </>
  );
};

export default NotChatFound;

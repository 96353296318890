import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./OnBoarding.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loginStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import OnBoardingVerificationModal from "./OnboardingVerificationModal";
import NavLogo from "../Layout/Header/NavLogo";
import TFALoginModal from "./TFALoginModal";

const AIasLoginIndex = (props) => {
  const t = useTranslation("login");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state.admin.login);
  const emailVerify = useSelector(state => state.admin.emailVerify);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [twoStep, setTwoStep] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
  });

  const handleLogin = (values) => {
    dispatch(loginStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !login.loading &&
      Object.keys(login.data).length > 0
    ) {
      if (login.data.email_status) {
        if(login.data.onboarding_step === 6)
        {
          if(login.data.tfa_status==0)
          navigate("/")
          else
          setTwoStep(login.data)
        }
        else if(login.data.onboarding_step === 5)
          navigate("/onboarding-subscription-plans");
        else
          navigate("/onboarding-steps");
      } else {
        setShowVerify(login.data)
      }
    }
  }, [login]);

  useEffect(() => {
    if (
      !skipRender &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      navigate('/onboarding-steps')
    }
    setSkipRender(false);
  }, [emailVerify]);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-header-sec">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <div className="onboarding-onpremise-title-sec">
                    <h2>{t("heading")}</h2>
                    <p>
                    {t("message")}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Formik
              initialValues={{
                email: "",
                password: "",
                remember: false,
              }}
              validationSchema={loginSchema}
              onSubmit={handleLogin}
            >
              {({ setFieldValue, values }) => (
                <FORM noValidate className="onboarding-form">
                  <div className="onboarding-body-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            {t("email.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="email"
                            placeholder={t("email.placeholder")}
                            name="email"
                          /> 
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Label>
                        {t("password.label")} <span>*</span>
                        </Form.Label>
                        <div className="mb-3">
                          <InputGroup className="register-input-group">
                            <Field
                              type={showPassword.password ? "text" : "password"}
                              className="form-control"
                              placeholder={t("password.placeholder")}
                              name="password"
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  password: !showPassword.password,
                                })
                              }
                            >
                              <Image
                                className="register-input-group-eye-icon"
                                src={
                                  showPassword.password
                                    ? window.location.origin +
                                      "/images/onboarding/eye-visible-off-icon.svg"
                                    : window.location.origin +
                                      "/images/onboarding/eye-visible-on-icon.svg"
                                }
                              />
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="errorMsg"
                          />
                        </div>
                        <div className="onbaording-link-sec-1">
                          {/* Forgot Password? */}
                          <Link to="/forgot-password">{t("forgot_password")}</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="onboarding-footer-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <div className="ai-as-service-btn-sec">
                          <Button
                            className="onboarding-btn"
                            type="submit"
                            disabled={login.buttonDisable}
                          >
                            {login.buttonDisable ? t("login_btn.loading") : t("login_btn.text")}
                          </Button>
                        </div>
                        <div className="onbaording-link-sec">
                          {t("no_account")}
                          <Link to="/ai-as-service">{t("register")}</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </div>
        <div className="onboarding-right-sec">
          <div className="onboarding-installation-content">
            <div className="onboarding-ai-as-service-img-sec">
              <Image
                className="onboarding-ai-as-service-img"
                src={
                  window.location.origin +
                  "/images/onboarding/choose-solution/ai-as-register-img.png"
                }
              />
            </div>
            <h3>
              Unlock the full potential of our service-based solutions and
              experience the transformative capabilities of our
              industry-specific platform.
            </h3>
            <p>
              Request a demo today to see how our services can drive exceptional
              outcomes tailored to your unique needs.
            </p>
            <ul className="list-unstyled onboarding-installation-list">
              <li>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    fill="#F4B01D"
                  />
                  <path
                    d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                    fill="white"
                    stroke="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    stroke="#F4B01D"
                  />
                </svg>
                <span>
                  Witness seamless conversion of customer interactions between
                  channels while preserving data and context .
                </span>
              </li>
              <li>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    fill="#F4B01D"
                  />
                  <path
                    d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                    fill="white"
                    stroke="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    stroke="#F4B01D"
                  />
                </svg>
                <span>
                  Trust HaiVE's innovative and flexible platform designed
                  specifically for your industry
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showVerify ? (
        <OnBoardingVerificationModal
          showVerify={showVerify}
          closeVerify={() => setShowVerify(false)}
        />
      ) : null}
      {twoStep && 
        <TFALoginModal
          showVerify={twoStep}
          closeVerify={()=> setTwoStep(false)}
        />
      }
    </>
  );
};

export default AIasLoginIndex;

import React from "react";
import { Image, Button, Container, Row, Col } from "react-bootstrap";

const LiveChatDemoSteps = (props) => {
  return (
    <>

      <div className="customize-steps-sec">
        <Container>
        <Row>
            <Col md={12}>
              {/* <div className="back-option-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <linearGradient
                    id="a"
                    x1="12"
                    x2="12"
                    y1="2.25"
                    y2="21.75"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#32089f"></stop>
                    <stop offset="1" stopColor="#32089f"></stop>
                  </linearGradient>
                  <g fill="url(#a)" fillRule="evenodd" clipRule="evenodd">
                    <path d="M14.878 4.083a12.604 12.604 0 00-5.756 0 6.761 6.761 0 00-5.039 5.039 12.604 12.604 0 000 5.756 6.761 6.761 0 005.039 5.039c1.893.444 3.863.444 5.756 0a6.761 6.761 0 005.039-5.039 12.603 12.603 0 000-5.756 6.761 6.761 0 00-5.039-5.039zM8.78 2.623a14.103 14.103 0 016.442 0 8.261 8.261 0 016.156 6.156 14.104 14.104 0 010 6.442 8.261 8.261 0 01-6.156 6.156 14.104 14.104 0 01-6.442 0 8.261 8.261 0 01-6.156-6.156 14.104 14.104 0 010-6.442A8.261 8.261 0 018.78 2.623z"></path>
                    <path d="M11.53 15.03a.75.75 0 01-1.06 0l-2.5-2.5a.75.75 0 010-1.06l2.5-2.5a.75.75 0 111.06 1.06l-1.22 1.22h5.19a.75.75 0 010 1.5h-5.19l1.22 1.22a.75.75 0 010 1.06z"></path>
                  </g>
                </svg>
                <span>Back</span>
              </div> */}
              <h3>Steps to follows</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-list-sec livechat-demo">
                <p className="note">Go to the url <a href="https://platform.text.com/console/" target="_blank">(https://platform.text.com/console/)</a>. Login using your live chat credentials. 
                </p>
                <ul className="customize-steps-list list-unstyled">
                  <li>
                    <span>1</span>
                    After login, click on the profile icon and choose settings.  
                  </li>
                  <li>
                    <span>2</span>
                    Select Account & Copy the Organization ID. 
                  </li>
                  <li>
                    <span>3</span>
                     <div>
                     Go to the haive panel. Select your bot and fill the required fields.
                     <h6>
                        <em>Widget Type :</em>
                        LiveChat
                      </h6>
                      <h6>
                        <em>Live Chat Email :</em>
                        (Your registered live chat email).
                      </h6> 
                      <h6>
                        <em>Live Chat Organization ID :</em>
                        (The Organization ID Copied from Step 2).
                      </h6> 
                     </div>
                  </li>
                  <li>
                    <span>4</span>
                    Once you have updated the keys in HaiVE panel. Install app from livechat marketplace. 
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-btn-sec">
                <Button
                  className="settings-btn"
                  download={true}
                  target="_blank"
                  href={
                    window.location.origin +
                    "/installation-steps/LiveChat-Installation-Steps.docx"
                  }
                >
                  Download Now
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LiveChatDemoSteps;

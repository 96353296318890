import React, { useEffect, useState } from "react";
import { Image, Button, Row, Col } from "react-bootstrap";
// import "../Layout/NewHeader/NewUI.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddBotModal from "./AddBotModal";
import { botsListStart, botDeleteStart } from "../../store/slices/BotSlice";
import { selectedBotSuccess } from "../../store/slices/AdminSlice";
import Skeleton from "react-loading-skeleton";
import { confirmAlert } from "react-confirm-alert";
import useDesign from "../../hooks/useDesign";
import NoBotExists from "../Helper/NoBotExists";
import { useTranslation, withTranslation } from "react-multi-lang";
import { selecteInboxBot } from "../../store/slices/ChatSlice";

const YourBotIndex = (props) => {

  const t = useTranslation("bot_creation");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const botsList = useSelector((state) => state.bots.botsList);
  const botDelete = useSelector((state) => state.bots.botDelete);
  const [addBot, setAddBot] = useState(false);

  const { getChatTypeName } = useDesign();

  useEffect(() => {
    dispatch(botsListStart());
  }, []);

  const closeAddBotModal = () => {
    setAddBot(false);
  };

  const handleRedirect = (bot, url) => {
    dispatch(selectedBotSuccess(bot));
    dispatch(selecteInboxBot({
      label: bot.name,
      value: bot.bot_id,
    }));
    navigate(url);
  };

  const deleteBot = (bot_id) => {
    confirmAlert({
      title: "Do you really want to delete this bot?",
      message: "All your data wil be deleted and cannot be recovered.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            dispatch(botDeleteStart({ bot_id: bot_id }));
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            console.log("cancel");
          },
        },
      ],
    });
  };

  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="admin-ui-your-bot-list-sec">
          {botsList.loading ? (
            <div className="admin-your-bot-list-box">
              {[...Array(3)].map((i) => (
                <div className="onboarding-form">
                  <div className="onboarding-body-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <div className="mb-3">
                          <Skeleton count={1} height={100} />
                        </div>
                        <div className="mb-3">
                          <Skeleton count={1} height={10} />
                          <Skeleton count={1} height={10} />
                          <Skeleton count={1} height={10} />
                          <Skeleton count={1} height={10} />
                          <Skeleton count={1} height={10} />
                        </div>
                        <div className="mb-3">
                          <Skeleton count={1} height={50} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
            </div>
          ) : Object.keys(botsList.data).length > 0 &&
            botsList.data.bots.length > 0 ? (
            <>
              <div className="onboarding-fine-tunings-files-header-sec">
                <Button
                  className="onboarding-add-fine-tuning-btn"
                  onClick={() => setAddBot(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="#fff"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <path d="M480 224H288V32c0-17.673-14.327-32-32-32s-32 14.327-32 32v192H32c-17.673 0-32 14.327-32 32s14.327 32 32 32h192v192c0 17.673 14.327 32 32 32s32-14.327 32-32V288h192c17.673 0 32-14.327 32-32s-14.327-32-32-32z"></path>
                  </svg>
                  <span>{t("add_bot")}</span>
                </Button>
              </div>
              <div className="admin-your-bot-list-box">
                {botsList.data.bots.map((bot) => (
                  <div className="admin-your-bot-list-card">
                    <div className="admin-your-bot-list-header-sec">
                      <div className="admin-your-bot-list-header-left-sec">
                        <div className="admin-your-bot-list-user-img-sec bg-yellow">
                          <Image
                            className="admin-your-bot-list-user-img"
                            src={
                              window.location.origin +
                              "/images/your-bot/your-bot-img.svg"
                            }
                          />
                        </div>
                        <div className="admin-your-bot-list-user-info">
                          <h4>{bot.name}</h4>
                          <Link
                            to={bot.website}
                            target="_blank"
                            className="admin-your-bot-list-user-url-link"
                          >
                            {bot.website}
                          </Link>
                        </div>
                      </div>
                      {/* <div className="admin-your-bot-list-header-right-sec">
                                    <h6>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="#62CE6E"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                        >
                                        <path d="M12 0c-1.65 0-3 1.35-3 3v18c0 1.65 1.35 3 3 3s3-1.35 3-3V3c0-1.65-1.35-3-3-3zm1 21c0 .55-.45 1-1 1s-1-.45-1-1V3c0-.55.45-1 1-1s1 .45 1 1v18zm8-15c-1.65 0-3 1.35-3 3v12c0 1.65 1.35 3 3 3s3-1.35 3-3V9c0-1.65-1.35-3-3-3zm1 15c0 .55-.45 1-1 1s-1-.45-1-1V9c0-.55.45-1 1-1s1 .45 1 1v12zM3 12c-1.65 0-3 1.35-3 3v6c0 1.65 1.35 3 3 3s3-1.35 3-3v-6c0-1.65-1.35-3-3-3zm1 9c0 .55-.45 1-1 1s-1-.45-1-1v-6c0-.55.45-1 1-1s1 .45 1 1v6z"></path>
                                        </svg>
                                    </h6>
                                    <p>vs last 30 day</p>
                                </div> */}
                    </div>
                    <div className="admin-your-bot-list-body-sec">
                      {Object.keys(botsList.data.subscription).length > 0 && (
                        <>
                          <div className="admin-your-bot-list-item">
                            <div className="admin-your-bot-list-item-left-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#54659499"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                                <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                              </svg>
                              <span>Message</span>
                            </div>
                            <div className="admin-your-bot-list-item-right-sec">
                              <div className="admin-your-bot-list-item-message-count">
                                {bot.chat_messages_count} Used
                                {/* {botsList.data.subscription.total_tokens} */}
                              </div>
                            </div>
                          </div>
                          <div className="admin-your-bot-list-item">
                            <div className="admin-your-bot-list-item-left-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#54659499"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                              </svg>
                              <span>Custom Training</span>
                            </div>
                            <div className="admin-your-bot-list-item-right-sec">
                              <div className="admin-your-bot-list-item-message-count">
                                {bot.fine_tuning_models_count ?? 0} Used
                                {/* {botsList.data.subscription.total_fine_tunings} */}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="admin-your-bot-list-item">
                        <div className="admin-your-bot-list-item-left-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#54659499"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 1H5a5.006 5.006 0 00-5 5v12a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V6a5.006 5.006 0 00-5-5zM5 3h14a3 3 0 013 3v1H2V6a3 3 0 013-3zm14 18H5a3 3 0 01-3-3V9h20v9a3 3 0 01-3 3zm0-8a1 1 0 01-1 1H6a1 1 0 010-2h12a1 1 0 011 1zm-4 4a1 1 0 01-1 1H6a1 1 0 010-2h8a1 1 0 011 1zM3 5a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1z"></path>
                          </svg>
                          <span>Source</span>
                        </div>
                        <div className="admin-your-bot-list-item-right-sec">
                          <div className={getChatTypeName(bot.widget_type)}>
                            {bot.widget_type_formatted}
                          </div>
                        </div>
                      </div>
                      <div className="admin-your-bot-list-item">
                        <div className="admin-your-bot-list-item-left-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#54659499"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 2h-1V1a1 1 0 00-2 0v1H8V1a1 1 0 00-2 0v1H5a5.006 5.006 0 00-5 5v12a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V7a5.006 5.006 0 00-5-5zM2 7a3 3 0 013-3h14a3 3 0 013 3v1H2zm17 15H5a3 3 0 01-3-3v-9h20v9a3 3 0 01-3 3z"></path>
                            <circle cx="12" cy="15" r="1.5"></circle>
                            <circle cx="7" cy="15" r="1.5"></circle>
                            <circle cx="17" cy="15" r="1.5"></circle>
                          </svg>
                          <span>Created Date:</span>
                        </div>
                        <div className="admin-your-bot-list-item-right-sec">
                          <div className="admin-your-bot-list-item-created-date">
                            {bot.created_at}
                          </div>
                        </div>
                      </div>
                      <div className="admin-your-bot-list-item">
                        <div className="admin-your-bot-list-item-left-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#54659499"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 2h-1V1a1 1 0 10-2 0v1H8V1a1 1 0 10-2 0v1H5C2.243 2 0 4.243 0 7v12c0 2.757 2.243 5 5 5h4a1 1 0 100-2H5c-1.654 0-3-1.346-3-3v-9h21a1 1 0 001-1V7c0-2.757-2.243-5-5-5zm3 6H2V7c0-1.654 1.346-3 3-3h14c1.654 0 3 1.346 3 3v1zm-3.121 4.879l-5.707 5.707A3.971 3.971 0 0012 21.414V23a1 1 0 001 1h1.586a3.975 3.975 0 002.828-1.172l5.707-5.707c.566-.567.879-1.32.879-2.122s-.313-1.555-.879-2.121a3.002 3.002 0 00-4.242 0zm2.828 2.828L16 21.414c-.378.378-.88.586-1.414.586H14v-.586c0-.526.214-1.042.586-1.414l5.707-5.707a1 1 0 011.414 1.414z"></path>
                          </svg>
                          <span>Last Edited:</span>
                        </div>
                        <div className="admin-your-bot-list-item-right-sec">
                          <div className="admin-your-bot-list-item-created-date">
                            {bot.updated_at}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="admin-your-bot-list-footer-sec">
                      <div className="admin-your-bot-list-btn-sec">
                        <Button
                          className="admin-your-bot-list-btn"
                          onClick={() => deleteBot(bot.bot_id)}
                          disabled={botDelete.buttonDisable}
                        >
                          {botDelete.buttonDisable &&
                          botDelete.data.bot_id === bot.bot_id
                            ? t("loading")
                            : t("delete")}
                        </Button>
                        <Button className="admin-your-bot-list-btn" onClick={() => handleRedirect(bot, "/settings?tab=fine-tune")}>
                        {t("train")}
                        </Button>
                        <Button
                          className="admin-your-bot-list-btn"
                          onClick={() => handleRedirect(bot, "/settings")}
                        >
                          {t("edit")}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <NoBotExists setAddBot={setAddBot} />
          )}
        </div>
      </div>
      {addBot && (
        <AddBotModal
          addBot={addBot}
          closeAddBotModal={closeAddBotModal}
          setAddBot={setAddBot}
        />
      )}
    </>
  );
};

export default withTranslation(YourBotIndex);

import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import { faqSaveStart } from "../../../store/slices/FaqSlice";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import ImportFaq from "./ImportFaq";

const AddFaqModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("settings.fine_tune.add_faq");
  const faqSave = useSelector((state) => state.faq.faqSave);
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    question: Yup.string().required(t("question.required")).trim().notOneOf(['']),
    response: Yup.string().required(t("response.required")).trim().notOneOf(['']),
  });

  const handleFaqStore = (values) => {
    let newValues = values;
    if(props.faq && Object.keys(props.faq).length>0)
    {
      if(props.faq.chat_message_faq_id){
        newValues = {...newValues,
          chat_message_faq_id: props.faq.chat_message_faq_id
        }
      }
      else if (props.faq.preview_chat_message_id){
        newValues = {...newValues, 
          preview_chat_message_id: props.faq.preview_chat_message_id
        }
      }
    }
    dispatch(faqSaveStart(newValues));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !faqSave.loding &&
      Object.keys(faqSave.data).length > 0
    ) {
      props.closeAddFaqModal();
    }
    setSkipRender(false);
  }, [faqSave]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.addFaq}
        onHide={props.closeAddFaqModal}
      >
        <Modal.Body>
          <h4>{props.type && props.type === 2 ? t("edit") : t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeAddFaqModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M16.061 10.061L14.122 12l1.939 1.939a1.5 1.5 0 11-2.122 2.121L12 14.121l-1.939 1.939c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121L9.878 12l-1.939-1.939A1.5 1.5 0 1110.06 7.94l1.939 1.939 1.939-1.939a1.5 1.5 0 112.121 2.121zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-3 0c0-4.963-4.037-9-9-9s-9 4.037-9 9 4.038 9 9 9 9-4.037 9-9z"></path>
            </svg>
          </Button>
          <Formik
            initialValues={{
              question: props.faq ? props.faq.question : "",
              response: props.faq ? props.faq.answer : "",
              status: props.faq ? props.faq.status : 3,
              bot_id: selectedBot.bot_id ?? 0,
            }}
            validationSchema={validationSchema}
            onSubmit={handleFaqStore}
          >
            {({ setFieldValue, values }) => (
              <FORM noValidate className="onboarding-form">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>{t("question.label")}</Form.Label>
                  <Field
                    className="form-control"
                    as="textarea"
                    rows={3}
                    placeholder={t("question.placeholder")}
                    name="question"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="question"
                    className="errorMsg"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>{t("response.label")}</Form.Label>
                  <Field
                    className="form-control"
                    as="textarea"
                    rows={3}
                    placeholder={t("response.placeholder")}
                    name="response"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="response"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="onboarding-import-webpage-modal-btn-sec">
                  {/* <Button
                    className="onboarding-cancel-btn"
                    onClick={() => props.closeAddFaqModal()}
                  >
                    {t("cancel")}
                  </Button> */}
                  <Button
                    className="onboarding-save-btn"
                    type="submit"
                    disabled={faqSave.buttonDisable}
                  >
                    {faqSave.buttonDisable ? t("loading") : t("add")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
          <ImportFaq
            closeAddFaqModal={props.closeAddFaqModal}
            botId={selectedBot.bot_id}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddFaqModal;

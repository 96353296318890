import React from "react";
import Skeleton from "react-loading-skeleton";

const ChatPreviewLoader = (props) => {
  return (
    <>
      <Skeleton count={1} height={70} />
      <div className="new-chat-preview-card-left-body-sec">
        <div className="new-chat-preview-sticky-date-time">
          <Skeleton count={1} height={30} width={60} />
          <div className="new-chat-preview-user-receiver-card mt-5">
            <div className="new-chat-preview-user-receiver-icon-sec">
              <Skeleton count={1} height={30} width={30} circle={true} />
            </div>
            <div className="new-chat-preview-user-receiver-message-card">
              <div className="new-chat-preview-user-receiver-message">
                <Skeleton count={1} height={50} width={200} />
              </div>
            </div>
          </div>
          <div className="new-chat-preview-user-sender-card mt-5">
            <div className="new-chat-preview-user-sender-icon-sec">
              <Skeleton count={1} height={30} width={30} circle={true} />
            </div>
            <div className="new-chat-preview-user-sender-message-card">
              <div className="new-chat-preview-user-sender-message">
                <Skeleton count={1} height={50} width={200} />
              </div>
            </div>
          </div>
        </div>
        <div className="new-chat-preview-sticky-date-time mt-5">
          <Skeleton count={1} height={30} width={60} />
          <div className="new-chat-preview-user-receiver-card mt-5">
            <div className="new-chat-preview-user-receiver-icon-sec">
              <Skeleton count={1} height={30} width={30} circle={true} />
            </div>
            <div className="new-chat-preview-user-receiver-message-card">
              <div className="new-chat-preview-user-receiver-message">
                <Skeleton count={1} height={50} width={200} />
              </div>
            </div>
          </div>
          <div className="new-chat-preview-user-sender-card mt-5">
            <div className="new-chat-preview-user-sender-icon-sec">
              <Skeleton count={1} height={30} width={30} circle={true} />
            </div>
            <div className="new-chat-preview-user-sender-message-card">
              <div className="new-chat-preview-user-sender-message">
                <Skeleton count={1} height={50} width={200} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="new-chat-preview-card-left-footer-form">
        <Skeleton count={1} height={120} />
      </div>
    </>
  );
};

export default ChatPreviewLoader;

import React from "react";
import { Image, Button, Container, Row, Col } from "react-bootstrap";

const FreshChatDemoSteps = (props) => {
  return (
    <>

      <div className="customize-steps-sec">
        <Container>
          <Row>
            <Col md={12}>
              {/* <div className="back-option-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <linearGradient
                    id="a"
                    x1="12"
                    x2="12"
                    y1="2.25"
                    y2="21.75"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#32089f"></stop>
                    <stop offset="1" stopColor="#32089f"></stop>
                  </linearGradient>
                  <g fill="url(#a)" fillRule="evenodd" clipRule="evenodd">
                    <path d="M14.878 4.083a12.604 12.604 0 00-5.756 0 6.761 6.761 0 00-5.039 5.039 12.604 12.604 0 000 5.756 6.761 6.761 0 005.039 5.039c1.893.444 3.863.444 5.756 0a6.761 6.761 0 005.039-5.039 12.603 12.603 0 000-5.756 6.761 6.761 0 00-5.039-5.039zM8.78 2.623a14.103 14.103 0 016.442 0 8.261 8.261 0 016.156 6.156 14.104 14.104 0 010 6.442 8.261 8.261 0 01-6.156 6.156 14.104 14.104 0 01-6.442 0 8.261 8.261 0 01-6.156-6.156 14.104 14.104 0 010-6.442A8.261 8.261 0 018.78 2.623z"></path>
                    <path d="M11.53 15.03a.75.75 0 01-1.06 0l-2.5-2.5a.75.75 0 010-1.06l2.5-2.5a.75.75 0 111.06 1.06l-1.22 1.22h5.19a.75.75 0 010 1.5h-5.19l1.22 1.22a.75.75 0 010 1.06z"></path>
                  </g>
                </svg>
                <span>Back</span>
              </div> */}
              <h3>Steps to follows</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-list-sec">
                <ul className="customize-steps-list list-unstyled">
                  <li>
                    <span>1</span>
                    Create AI Agent from user panel. Setup Source. Copy the API KEY.
                  </li>
                  <li>
                    <span>2</span>
                    Login to your freshchat account. Click on crm/sales
                  </li>
                  <li>
                    <span>3</span>
                    Setings {'>'} Click On Admin Settings
                  </li>
                  <li>
                    <span>4</span>
                      Search for “api” and choose “API Settings”
                  </li>
                  <li>
                    <span>5</span>
                    <div>
                      In the API DETAILS FOR CHAT section, Copy the “Your API Key” & “Your chat URL”
                      <h6>
                        <em>Note:</em>
                        If the API key (access token) is unavailable or visiting the page for first time, click Generate token displayed adjacent to the Your API Key field.
                      </h6>
                    </div>
                  </li>
                  <li>
                    <span>6</span>
                    While installing the app. Configure the Copied Keys.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-key-list-note-sec">
                <div className="customize-steps-key-list-note-box">
                  <div className="customize-steps-key-list-note-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 15 20"
                    >
                      <path
                        stroke="#32089F"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="22.926"
                        d="M3.978 10l-2.9-7.202A1.055 1.055 0 011.65 1.43c.331-.136.692-.087.968.099l11.276 7.596a1.06 1.06 0 01-.016 1.762L2.62 18.471a1.035 1.035 0 01-1.444-.291 1.061 1.061 0 01-.077-1.029L3.978 10z"
                        clipRule="evenodd"
                      ></path>
                      <path
                        fill="#F9AF1A"
                        fillRule="evenodd"
                        d="M4.328 14.777L11.42 10 4.328 5.222 6.253 10l-1.925 4.777z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <p>
                      <span>Domain : </span>
                      (Domain Value copied from the Step 5). 
                    </p>
                  </div>
                  <div className="customize-steps-key-list-note-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 15 20"
                    >
                      <path
                        stroke="#32089F"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="22.926"
                        d="M3.978 10l-2.9-7.202A1.055 1.055 0 011.65 1.43c.331-.136.692-.087.968.099l11.276 7.596a1.06 1.06 0 01-.016 1.762L2.62 18.471a1.035 1.035 0 01-1.444-.291 1.061 1.061 0 01-.077-1.029L3.978 10z"
                        clipRule="evenodd"
                      ></path>
                      <path
                        fill="#F9AF1A"
                        fillRule="evenodd"
                        d="M4.328 14.777L11.42 10 4.328 5.222 6.253 10l-1.925 4.777z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <p>
                      <span>API Key : </span>
                      (API Key Value copied from Step 5). 
                    </p>
                  </div>
                  <div className="customize-steps-key-list-note-card">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 15 20"
                    >
                      <path
                        stroke="#32089F"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="22.926"
                        d="M3.978 10l-2.9-7.202A1.055 1.055 0 011.65 1.43c.331-.136.692-.087.968.099l11.276 7.596a1.06 1.06 0 01-.016 1.762L2.62 18.471a1.035 1.035 0 01-1.444-.291 1.061 1.061 0 01-.077-1.029L3.978 10z"
                        clipRule="evenodd"
                      ></path>
                      <path
                        fill="#F9AF1A"
                        fillRule="evenodd"
                        d="M4.328 14.777L11.42 10 4.328 5.222 6.253 10l-1.925 4.777z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <p>
                      <span>HaiVE API Key : </span>
                      (API Key copied from Step 1).
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="customize-steps-btn-sec">
                <a
                  className="settings-btn"
                  download={true}
                  target="_blank"
                  href={
                    window.location.origin +
                    "/installation-steps/FreshChat-Installation-Steps.docx"
                  }
                >
                  Download Now
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FreshChatDemoSteps;

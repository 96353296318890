import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import "./OnBoarding.css";
import AIasRegisterIndex from "./AIasRegisterIndex";
import ChooseSolutionIndex from "./ChooseSolutionIndex";
import BotCreationIndex from "./BotCreationIndex";
import FineTuningIndex from "./FineTuningIndex";
import FineTuningsFilesIndex from "./FineTuningsFilesIndex";
import OnBoardingSubscriptionPayment from "./OnBoardingSubscriptionPayment";
import { useDispatch, useSelector } from "react-redux";
import {
  onboardingCreatedbotStart,
} from "../../store/slices/OnboardingSlice";
import { useNavigate } from "react-router-dom";

const OnBoardingSteps = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const profile = useSelector((state) => state.admin.profile);
  const onboardingbot = useSelector((state) => state.onboarding.onboardingbot);

  const [botType, setBotType] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.onboarding_step == 2) {
        setStep(1);
      } else if(profile.data.onboarding_step == 3) {
        setStep(2);
      } else if(profile.data.onboarding_step == 4) {
        setStep(3);
      } else if(profile.data.onboarding_step >= 6) {
        navigate("/");
      } else {
        setStep(4);
      }
      dispatch(onboardingCreatedbotStart());
    }
  }, [profile]);

  useEffect(() => {
    if (
      !skipRender &&
      !onboardingbot.loading &&
      Object.keys(onboardingbot.data).length > 0
    ) {
      setBotType(onboardingbot.data.widget_type);
    }
    setSkipRender(false);
  }, [onboardingbot]);

  return (
    <>
      <div className="onboarding-sec">
        {step == 1 && (
          <div className="step-2">
            <ChooseSolutionIndex setStep={setStep} botType={botType} setBotType={setBotType} />
          </div>
        )}
        {step == 2 && (
          <div className="step-3">
            <BotCreationIndex setStep={setStep} botType={botType} />
          </div>
        )}
        {step == 3 && (
          <div className="step-4">
            <FineTuningIndex step={step} setStep={setStep} />
          </div>
        )}
        {step == 4 && (
          <div className="step-5">
            <FineTuningsFilesIndex step={step} setStep={setStep} />
          </div>
        )}
      </div>
    </>
  );
};

export default OnBoardingSteps;

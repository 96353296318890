import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  Button,
  Tab,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  currrentSubscriptionPlanStart,
  generateStripeIntentStart,
  subscriptionPlansStart,
} from "../../store/slices/SubscriptionSlice";
import { withTranslation, useTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import EditAddressModal from "./EditAddressModal";
import UpcomingBills from "../Dashboard/UpcomingBills";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import SubscriptionCard from "../Dashboard/SubscriptionCard";

const SubscriptionIndex = (props) => {
  
  const t = useTranslation("subscriptions");
  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);
  const navigate = useNavigate();
  const [editAddress, setEditAddress] = useState(false);

  const closeEditAddressModal = () => {
    setEditAddress(false);
  };

  const subscriptionPlans = useSelector(
    (state) => state.subscription.subscriptionPlans
  );
  const generateStripeIntent = useSelector(
    (state) => state.subscription.generateStripeIntent
  );
  const currentSubscription = useSelector(
    (state) => state.subscription.currentSubscription
  );

  useEffect(() => {
    dispatch(subscriptionPlansStart());
    dispatch(currrentSubscriptionPlanStart());
  }, []);

  const handleStripeIntent = (plan, type) => {
    if (plan) {
      dispatch(
        generateStripeIntentStart({
          type: type == "monthly" ? 1 : 2,
          subscription_plan_unique_id: plan.subscription_plan_unique_id,
          subscription_plan: plan,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !generateStripeIntent.loading &&
      Object.keys(generateStripeIntent.data).length > 0
    ) {
      localStorage.setItem("payment_page","subscription");
      if (generateStripeIntent.data.is_needs_to_pay) {
        navigate("/subscription-payment");
      } else {
        getSuccessNotificationMessage(
          "You have subscribed to free subscription successfully"
        );
        navigate("/billings");
      }
    }
    setSkipRender(false);
  }, [generateStripeIntent]);

  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="my-subscription-sec">
          {currentSubscription.loading ? (
            <div className="onboarding-form">
              <div className="onboarding-body-sec">
                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <div className="mb-3 flex flex-row justfiy-between">
                      <Row>
                        <Col md={8}>
                          <Skeleton count={1} height={10} width={200} />
                          <Skeleton count={1} height={10} width={250} />
                        </Col>
                        <Col md={2}>
                          <Skeleton count={1} height={30} width={100} />
                        </Col>
                        <Col md={2}>
                          <Skeleton count={1} height={30} width={100} />
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      {[...Array(5)].map((i) => (
                        <Col>
                          <Skeleton count={1} height={60} width={150} />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          ) : Object.keys(currentSubscription.data).length > 0 &&
            Object.keys(currentSubscription.data.current_plan).length > 0 ? (
            <div className="my-subscription-header-sec">
              <div className="my-subscription-header-box">
                <div className="my-subscription-header-info">
                  <div className="my-subscription-header-info-left-sec">
                    <h3>{t("current.heading")} ({currentSubscription.data.current_plan.plan_name})</h3>
                    <p>{t("current.message")}</p>
                  </div>
                  <div className="my-subscription-header-info-right-sec">
                    <div className="my-subscription-header-action-btn-sec">
                      <Link to="https://www.haive.tech/#contact" target="_blank" className="contact-us-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 21 22"
                        >
                          <path
                            stroke="#32089F"
                            strokeWidth="1.7"
                            d="M17.354 13.263a2.453 2.453 0 00-3.39.005l-1.509 1.271c-2.532-1.074-4.334-2.88-5.488-5.498l1.268-1.502a2.453 2.453 0 00.004-3.388s-1.466-1.905-1.49-1.93c-.955-.956-2.512-.955-3.426-.037l-.91.794C1.502 3.888 1 5.118 1 6.442 1 12.485 9.017 20.5 15.062 20.5a4.871 4.871 0 003.5-1.45l.723-.832a2.454 2.454 0 000-3.463c-.024-.025-1.93-1.49-1.93-1.49l-.002-.002z"
                          ></path>
                        </svg>
                        {t("contact_us")}
                      </Link>
                      <Link to="/billings" className="billing-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#fff"
                          viewBox="0 0 24 24"
                        >
                          <path d="M16 0H8a5.006 5.006 0 00-5 5v18a1 1 0 001.564.825l2.106-1.439 2.106 1.439a1 1 0 001.13 0l2.1-1.439 2.1 1.439a1 1 0 001.131 0l2.1-1.438 2.1 1.437A1 1 0 0021 23V5a5.006 5.006 0 00-5-5zm3 21.1l-1.1-.752a1 1 0 00-1.132 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.131 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.129 0L5 21.1V5a3 3 0 013-3h8a3 3 0 013 3z"></path>
                          <rect width="10" height="2" x="7" y="8" rx="1"></rect>
                          <rect width="8" height="2" x="7" y="12" rx="1"></rect>
                        </svg>
                        {t("billing")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="my-subscription-body-info">
                  <div className="my-subscription-body-info-list-box">
                    <div className="my-subscription-body-info-list-card">
                      <div className="my-subscription-body-info-list-icon-sec">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#00195E"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.836.029a12.043 12.043 0 00-9.327 3.492 12.027 12.027 0 00-3.48 9.333C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.862 19.097.454 12.836.029zM22 19c0 1.654-1.346 3-3 3h-5.917c-6.034 0-10.686-3.905-11.058-9.285a10.027 10.027 0 012.898-7.782 10.019 10.019 0 017.778-2.91C17.915 2.377 22 6.909 22 12.339v6.66zm-6-5c0 1.654-1.346 3-3 3v1a1 1 0 11-2 0v-1h-.268a3.01 3.01 0 01-2.598-1.499 1 1 0 111.73-1.001c.179.308.511.5.867.5h2.268c.552 0 1-.449 1-1a.768.768 0 00-.644-.76l-3.041-.507A2.761 2.761 0 017.999 10c0-1.654 1.346-3 3-3V6a1 1 0 112 0v1h.268c1.067 0 2.063.575 2.598 1.5a1 1 0 11-1.73 1.001 1.004 1.004 0 00-.867-.5H11c-.552 0-1 .449-1 1 0 .378.271.698.644.76l3.041.507A2.761 2.761 0 0116 14.001z"></path>
                        </svg>
                      </div>
                      <div className="my-subscription-body-info-list-info-sec">
                        <p>{t("current.subscription")}</p>
                        <h4>
                          {currentSubscription.data.current_plan.amount}{" "}
                          {currentSubscription.data.current_plan.type_formatted}
                        </h4>{" "}
                      </div>
                    </div>
                    <div className="my-subscription-body-info-list-card">
                      <div className="my-subscription-body-info-list-icon-sec">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#00195E"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M21 23a1 1 0 11-2 0c0-2.206-1.794-4-4-4H9c-2.206 0-4 1.794-4 4a1 1 0 11-2 0c0-3.309 2.691-6 6-6h6c3.309 0 6 2.691 6 6zm1-15.5v2c0 .827-.673 1.5-1.5 1.5H20c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4h-.5C2.673 11 2 10.327 2 9.5v-2C2 6.673 2.673 6 3.5 6H4c0-2.206 1.794-4 4-4h3V1a1 1 0 112 0v1h3c2.206 0 4 1.794 4 4h.5c.827 0 1.5.673 1.5 1.5zM18 6c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2v5c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6zM9.5 7a1.5 1.5 0 10.001 3.001A1.5 1.5 0 009.5 7zm5 0a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0014.5 7z"></path>
                        </svg>
                      </div>
                      <div className="my-subscription-body-info-list-info-sec">
                        <p>{t("current.bots")}</p>
                        <h4>
                          {currentSubscription.data.bots.used}/{" "}
                          {currentSubscription.data.bots.total}
                        </h4>
                      </div>
                    </div>
                    <div className="my-subscription-body-info-list-card">
                      <div className="my-subscription-body-info-list-icon-sec">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#00195E"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                          <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                        </svg>
                      </div>
                      <div className="my-subscription-body-info-list-info-sec">
                        <p>{t("current.messages")}</p>
                        <h4>
                          {currentSubscription.data.current_plan.total_tokens -
                            currentSubscription.data.current_plan
                              .remaining_tokens}
                          / {currentSubscription.data.current_plan.total_tokens}
                        </h4>{" "}
                      </div>
                    </div>
                    <div className="my-subscription-body-info-list-card">
                      <div className="my-subscription-body-info-list-icon-sec">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#00195E"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                        </svg>
                      </div>
                      <div className="my-subscription-body-info-list-info-sec">
                        <p>{t("current.fine_tune")}</p>
                        <h4>
                          {currentSubscription.data.fine_tunings.used}/{" "}
                          {currentSubscription.data.fine_tunings.total}
                        </h4>
                      </div>
                    </div>
                    <div className="my-subscription-body-info-list-card">
                      <div className="my-subscription-body-info-list-icon-sec">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#00195E"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19 2h-1V1a1 1 0 00-2 0v1H8V1a1 1 0 00-2 0v1H5C2.243 2 0 4.243 0 7v12c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5V7c0-2.757-2.243-5-5-5zM5 4h14c1.654 0 3 1.346 3 3v1H2V7c0-1.654 1.346-3 3-3zm14 18H5c-1.654 0-3-1.346-3-3v-9h20v9c0 1.654-1.346 3-3 3zm-3-6a1 1 0 01-1 1H9a1 1 0 010-2h6a1 1 0 011 1z"></path>
                        </svg>
                      </div>
                      <div className="my-subscription-body-info-list-info-sec">
                        <p>{t("current.expiry")}</p>
                        <h4>
                          {currentSubscription.data.current_plan.expiry_date}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="my-subscription-body-sec">
            <Tab.Container id="left-tabs-example" defaultActiveKey="monthly">
              <Row>
                <Col sm={12}>
                  <div className="my-subscription-upgrade-tab-header-sec">
                    <div className="my-subscription-upgrade-tab-header-info-left-sec">
                      <h3>{t("upgrade.heading")}</h3>
                      <p>{t("upgrade.message")}</p>
                      <div className="my-subscription-upgrade-tab-nav-link-sec mt-3">
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey="monthly">{t("monthly")}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="yearly">
                              <h4>{t("yearly")}</h4>
                              {/* <p>Save 20%</p> */}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                    </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="my-subscription-upgrade-tab-content-sec">
                    <Tab.Content>
                      <Tab.Pane eventKey="monthly">
                        <div className="my-subscription-upgrade-tab-content">
                          <Row>
                            <Col md={12} lg={12} xl={8}>
                              <div className="onboarding-subscription-plans-box">
                                {subscriptionPlans.loading ? (
                                  [...Array(3)].map((i) => (
                                    <div className="onboarding-form">
                                      <div className="onboarding-body-sec">
                                        <Row className="justify-content-md-center">
                                          <Col md={12}>
                                            <div className="mb-3">
                                              <Skeleton count={1} height={30} />
                                            </div>
                                            <div className="mb-3">
                                              <Skeleton
                                                count={1}
                                                height={20}
                                                width={100}
                                              />
                                              <Skeleton count={1} height={40} />
                                            </div>
                                            <div className="mb-3">
                                              <Skeleton
                                                count={1}
                                                height={150}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  ))
                                ) : Object.keys(subscriptionPlans.data).length >
                                    0 &&
                                  subscriptionPlans.data.subscription_plans
                                    .length > 0 ? (
                                  subscriptionPlans.data.subscription_plans.map(
                                    (plan) => (
                                      <>
                                        <SubscriptionCard
                                          plan={plan}
                                          handleStripeIntent={
                                            handleStripeIntent
                                          }
                                          type="monthly"
                                        />
                                      </>
                                    )
                                  )
                                ) : (
                                  <NoDataFound />
                                )}
                              </div>
                            </Col>
                            <Col md={12} lg={12} xl={4}>
                              {currentSubscription.loading ? (
                                <div className="onboarding-form">
                                  <div className="onboarding-body-sec">
                                    <Row className="justify-content-md-center">
                                      <Col md={10}>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={40} />
                                        </div>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={40} />
                                        </div>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={150} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              ) : Object.keys(currentSubscription.data).length >
                                  0 &&
                                Object.keys(
                                  currentSubscription.data.current_plan
                                ).length > 0 ? (
                                <UpcomingBills
                                  data={currentSubscription.data}
                                  setEditAddress={setEditAddress}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="yearly">
                        <div className="my-subscription-upgrade-tab-content">
                          <Row>
                            <Col md={8}>
                              <div className="onboarding-subscription-plans-box">
                                {subscriptionPlans.loading ? (
                                  <div className="onboarding-form">
                                    <div className="onboarding-body-sec">
                                      <Row className="justify-content-md-center">
                                        <Col md={10}>
                                          <div className="mb-3">
                                            <Skeleton
                                              count={1}
                                              height={10}
                                              width={100}
                                            />
                                            <Skeleton count={1} height={40} />
                                          </div>
                                          <div className="mb-3">
                                            <Skeleton
                                              count={1}
                                              height={10}
                                              width={100}
                                            />
                                            <Skeleton count={1} height={40} />
                                          </div>
                                          <div className="mb-3">
                                            <Skeleton
                                              count={1}
                                              height={10}
                                              width={100}
                                            />
                                            <Skeleton count={1} height={150} />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                ) : Object.keys(subscriptionPlans.data).length >
                                    0 &&
                                  subscriptionPlans.data.subscription_plans
                                    .length > 0 ? (
                                  subscriptionPlans.data.subscription_plans.map(
                                    (plan) => (
                                      <>
                                        <SubscriptionCard
                                          plan={plan}
                                          handleStripeIntent={
                                            handleStripeIntent
                                          }
                                          type="yearly"
                                        />
                                      </>
                                    )
                                  )
                                ) : (
                                  <NoDataFound />
                                )}
                              </div>
                            </Col>
                            <Col md={4}>
                              {currentSubscription.loading ? (
                                <div className="onboarding-form">
                                  <div className="onboarding-body-sec">
                                    <Row className="justify-content-md-center">
                                      <Col md={10}>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={40} />
                                        </div>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={40} />
                                        </div>
                                        <div className="mb-3">
                                          <Skeleton
                                            count={1}
                                            height={10}
                                            width={100}
                                          />
                                          <Skeleton count={1} height={150} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              ) : Object.keys(currentSubscription.data).length >
                                  0 &&
                                Object.keys(
                                  currentSubscription.data.current_plan
                                ).length > 0 ? (
                                <UpcomingBills
                                  data={currentSubscription.data}
                                  setEditAddress={setEditAddress}
                                />
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
      {editAddress && (
        <EditAddressModal
          address={currentSubscription.data.user_billing_address}
          addBot={editAddress}
          closeAddBotModal={closeEditAddressModal}
          setAddBot={setEditAddress}
        />
      )}
    </>
  );
};

export default withTranslation(SubscriptionIndex);

import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  faqList: getCommonInitialState(),
  faqSave: getCommonInitialState(),
  faqDelete: getCommonInitialState(),
  faqImport: getCommonInitialState(),
  faqTrain: getCommonInitialState(),
  faqLikeUnlikeList: {
    liked: [],
    unliked: [],
  },
};

const FaqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {

    //Fine Tune list
    faqListStart: (state, action) => { state.faqList = getCommonStart() },
    faqListSuccess: (state, action) => { state.faqList = getCommonSuccess(action.payload) },
    faqListFailure: (state, action) => { state.faqList = getCommonFailure(action.payload) },
    moreFaqListStart: (state) => { state.faqList = state.faqList },

    //Fine Tune Save
    faqSaveStart: (state, action) => { state.faqSave = getCommonStart() },
    faqSaveSuccess: (state, action) => { state.faqSave = getCommonSuccess(action.payload) },
    faqSaveFailure: (state, action) => { state.faqSave = getCommonFailure(action.payload) },

    //Fine Tune 
    faqDeleteStart: (state, action) => { state.faqDelete = getCommonStart(action.payload) },
    faqDeleteSuccess: (state, action) => { state.faqDelete = getCommonSuccess(action.payload) },
    faqDeleteFailure: (state, action) => { state.faqDelete = getCommonFailure(action.payload) },

    //Faq Import 
    faqImportStart: (state, action) => { state.faqImport = getCommonStart() },
    faqImportSuccess: (state, action) => { state.faqImport = getCommonSuccess(action.payload) },
    faqImportFailure: (state, action) => { state.faqImport = getCommonFailure(action.payload) },

    //Faq Train
    faqTrainStart: (state, action) => { state.faqTrain = getCommonStart() },
    faqTrainSuccess: (state, action) => { state.faqTrain = getCommonSuccess(action.payload) },
    faqTrainFailure: (state, action) => { state.faqTrain = getCommonFailure(action.payload) },

    setShowAddModal: (state, action) => { state.showAddModal = action.payload },

    // Like Unlike
    faqLikeUnlikeList: (state, { payload }) => {
      if (payload.chat_message_faq.status == 1)
        state.faqLikeUnlikeList.unliked.push(payload.chat_message_faq.preview_chat_message_id);
      if (payload.chat_message_faq.status == 2)
        state.faqLikeUnlikeList.liked.push(payload.chat_message_faq.preview_chat_message_id);
    },
    // faqLikeUnlikeListSuccess: (state, action) => { state.faqLikeUnlikeList = getCommonSuccess(action.payload) }

  }
});


export const {
  faqListStart,
  faqListSuccess,
  faqListFailure,
  moreFaqListStart,
  faqSaveStart,
  faqSaveSuccess,
  faqSaveFailure,
  faqDeleteStart,
  faqDeleteSuccess,
  faqDeleteFailure,
  faqImportFailure,
  faqImportStart,
  faqImportSuccess,
  faqTrainFailure,
  faqTrainStart,
  faqTrainSuccess,
  faqLikeUnlikeList,

} = FaqSlice.actions;

export default FaqSlice.reducer;
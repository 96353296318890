import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  chatListFailure,
  chatListSuccess,
  sendMessageFailure,
  sendMessageSuccess,
  sendPreviewMessageFailure,
  sendPreviewMessageSuccess,
  previewChatListSuccess,
  previewChatMessagesSuccess,
  previewChatMessagesFailure
} from "../slices/ChatSlice";


function* chatListAPI(action) {
  yield getCommonSaga({
    apiUrl: "chats",
    payload: action.payload,
    successNotify: false,
    success: chatListSuccess,
    failure: chatListFailure,
  });
}

function* sendPreviewMessageAPI(action) {
  const date = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  let previewChats = yield select(state => state.chat.previewChats.data);
  const question = {question: action.payload.question, sent_by: "ai", send_at: date}
  if (Object.keys(previewChats).length > 0) {
    store.dispatch(previewChatListSuccess({
      ...previewChats,
      messages: [...previewChats.messages, question]
    }));
  } else {
    store.dispatch(previewChatListSuccess({
      messages: [question],
    }));
  }
  previewChats = yield select(state => state.chat.previewChats.data);
  yield getCommonSaga({
    apiUrl: "hooks/generate_response",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(sendPreviewMessageSuccess(data));
      if (Object.keys(data).length > 0) {
        let message = {...data, sent_by: "user"}
        if (Object.keys(previewChats).length > 0 && Object.keys(data).length > 0) {
          store.dispatch(previewChatListSuccess({
            ...previewChats,
            messages: [...previewChats.messages, message]
          }));
        } else {
          store.dispatch(previewChatListSuccess({
            messages: [message],
          }));
        }
      }
    },
    failure: sendPreviewMessageFailure,
  });
}

function* previewChatMessagesAPI(action) {
  yield getCommonSaga({
    apiUrl: "preview_chat_messages",
    payload: action.payload,
    successNotify: false,
    success: previewChatMessagesSuccess,
    failure: previewChatMessagesFailure,
  });
}


export default function* ChatSaga() {
  yield all([
    yield takeLatest('chat/chatListStart', chatListAPI),
    yield takeLatest('chat/sendPreviewMessageStart', sendPreviewMessageAPI),
    yield takeLatest('chat/previewChatMessagesStart', previewChatMessagesAPI),
  ]);
}


import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Dropdown,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import AddFaqModal from "../Faqs/AddFaqModal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import ImportWebpageModal from "../../OnBoarding/ImportWebpageModal";
import ImportDocumentModal from "../../OnBoarding/ImportDocumentModal";
import Skeleton from "react-loading-skeleton";
import FaqIndex from "../Faqs/FaqIndex";
import FinetuneIndex from "./FinetuneIndex";
import ConversationsIndex from "../ConversationData/ConversationsIndex"
import ImportConversationsModal from "../ConversationData/ImportConversationsModal"
import CustomTrainingIndex from "./CustomTrainingIndex";

const SettingsfineTune = (props) => {

  const t = useTranslation("settings.fine_tune");
  const [addFaq, setAddFaq] = useState(false);
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const fineTuneList = useSelector((state) => state.fineTune.fineTuneList);
  const customTrainingList = useSelector((state) => state.fineTune.customTrainingList);
  const [importWebpage, setImportWebpage] = useState(false);
  const [importDocument, setImportDocument] = useState(false);
  const [importConversations, setImportConversations] = useState(false);
  const [tab, setTab] = useState("all");

  const closeAddFaqModal = () => {
    setAddFaq(false);
  };

  const closeImportWebpageModal = () => {
    setImportWebpage(false);
  };

  const closeImportDocumentModal = () => {
    setImportDocument(false);
  };

  const closeImportConversationsModal = () => {
    setImportConversations(false);
  };

  return (
    <>
      <div className="new-settings-finetune-sec">
        <div className="new-settings-finetune-header-sec">
          <div className="new-settings-finetune-info-sec">
            <h3>{t("heading")}</h3>
          </div>
          <div className="new-settings-finetune-header-box">
            <div
              className="new-settings-finetune-header-card"
              onClick={() => setImportWebpage(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="#000"
                viewBox="0 0 24 24"
              >
                <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.647 7h-3.221a19.676 19.676 0 00-2.821-4.644A10.031 10.031 0 0120.647 7zM16.5 12a10.211 10.211 0 01-.476 3H7.976a10.211 10.211 0 01-.476-3 10.211 10.211 0 01.476-3h8.048a10.211 10.211 0 01.476 3zm-7.722 5h6.444A19.614 19.614 0 0112 21.588 19.57 19.57 0 018.778 17zm0-10A19.614 19.614 0 0112 2.412 19.57 19.57 0 0115.222 7zM9.4 2.356A19.676 19.676 0 006.574 7H3.353A10.031 10.031 0 019.4 2.356zM2.461 9H5.9a12.016 12.016 0 00-.4 3 12.016 12.016 0 00.4 3H2.461a9.992 9.992 0 010-6zm.892 8h3.221A19.676 19.676 0 009.4 21.644 10.031 10.031 0 013.353 17zm11.252 4.644A19.676 19.676 0 0017.426 17h3.221a10.031 10.031 0 01-6.042 4.644zM21.539 15H18.1a12.016 12.016 0 00.4-3 12.016 12.016 0 00-.4-3h3.437a9.992 9.992 0 010 6z"></path>
              </svg>
              <h4>{t("import.web")}</h4>
            </div>
            <div
              className="new-settings-finetune-header-card"
              onClick={() => setImportDocument(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="#000"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M17 14a1 1 0 01-1 1H8a1 1 0 010-2h8a1 1 0 011 1zm-4 3H8a1 1 0 000 2h5a1 1 0 000-2zm9-6.515V19a5.006 5.006 0 01-5 5H7a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h4.515a6.958 6.958 0 014.95 2.05l3.484 3.486A6.951 6.951 0 0122 10.485zm-6.949-7.021A5.01 5.01 0 0014 2.684V7a1 1 0 001 1h4.316a4.983 4.983 0 00-.781-1.05zM20 10.485c0-.165-.032-.323-.047-.485H15a3 3 0 01-3-3V2.047c-.162-.015-.321-.047-.485-.047H7a3 3 0 00-3 3v14a3 3 0 003 3h10a3 3 0 003-3z"></path>
              </svg>
              <h4>{t("import.doc")}</h4>
            </div>
            <div
              className="new-settings-finetune-header-card"
              onClick={() => setAddFaq(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="#000"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M11.904 16a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0111.904 16zm1-2c0-.561.408-1.225.928-1.512a4.021 4.021 0 002.009-4.223c-.283-1.613-1.607-2.938-3.221-3.221a4.034 4.034 0 00-3.289.874 3.995 3.995 0 00-1.428 3.063 1 1 0 102 0 2.002 2.002 0 012.371-1.967c.787.138 1.458.81 1.596 1.596a1.995 1.995 0 01-1.004 2.125c-1.156.637-1.963 1.979-1.963 3.264a1 1 0 102 0zM24 19v-6.66C24 5.861 19.096.454 12.836.028a12.047 12.047 0 00-9.327 3.493A12.024 12.024 0 00.03 12.854C.47 19.208 6.082 24 13.084 24h5.917c2.757 0 5-2.243 5-5zM12.701 2.024C17.916 2.378 22 6.909 22 12.339v6.66c0 1.654-1.346 3-3 3h-5.917c-6.035 0-10.686-3.904-11.059-9.284a10.02 10.02 0 012.899-7.781A10.016 10.016 0 0112.7 2.023z"></path>
              </svg>
              <h4>{t("import.faq")}</h4>
            </div>
            <div
              className="new-settings-finetune-header-card"
              onClick={() => setImportConversations(true)}
            >
              <svg
               xmlns="http://www.w3.org/2000/svg"
               width="28"
               height="28"
               fill="#000"
               viewBox="0 0 24 24"
             >
               <g fill="#000">
                 <path d="M21 11H7c-.6 0-1-.4-1-1s.4-1 1-1h11.6l-2.3-2.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.3.3.4.7.2 1.1-.1.4-.5.6-.9.6zM7 19c-.3 0-.5-.1-.7-.3l-4-4c-.3-.3-.4-.7-.2-1.1s.5-.6.9-.6h14c.6 0 1 .4 1 1s-.4 1-1 1H5.4l2.3 2.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"></path>
               </g>
             </svg>
              <h4>Conversational Data</h4>
            </div>
          </div>
        </div>
        <div className="new-settings-finetune-body-sec">
          <Tab.Container id="left-tabs-example"
            defaultActiveKey="all"
            onSelect={(key, ev) => (ev.type != "click") && setTab(key)}
            >
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="all" onClick={() => setTab("all")}>
                      {t("options.all")}
                      <span className="count">{!customTrainingList.loading && Object.keys(customTrainingList.data).length > 0 ? customTrainingList.data.total_custom_training_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="docs" onClick={() => setTab("docs")}>
                      {t("options.docs")}
                      <span className="count">{!fineTuneList.loading && Object.keys(fineTuneList.data).length > 0 ? fineTuneList.data.docs_fine_tune_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="web-page"
                      onClick={() => setTab("web-page")}
                    >
                      {t("options.web")}
                      <span className="count">{!fineTuneList.loading && Object.keys(fineTuneList.data).length > 0 ? fineTuneList.data.links_fine_tune_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="faqs" onClick={() => setTab("faqs")}>
                      {t("options.faq")}
                      <span className="count">{!fineTuneList.loading && Object.keys(fineTuneList.data).length > 0 ? fineTuneList.data.total_faq_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="conversations" onClick={() => setTab("conversations")}>
                      {t("options.conversations")}
                      <span className="count">{!fineTuneList.loading && Object.keys(fineTuneList.data).length > 0 ? fineTuneList.data.total_conversational_data_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  {tab == "all" && (
                    <Tab.Pane eventKey="all">
                      <CustomTrainingIndex />
                    </Tab.Pane>
                  )}
                  {tab == "docs" && (
                    <Tab.Pane eventKey="docs">
                      <FinetuneIndex file_type="docs" />
                    </Tab.Pane>
                  )}
                  {tab == "web-page" && (
                    <Tab.Pane eventKey="web-page">
                      <FinetuneIndex file_type="link" />
                    </Tab.Pane>
                  )}
                  {tab == "faqs" && (
                    <Tab.Pane eventKey="faqs">
                      <FaqIndex />
                    </Tab.Pane>
                  )}
                  {tab == "conversations" && (
                    <Tab.Pane eventKey="conversations">
                      <ConversationsIndex />
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
      {addFaq && (
        <AddFaqModal
          addFaq={addFaq}
          closeAddFaqModal={closeAddFaqModal}
          setAddFaq={setAddFaq}
        />
      )}
      {importWebpage && (
        <ImportWebpageModal
          importWebpage={importWebpage}
          closeImportWebpageModal={closeImportWebpageModal}
          setImportWebpage={setImportWebpage}
          onboardingBot={selectedBot}
          onboarding={0}
        />
      )}
      {importDocument && (
        <ImportDocumentModal
          importDocument={importDocument}
          closeImportDocumentModal={closeImportDocumentModal}
          setImportDocument={setImportDocument}
          onboardingBot={selectedBot}
          onboarding={0}
        />
      )}
      {importConversations && (
        <ImportConversationsModal
          importConversations={importConversations}
          closeImportConversationsModal={closeImportConversationsModal}
          setImportConversations={setImportConversations}
          onboardingBot={selectedBot}
        />
      )}
    </>
  );
};

export default withTranslation(SettingsfineTune);

import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  onPromiseCompanySaveFailure,
  onPromiseCompanySaveSuccess,
  updateWidgetTypeFailure,
  updateWidgetTypeSuccess,
  onboardingCreatedbotFailure,
  onboardingCreatedbotSuccess,
} from "../slices/OnboardingSlice";
import { profileSuccess } from "../slices/AdminSlice"

function* onPromiseCompanySaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "on_promise_companies_save",
    payload: action.payload,
    success: onPromiseCompanySaveSuccess,
    failure: onPromiseCompanySaveFailure,
  });
}

function* updateWidgetTypeAPI(action) {
  const profileData = yield select(state => state.admin.profile.data);
  yield getCommonSaga({
    apiUrl: "update_widget_type",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(updateWidgetTypeSuccess(data));
      if (Object.keys(profileData).length > 0) {
        store.dispatch(profileSuccess(data.user))
      }
    },
    failure: updateWidgetTypeFailure,
  });
}

function* onboardingCreatedbotAPI(action) {
  yield getCommonSaga({
    apiUrl: "bots/onboarding_created",
    payload: action.payload,
    successNotify: false,
    success: onboardingCreatedbotSuccess,
    failure: onboardingCreatedbotFailure,
  });
}

export default function* OnboardingSaga() {
  yield all([
    yield takeLatest('onboarding/onPromiseCompanySaveStart', onPromiseCompanySaveAPI),
    yield takeLatest('onboarding/updateWidgetTypeStart', updateWidgetTypeAPI),
    yield takeLatest('onboarding/onboardingCreatedbotStart', onboardingCreatedbotAPI),
  ]);
}


import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutStart, profileFailure } from '../store/slices/AdminSlice';
import {
    setChats,
    setHighlightChat,
    setSelectedChats,
    selecteInboxBot
} from '../store/slices/ChatSlice';

const useLogout = () => {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(logoutStart());
        dispatch(setSelectedChats([]));
        dispatch(selecteInboxBot({
            label: "All Chats",
            value: "",
          }));
        dispatch(setChats([]));
        dispatch(setHighlightChat(""));
        dispatch(profileFailure());
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
    }

    return { logout };

}

export default useLogout;
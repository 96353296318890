import { select, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import { makeChatAsTicketFailure, makeChatAsTicketSuccess } from "../slices/SupportTicketSlice";
import { chatListSuccess } from "../slices/ChatSlice";
import { dashboardSuccess } from "../slices/AdminSlice";

function* makeChatAsTicketAPI(action){
    const chatListData = yield select(state => state.chat.chatList.data);
    const dashboardData = yield select(state => state.admin.dashboard.data);
    yield getCommonSaga({
        apiUrl: "tickets/make_chat_as_ticket",
        payload: action.payload,
        successCallback: true,
        success: data => {
          store.dispatch(makeChatAsTicketSuccess(data));
          if (Object.keys(chatListData).length > 0) {
            store.dispatch(chatListSuccess({
                ...chatListData,
                chats: chatListData.chats.map((chat) => chat.chat_id === action.payload.chat_id ? {
                    ...chat, is_ticket: 1,
                } : chat)
            }))
          }
          if (Object.keys(dashboardData).length > 0) {
            store.dispatch(dashboardSuccess({
                ...dashboardData,
                recent_chats: dashboardData.recent_chats.map((chat) => chat.chat_id === action.payload.chat_id ? {
                    ...chat, is_ticket: 1,
                } : chat)
            }))
          }
        },
        failure: makeChatAsTicketFailure,
    });
}


export default function* SupportTicketSaga() {
    yield all([
      yield takeLatest('supportticket/makeChatAsTicketStart', makeChatAsTicketAPI),
    ]);
  }
import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./OnBoarding.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fineTuneListStart,
  fineTuneDeleteStart,
} from "../../store/slices/FineTuneSlice";
import NoDataFound from "../Helper/NoDataFound";
import { useTranslation, withTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";
import ImportWebpageModal from "./ImportWebpageModal";
import ImportDocumentModal from "./ImportDocumentModal";
import { onboardingCreatedbotStart } from "../../store/slices/OnboardingSlice";
import OnboardingStepsContent from "./OnboardingStepsContent";
import NavLogo from "../Layout/Header/NavLogo";

const FineTuningsFilesIndex = (props) => {
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: '100%',
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "30%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#32089F12!important",
      border: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: 'max-content',
      fontSize: "0.9em",
      cursor:"pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const t = useTranslation("onboarding_fine_tuning");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fineTuneList = useSelector((state) => state.fineTune.fineTuneList);
  const fineTuneDelete = useSelector((state) => state.fineTune.fineTuneDelete);
  const onboardingBot = useSelector((state) => state.onboarding.onboardingbot);
  const fineTuneSave = useSelector((state) => state.fineTune.fineTuneSave);
  const [skipRender, setSkipRender] = useState(true);
  const [importOption, setImportOption] = useState("");
  const [importWebpage, setImportWebpage] = useState(false);
  const [importDocument, setImportDocument] = useState(false);

  useEffect(() => {
    if (
      !skipRender &&
      !onboardingBot.loading &&
      Object.keys(onboardingBot.data).length > 0
    ) {
      dispatch(
        fineTuneListStart({ bot_id: onboardingBot.data.bot_id, take: 20 })
      );
    }
  }, [onboardingBot]);

  const deleteFile = (fine_tune_model_id) => {
    dispatch(fineTuneDeleteStart({ fine_tune_model_id: fine_tune_model_id, onboarding: 1 }));
  };

  const options = [
    { value: 1, label: "Import from webpage" },
    { value: 2, label: "Import from doc" },
  ];

  const closeImportWebpageModal = () => {
    setImportWebpage(false);
  };

  const closeImportDocumentModal = () => {
    setImportDocument(false);
  };

  useEffect(() => {
    dispatch(onboardingCreatedbotStart());
  }, []);

  const handleImportInputChange = (selectedOption) => {
    setImportOption(selectedOption);
    if (selectedOption.value == 1) setImportWebpage(true);
    else if (selectedOption.value == 2) setImportDocument(true);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !fineTuneSave.loading &&
      Object.keys(fineTuneSave.data).length > 0 &&
      Object.keys(onboardingBot.data).length > 0
    ) {
      setImportOption("");
      dispatch(
        fineTuneListStart({ bot_id: onboardingBot.data.bot_id, take: 20 })
      );
    }
    setSkipRender(false);
  }, [fineTuneSave]);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-body-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-fine-tunings-files-sec">
                    <div className="onboarding-fine-tunings-files-header-sec">
                      {/* <Button
                        className="onboarding-add-fine-tuning-btn"
                        onClick={() => props.setStep(3)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#fff"
                          x="0"
                          y="0"
                          enableBackground="new 0 0 512 512"
                          version="1.1"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                        >
                          <path d="M480 224H288V32c0-17.673-14.327-32-32-32s-32 14.327-32 32v192H32c-17.673 0-32 14.327-32 32s14.327 32 32 32h192v192c0 17.673 14.327 32 32 32s32-14.327 32-32V288h192c17.673 0 32-14.327 32-32s-14.327-32-32-32z"></path>
                        </svg>
                        <span>Add Fine Tuning</span>
                      </Button> */}
                      {!fineTuneList.loading &&
                        Object.keys(fineTuneList.data).length > 0 &&
                        fineTuneList.data.fine_tune_models &&
                        fineTuneList.data.fine_tune_models.length === 0 && (
                          <Select
                            options={options}
                            value={importOption}
                            styles={customStyles}
                            // menuIsOpen={true}
                            isSearchable={false}
                            placeholder={<div>{t("add")}</div>}
                            onChange={(selectedOption) => {
                              handleImportInputChange(selectedOption);
                            }}
                          />
                        )}
                    </div>
                    <div className="onboarding-fine-tunings-files-body-sec">
                      <div className="onboarding-fine-tunings-files-box">
                        {fineTuneList.loading || onboardingBot.loading ? (
                          <>
                            {[...Array(4)].map((i) => (
                              <Skeleton count={1} height={50} />
                            ))}
                          </>
                        ) : Object.keys(fineTuneList.data).length > 0 &&
                          fineTuneList.data.fine_tune_models &&
                          fineTuneList.data.fine_tune_models.length > 0 ? (
                          fineTuneList.data.fine_tune_models.map((model) => (
                            <div className="onboarding-fine-tunings-files-card">
                              <div className="onboarding-fine-tunings-files-name">
                                {model.name}{" "}
                                {model.file_type == "link" && (
                                  <Link
                                    to={model.import_url}
                                    target="_blank"
                                    // className="onboarding-fine-tunings-download-btn"
                                  >
                                    ({model.import_url.replace("https://", "")})
                                  </Link>
                                )}
                              </div>
                              <div className="onboarding-fine-tunings-files-action-btn-sec">
                                {model.file_type == "link" ? (
                                  <Link
                                    to={model.import_url}
                                    target="_blank"
                                    className="onboarding-fine-tunings-download-btn"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      fill="#32089F"
                                      height="24"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"></path>
                                      <path d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"></path>
                                    </svg>
                                    {t("view")}
                                  </Link>
                                ) : (
                                  <a
                                    className="onboarding-fine-tunings-download-btn"
                                    download={true}
                                    target="_blank"
                                    href={model.file}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="#32089F"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M17.974 7.146a1.028 1.028 0 01-.742-.569c-1.552-3.271-5.143-5.1-8.735-4.438A7.946 7.946 0 002.113 8.64a8.13 8.13 0 00.033 2.89c.06.309-.073.653-.346.901a5.51 5.51 0 00-1.801 4.07c0 3.032 2.467 5.5 5.5 5.5h11c4.136 0 7.5-3.364 7.5-7.5 0-3.565-2.534-6.658-6.026-7.354zM16.5 20h-11C3.57 20 2 18.43 2 16.5a3.51 3.51 0 011.146-2.59c.786-.715 1.155-1.773.963-2.763a6.142 6.142 0 01-.024-2.181c.403-2.422 2.365-4.421 4.771-4.862A6.4 6.4 0 0110.001 4a5.95 5.95 0 015.422 3.434 3.018 3.018 0 002.158 1.673 5.51 5.51 0 014.417 5.394c0 3.032-2.467 5.5-5.5 5.5zm-1.379-6.707a.999.999 0 010 1.414l-2.707 2.707a1.993 1.993 0 01-1.405.584L11 18l-.009-.002a1.993 1.993 0 01-1.405-.584l-2.707-2.707a.999.999 0 111.414-1.414L10 15v-5a1 1 0 112 0v5l1.707-1.707a.999.999 0 011.414 0z"></path>
                                    </svg>
                                    {t("download")}
                                  </a>
                                )}
                                {/* <Button className="onboarding-fine-tunings-edit-btn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#32089F"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm1.379-15.621l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914c1.17-1.17 1.17-3.072 0-4.242s-3.072-1.17-4.242 0zm-3.086 8.742A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414-3.457 3.457zm5.914-5.914l-1.043 1.043-1.414-1.414 1.043-1.043a.999.999 0 111.414 1.414z"></path>
                              </svg>
                              Edit
                            </Button> */}
                                <Button
                                  className="onboarding-fine-tunings-delete-btn"
                                  onClick={() => deleteFile(model.id)}
                                  disabled={fineTuneDelete.buttonDisable}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="#E2434D"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                                    <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                                  </svg>
                                  {fineTuneDelete.buttonDisable &&
                                  fineTuneDelete.data.fine_tune_model_id ===
                                    model.id
                                    ? t("loading")
                                    : t("delete")}
                                </Button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="onboarding-footer-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-on-premise-btn-sec">
                    <Button
                      className="onboarding-back-btn"
                      onClick={() => props.setStep(3)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#00195E"
                          d="M20 11H6.83l2.88-2.88A.997.997 0 008.3 6.71L3.71 11.3a.996.996 0 000 1.41L8.3 17.3a.997.997 0 101.41-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z"
                        ></path>
                      </svg>
                      {t("back")}
                    </Button>
                    <Button
                      className="onboarding-btn"
                      // to={'/onboarding-subscription-plans'}
                      disabled={
                        fineTuneList.loading || onboardingBot.loading || fineTuneDelete.buttonDisable || 
                        (Object.keys(fineTuneList.data).length <= 0 ||
                          !fineTuneList.data.fine_tune_models ||
                          fineTuneList.data.fine_tune_models.length <= 0)
                      }
                      onClick={() => navigate("/onboarding-subscription-plans")}
                    >
                      {t("next")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <OnboardingStepsContent step={3} />
      </div>
      {!onboardingBot.loading && (
        <>
          <ImportWebpageModal
            importWebpage={importWebpage}
            closeImportWebpageModal={closeImportWebpageModal}
            setImportWebpage={setImportWebpage}
            onboardingBot={onboardingBot.data}
            onboarding={1}
            step={props.step}
            setStep={props.setStep}
          />
          <ImportDocumentModal
            importDocument={importDocument}
            closeImportDocumentModal={closeImportDocumentModal}
            setImportDocument={setImportDocument}
            onboardingBot={onboardingBot.data}
            onboarding={1}
            step={props.step}
            setStep={props.setStep}
          />
        </>
      )}
    </>
  );
};

export default withTranslation(FineTuningsFilesIndex);

import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "react-bootstrap";

const ProfileLoader = () => {
  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="admin-ui-dashboard-sec">
          <Row className="justify-content-md-center m-3">
            <Col md={2}>
              <Skeleton circle width={140} height={140} className="mb-3" />
            </Col>
            <Col md={10}>
              <div className="mt-3">
                <Skeleton count={2} height={40} width="50%" className="mb-3" />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Skeleton height={200} width="100%" className="mb-3" />
            </Col>
            <Col md={6}>
              <Skeleton height={200} width="100%" className="mb-3" />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ProfileLoader;

import axios from "axios";
const useBot = () => {

  const widgets = [
    {
      label: "FreshChat",
      value: 1,
      picture: window.location.origin + "/images/onboarding/choose-solution/logo-freshchat.png"
    },
    // {
    //   label: "FreshDesk",
    //   value: 6,
    //   picture: window.location.origin + "/images/onboarding/choose-solution/logo-freshdesk.png"
    // },
    {
      label: "ZenDesk",
      value: 3,
      picture: window.location.origin + "/images/onboarding/choose-solution/logo-zendesk.png"
    },
    // {
    //   label: "Salesforce",
    //   value: 5,
    //   picture: window.location.origin + "/images/onboarding/choose-solution/logo-salesforce.png"
    // },
    {
      label: "LiveChat",
      value: 4,
      picture: window.location.origin + "/images/onboarding/choose-solution/logo-live-chat.png"
    }
  ];

  const promptOptions = [
    { label: 'General Inquiry Handling', value: 'General Inquiry Handling' },
    { label: 'Technical Support', value: 'Technical Support' },
    { label: 'Product Information', value: 'Product Information' },
    { label: 'Ordering Assistance', value: 'Ordering Assistance' },
    { label: 'Complaint Resolution', value: 'Complaint Resolution' },
    { label: 'Billing Support', value: 'Billing Support' },
    { label: 'Returns and Exchanges', value: 'Returns and Exchanges' },
    { label: 'Feedback and Suggestions', value: 'Feedback and Suggestions'},
    { label: 'Others', value: 'Others' },
  ]
 
  const handlePrompt = async (data) => {
    const response = await axios.post("http://13.59.2.142:5002/api/v1/generate_prompt",data);
    return response.data;
  };

  return {
    widgets,
    promptOptions,
    handlePrompt
  };
};

export default useBot;

import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  faqDeleteStart,
  faqListStart,
  moreFaqListStart,
  faqTrainStart,
} from "../../../store/slices/FaqSlice";
import NoDataFound from "../../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import AddFaqModal from "./AddFaqModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { confirmAlert } from "react-confirm-alert";

const FaqIndex = () => {
  const dispatch = useDispatch();

  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const faqList = useSelector((state) => state.faq.faqList);
  const faqDelete = useSelector((state) => state.faq.faqDelete);
  const faqTrain = useSelector((state) => state.faq.faqTrain);
  const [addFaq, setAddFaq] = useState(false);
  const [faq, setFaq] = useState({});

  const handleAddFaq = (message) => {
    setFaq({ ...faq, question: message.question, answer: message.response, status: message.status, chat_message_faq_id: message.chat_message_faq_id });
    setAddFaq(true);
  };

  const closeAddFaqModal = () => {
    setAddFaq(false);
  };

  useEffect(() => {
    dispatch(faqListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12 }));
  }, [selectedBot]);

  const fetchMoreData = () => {
    dispatch(
      moreFaqListStart({
        bot_id: selectedBot.bot_id,
        skip: faqList.data.chat_message_faqs.length,
        take: 12,
      })
    );
  };

  const handleDeleteFaq = (chat_message_faq_id) => {
    confirmAlert({
      title: "Do you really want to delete this FAQ?",
      message: "All your data wil be deleted and cannot be recovered.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            dispatch(faqDeleteStart({ chat_message_faq_id }));
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleTrain = () => {
    dispatch(faqTrainStart({ bot_id: selectedBot.bot_id }));
  };

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return "progress-badge";
      case 2:
        return "trained-badge";
      case 3:
        return "failed-badge";
      default:
        return "uploaded-badge";
    }
  };

  return (
    <>
      <div className="new-finetune-faq-table-sec">
        <div className="new-finetune-faq-table-box">
          {faqList.loading ? (
            <div className="onboarding-fine-tunings-files-box">
              {[...Array(4)].map((i) => (
                <Skeleton height={80} />
              ))}
            </div>
          ) : Object.keys(faqList.data).length > 0 &&
            faqList.data.chat_message_faqs &&
            faqList.data.chat_message_faqs.length > 0 ? (
            <>
              {faqList.data.chat_message_faqs.find(
                (faq) => faq.training_status == 0
              ) && (
                <div className="admin-ui-dashboard-graph-header-sec">
                  <p>Total FAQs - {faqList.data.total_faqs}</p>
                  <Button
                    type="button"
                    className="onboarding-add-fine-tuning-btn btn btn-primary"
                    onClick={() => handleTrain()}
                    disabled={faqTrain.buttonDisable}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#fff"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16 0H8a5.006 5.006 0 00-5 5v18a1 1 0 001.564.825l2.106-1.439 2.106 1.439a1 1 0 001.13 0l2.1-1.439 2.1 1.439a1 1 0 001.131 0l2.1-1.438 2.1 1.437A1 1 0 0021 23V5a5.006 5.006 0 00-5-5zm3 21.1l-1.1-.752a1 1 0 00-1.132 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.131 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.129 0L5 21.1V5a3 3 0 013-3h8a3 3 0 013 3z"></path>
                      <rect width="10" height="2" x="7" y="8" rx="1"></rect>
                      <rect width="8" height="2" x="7" y="12" rx="1"></rect>
                    </svg>
                    {faqTrain.buttonDisable ? "Loading" : "Train"}
                  </Button>
                </div>
              )}
              <InfiniteScroll
                dataLength={faqList.data.chat_message_faqs.length}
                next={fetchMoreData}
                hasMore={
                  faqList.data.chat_message_faqs.length <
                  faqList.data.total_chat_message_faqs
                }
                loader={[...Array(3)].map((i) => (
                  <Skeleton height={80} className="mb-3" />
                ))}
              >
                <div className="new-settings-finetune-table-sec">
                  <Table responsive="md">
                    {faqList.data.chat_message_faqs.map((faq, index) => (
                      <tbody key={index}>
                        {faq.file.length > 0 ? (
                          <tr>
                            <div className="new-finetune-faq-mesage">
                              <h4 className="question-text">{faq.batch_id.slice(0,5)}...{faq.batch_id.slice(-5)}</h4>
                            </div>
                            <div className="new-finetune-faq-btn-sec">
                              <div className={getStatusName(faq.training_status)}>
                                {faq.training_status_formatted}
                              </div>
                              <div className="new-finetune-faq-action-btn-sec">
                                <div className="recent-chat-menu-btn">
                                  {faqDelete.buttonDisable &&
                                  faqDelete.data.chat_message_faq_id == faq.chat_message_faq_id ? (
                                    <Image
                                      className="btn-loader"
                                      src={
                                        window.location.origin +
                                        "/images/loader/btn-loader.gif"
                                      }
                                    />
                                  ) : (
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                      >
                                        <div className="recent-chat-menu-icon-sec">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            x="0"
                                            y="0"
                                            enableBackground="new 0 0 512 512"
                                            version="1.1"
                                            viewBox="0 0 512 512"
                                            xmlSpace="preserve"
                                          >
                                            <circle
                                              cx="458.667"
                                              cy="256"
                                              r="53.333"
                                            ></circle>
                                            <circle
                                              cx="256"
                                              cy="256"
                                              r="53.333"
                                            ></circle>
                                            <circle
                                              cx="53.333"
                                              cy="256"
                                              r="53.333"
                                            ></circle>
                                          </svg>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href={faq.file}
                                          target="_blank"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            fill="#546594"
                                            height="20"
                                            viewBox="0 0 24 24"
                                          >
                                            <path d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"></path>
                                            <path d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"></path>
                                          </svg>
                                          View
                                        </Dropdown.Item>
                                        {faq.training_status != 1 && (
                                          <Dropdown.Item
                                            disabled={faqDelete.buttonDisable}
                                            onClick={() => handleDeleteFaq(faq.chat_message_faq_id)}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              fill="#546594"
                                              height="20"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                                              <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                                            </svg>
                                            Delete
                                          </Dropdown.Item>
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )}
                                </div>
                              </div>
                            </div>
                          </tr>
                        ) : (
                          <tr>
                            <div className="new-finetune-faq-mesage">
                              <h4 className="question-text">{faq.question}</h4>
                              <p className="answer-text">{faq.response}</p>
                            </div>
                            <div className="new-finetune-faq-btn-sec">
                              <div className={getStatusName(faq.training_status)}>
                                {faq.training_status_formatted}
                              </div>
                              <div className="new-finetune-faq-action-btn-sec">
                                {/* <div className="trained-badge">Trained</div> */}
                                {faq.training_status != 1 ? (
                                  <div className="recent-chat-menu-btn">
                                    {faqDelete.buttonDisable &&
                                    faqDelete.data.chat_message_faq_id ==
                                      faq.chat_message_faq_id ? (
                                      <Image
                                        className="btn-loader"
                                        src={
                                          window.location.origin +
                                          "/images/loader/btn-loader.gif"
                                        }
                                      />
                                    ) : (
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                        >
                                          <div className="recent-chat-menu-icon-sec">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              x="0"
                                              y="0"
                                              enableBackground="new 0 0 512 512"
                                              version="1.1"
                                              viewBox="0 0 512 512"
                                              xmlSpace="preserve"
                                            >
                                              <circle
                                                cx="458.667"
                                                cy="256"
                                                r="53.333"
                                              ></circle>
                                              <circle
                                                cx="256"
                                                cy="256"
                                                r="53.333"
                                              ></circle>
                                              <circle
                                                cx="53.333"
                                                cy="256"
                                                r="53.333"
                                              ></circle>
                                            </svg>
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {faq.training_status != 2 && (
                                            <Dropdown.Item
                                              disabled={faqDelete.buttonDisable}
                                              onClick={() => handleAddFaq(faq)}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="#32089F"
                                                data-name="Layer 1"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm1.379-15.621l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914c1.17-1.17 1.17-3.072 0-4.242s-3.072-1.17-4.242 0zm-3.086 8.742A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414-3.457 3.457zm5.914-5.914l-1.043 1.043-1.414-1.414 1.043-1.043a.999.999 0 111.414 1.414z"></path>
                                              </svg>
                                              Edit
                                            </Dropdown.Item>
                                          )}
                                          <Dropdown.Item
                                            disabled={faqDelete.buttonDisable}
                                            onClick={() =>
                                              handleDeleteFaq(
                                                faq.chat_message_faq_id
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              fill="#546594"
                                              height="20"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                                              <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                                            </svg>
                                            {faqDelete.buttonDisable
                                              ? "Loading"
                                              : "Delete"}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </tr>
                        )}
                      </tbody>
                    ))}
                  </Table>
                </div>
              </InfiniteScroll>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
      {addFaq && (
        <AddFaqModal
          addFaq={addFaq}
          closeAddFaqModal={closeAddFaqModal}
          setAddFaq={setAddFaq}
          faq={faq}
          type={2}
        />
      )}
    </>
  );
};

export default FaqIndex;

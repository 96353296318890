import React from "react";

const OnboardingStepsContent = ({ step }) => {

  return (
    <div className="onboarding-right-sec">
      <div className="onboarding-installation-content">
        <div className="stepper-wrapper">
          <div className={`stepper-item ${step > 0 ? "completed" : ""}`}>
            <div className="step-counter">1</div>
          </div>
          <div className={`stepper-item ${step > 1 ? "completed" : ""}`}>
            <div className="step-counter">2</div>
          </div>
          <div className={`stepper-item ${step > 2 ? "completed" : ""}`}>
            <div className="step-counter">3</div>
          </div>
          <div className={`stepper-item ${step > 3 ? "completed" : ""}`}>
            <div className="step-counter">4</div>
          </div>
        </div>
        <h3>Choose Solution</h3>
        <p>
          Kindly choose the solution which needs to configure for the website.
        </p>
      </div>
    </div>
  );
};

export default OnboardingStepsContent;

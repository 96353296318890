import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup, Row, Col } from "react-bootstrap";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import {
  botsStoreStart,
  botsListStart
} from "../../store/slices/BotSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";
import { billingAddressSaveStart } from "../../store/slices/SubscriptionSlice";

const EditAddressModal = (props) => {

  const t = useTranslation("edit_address_modal");
  const dispatch = useDispatch();
  const botsStore = useSelector((state) => state.bots.botsStore);
  const billingAddressSave = useSelector((state) => state.subscription.billingAddressSave)
  const [skipRender, setSkipRender] = useState(true);

  const addressSchema = Yup.object().shape({
    name: Yup.string().required(t("name_is_required")),
    contact_number: Yup.string().required(t("contact_number_is_required"))
      .matches(/^(?=.*[0-9])/, t("space_values_not_valid"))
      .min(10, t("must_contain_10_characters"))
      .max(10, t("must_contain_10_characters")),
    landmark: Yup.string().required(t("landmark_is_required")),
    address: Yup.string().required(t("address_is_required")),
    state: Yup.string().required(t("state_is_required")),
    country: Yup.string().required(t("country_is_required")),
    pincode: Yup.string().required(t("pincode_is_required"))
      .matches(/^(?=.*[0-9])/, t("space_values_not_valid"))
      .min(6, t("must_contain_6_characters"))
      .max(6, t("must_contain_6_characters")),
  });
  const options = [
    { label: 'Zendesk', value: 3 },
    { label: 'Salesforce', value: 5 },
    { label: 'LiveChat', value: 4 },
    { label: 'Freshchat', value: 1 },
    { label: 'Freshdesk', value: 6 },
  ];

  const handleEditAddress = (values) => {
    dispatch(
      billingAddressSaveStart(values)
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !billingAddressSave.loading &&
      Object.keys(billingAddressSave.data).length > 0
    ) {
      props.closeAddBotModal();
    }
    setSkipRender(false);
  }, [billingAddressSave]);

  return (
    <>
    {props.address ? 
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.addBot}
        onHide={props.closeAddBotModal}
      >
        <Modal.Body>
          <h4>Edit Address</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeAddBotModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M16.061 10.061L14.122 12l1.939 1.939a1.5 1.5 0 11-2.122 2.121L12 14.121l-1.939 1.939c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121L9.878 12l-1.939-1.939A1.5 1.5 0 1110.06 7.94l1.939 1.939 1.939-1.939a1.5 1.5 0 112.121 2.121zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-3 0c0-4.963-4.037-9-9-9s-9 4.037-9 9 4.038 9 9 9 9-4.037 9-9z"></path>
            </svg>
          </Button>
          <Formik
            initialValues={{
              user_billing_address_id: props.address.user_billing_address_id,
              name: props.address.name,
              contact_number: props.address.contact_number,
              address: props.address.address,
              landmark: props.address.landmark,
              state: props.address.state,
              country: props.address.country,
              pincode: props.address.pincode,
            }}
            validationSchema={addressSchema}
            onSubmit={handleEditAddress}
          >
            {({ setFieldValue, values }) => (
              <FORM noValidate className="onboarding-form">
                <div className="onboarding-body-sec">
                  <Row className="justify-content-md-center">
                    <Col md={10}>
                    <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>{t("name")}: (*)</Form.Label>
                            <Field
                              className="form-control"
                              placeholder={t("name")}
                              type="text"
                              autoFocus={true}
                              name="name"
                            />
                            <ErrorMessage
                              name="name"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              {t("mobile")}: (*)
                            </Form.Label>
                            <Field
                              className="form-control"
                              placeholder={t("mobile")}
                              type="number"
                              name="contact_number"
                            />
                            <ErrorMessage
                              name="contact_number"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>{t("address")}: (*)</Form.Label>
                            <Field
                              as="textarea"
                              rows={2}
                              className="form-control"
                              placeholder={t("address")}
                              type="text"
                              name="address"
                            />
                            <ErrorMessage
                              name="address"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              {t("landmark")}: (*)
                            </Form.Label>
                            <Field
                              className="form-control"
                              placeholder={t("landmark")}
                              type="text"
                              name="landmark"
                            />
                            <ErrorMessage
                              name="landmark"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>{t("state")}: (*)</Form.Label>
                            <Field
                              className="form-control"
                              placeholder={t("state")}
                              type="text"
                              name="state"
                            />
                            <ErrorMessage
                              name="state"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>{t("country")}: (*)</Form.Label>
                            <Field
                              className="form-control"
                              placeholder={t("country")}
                              type="text"
                              name="country"
                            />
                            <ErrorMessage
                              name="country"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>{t("pincode")}: (*)</Form.Label>
                            <Field
                              className="form-control"
                              placeholder={t("pincode")}
                              type="number"
                              name="pincode"
                            />
                            <ErrorMessage
                              name="pincode"
                              component={"div"}
                              className="text-danger pass-txt"
                            />
                          </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="onboarding-footer-sec">
                  <Row className="justify-content-md-center">
                    <Col md={10}>
                      <div className="onboarding-on-premise-btn-sec">
                        <Button
                          className="onboarding-btn"
                          type="submit"
                          disabled={billingAddressSave.buttonDisable}
                        >
                          {billingAddressSave.buttonDisable ? "Loading" : "Edit Address"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    : null}
    </>
  );
};

export default EditAddressModal;
